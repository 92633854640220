import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path:"",redirectTo:"/",pathMatch:"full"},
  {path:"admin",redirectTo:"admin/auth/sign-in",pathMatch:"full"},
  //{path:"**",redirectTo:"error/404"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
