<!-- <section class="invinsource_top_head">
	<div class="container-fluid">
		<a>
			<img src="assets/logo.png">
		</a>
		<a *ngIf="isNotLoggedIn" class="float-end link-light" routerLink="/signup">&emsp;Signup</a>
		<a *ngIf="isNotLoggedIn" class="float-end link-light" routerLink="/login">Login&emsp;</a>
		<a *ngIf="!isNotLoggedIn" (click)="logout()" class="pointer float-end link-light">Logout</a>
	</div>
</section> -->


<nav class="m-0 navbar navbar-expand-lg navbar-custom sticky sticky-dark align-items-center row text-left">
	<a class="navbar-brand" href="#"><img src="../../../assets/images/site-logo.png" alt="Invinsense" height="40px" ></a>
</nav>

