import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of } from 'rxjs'; // Import the 'of' operator
import { switchMap } from 'rxjs/operators';
import { LocalService } from './shared/local.service';
import { UserService } from './shared/user.service';
import { ApiService } from './shared/api.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private RefreshTime: number = 10 * 60 * 1000;
  private expireTime: number = 60 * 60 * 1000;
  constructor(
    private local: LocalService,
    private user: UserService,
    private apiService: ApiService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/auth/refresh-token') === -1) {
      const email = this.user.getEmail();
      const token = this.local.getData('authtoken');
      const loginTime = Number(this.local.getData('login_time'));
      const currentTime = new Date().getTime();
      const refreshThreshold = loginTime + this.expireTime - this.RefreshTime;

      // if (email && token) {
      //   const jwt = JSON.parse(token);
      //   if (currentTime >= refreshThreshold) {
      //     if (jwt && jwt.refresh_token) {
      //       const requestBody = {
      //         refresh_authtoken: jwt.refresh_token,
      //       };
      //       return this.apiService.refreshToken(requestBody).pipe(
      //         switchMap((response: any) => {
      //           jwt.access_token = response.acc_token;
      //           const currentTime = new Date().getTime();
      //           this.local.saveData('login_time', currentTime.toString());
      //           this.local.saveData('authtoken', JSON.stringify(jwt || {}));
      //           const headers = new HttpHeaders({
      //             Authorization: 'Bearer ' + jwt.access_token,
      //           });
      //           const newRequest = request.clone({ headers });
      //           return next.handle(newRequest);
      //         })
      //       );
      //     }
      //   } else {
      //     if (jwt && jwt.access_token) {
      //       const headers = new HttpHeaders({
      //         Authorization: 'Bearer ' + jwt.access_token,
      //       });
      //       const newRequest = request.clone({ headers });
      //       return next.handle(newRequest);
      //     }
      //   }
      // }
    } else {
      // const token = this.local.getData('authtoken');
      // const jwt = JSON.parse(token);
      // const headers = new HttpHeaders({
      //   Authorization: 'Bearer ' + jwt.access_token,
      // });
      // const newRequest = request.clone({ headers });
      // return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
