import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from '../../shared/toast.service';
import { FormGroup,FormControl, Validators, FormBuilder } from '@angular/forms';
import { ConfirmedValidator } from 'src/app/confirm-validator';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
// import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/shared/user.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit{
  maxSize: number = 5000000; 
  minSize: number = 50000;
  photoSelected: boolean = false;
  changepasswordForm!: FormGroup;
  personalInformationForm!: FormGroup;
  professionalInformationForm!: FormGroup;
  geographicalInformationForm!: FormGroup;
  educationalInformationForm!: FormGroup;
  experiencesInformationForm!: FormGroup;
  profileData: any = {};
  isDisabled: boolean = true;
  professionInformation: any = {};
  geographicInformation: any = {};
  educationInformation: any = [];
  experienceInformation: any = [];
  skills: any =[];
  firstCh : any = " ";

  // dropdownList: any = [
  //   {id: 1, name: "ReactJs"},
  //   {id: 2, name: "Angular"},
  //   {id: 3, name: "NodeJs"},
  //   {id: 4, name: "AWS Lambda"},
  //   {id: 5, name: "Git"},
  //   {id: 6, name: "GitHub"},
  //   {id: 7, name: "ElasticSearch"},
  //   {id: 8, name: "Graphic DEsign"}
  // ];
  // selectedItems: any = [
  //   {id: 1, name: "ReactJs"},
  //   {id: 2, name: "Angular"},
  // ];
  // dropdownSettings: IDropdownSettings = {
  //   singleSelection: false,
  //   idField: 'id',
  //   textField: 'name',
  //   itemsShowLimit: 3,
  // };
  


  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  base64Output_profile : string = "";
  name = '';
  imageUrl = "";

  // skills variables = this.user.Skills;
  searchText: string="";  
  filteredSkills: any =[];
  selectedSkills: any =[];
  arrayOfSkillOnly: any =[];
  isSaveSkill_Allowed: any=true;
  // experience profile variables
  listExperience: any=[];
  passListOfExperience: any=[];
  apiExpValue_saver: any=[];
  apiExpValue_filter: any;
  jim: any=[];
  isSaveAllAllowed: any=true;
  afterRemoved_Exp: any = [];
  // editing existing education variables
  selectedEduc_toBe_Edited: any =[];

  // education profile variables
  listEducation: any =[];
  passListOfEducation: any =[];
  apiEducValue_saver: any= [];
  apiEducValue_filter: any= [];
  jam: any=[];
  is_EduSaveAllAllowed: any=true;
  afterRemoved_Edu: any=[];
  //editing exisiting experience variables
  selectedExp_toBe_Edited: any=[];
  userDetails: any;
  

  constructor(private formBuilder: FormBuilder,private apiService: ApiService, private spinner: NgxSpinnerService, private route : ActivatedRoute, private router : Router, private local : LocalService, private toast: ToastService, private user: UserService) { }
  
  ngOnInit(){
    const user = this.local.getData("user");
    this.userDetails = user ? JSON.parse(user) :null;
    $.getScript('/assets/js/form-validations.js');
    // $.getScript('./assets/plugins/select2/select2.min.js');
    // $.getScript('./assets/js/custom-select2.js');

    // this.profile();
    this.personalInformationForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: [''.toString(),[ Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
	  })

    this.professionalInformationForm = this.formBuilder.group({
      github: ['', Validators.required],
      linkedin: ['', Validators.required],
      medium: ['', Validators.required],
      other: ['', Validators.required]
	  })

    this.geographicalInformationForm = this.formBuilder.group({
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      pincode: ['', Validators.required]
	  })

    this.educationalInformationForm = this.formBuilder.group({
      degree: ['', Validators.required],
      institute: ['', Validators.required],
      durationFrom: ['', Validators.required],
      durationTo: ['', Validators.required]
	  })

    this.experiencesInformationForm = this.formBuilder.group({
      position: ['', Validators.required],
      company: ['', Validators.required],
      durationFrom: ['', Validators.required],
      durationTo: ['', Validators.required],
      workdescription: ['', Validators.required],
	  })

    this.changepasswordForm = this.formBuilder.group({
      pre_password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
	  }, { 
      validator: ConfirmedValidator('new_password', 'confirm_password')
    })	
    
    
  }
  passSelected_Edu_data(itemEdu: any){   
    console.log(itemEdu, "this is selected education---hehehe");
    this.selectedEduc_toBe_Edited.push(itemEdu);

  }

  Save_EditedEducation(editedDegree: any, editedInstitute: any, editedDurationFrom: any,editedDurationTo: any){
    console.log("huuhuhuuuuhaaahaha----", editedDegree, editedInstitute, editedDurationFrom);
    const durationFrom = new Date(editedDurationFrom).toISOString().substr(0, 7);
    const durationTo = new Date(editedDurationTo).toISOString().substr(0, 7);
    let newEdu = {"degree": editedDegree, "institute": editedInstitute, "duration": `${durationFrom} to ${durationTo}`};
    console.log("this is present Edu", this.educationInformation);
    this.educationInformation = this.educationInformation.map((item: any)=>{
      if(item.degree === this.selectedEduc_toBe_Edited[0].degree){
        item.degree = newEdu.degree;
        item.institute = newEdu.institute;
        item.duration = newEdu.duration;
      }
      return item;
    })
    console.log("this is MAP Educ", this.educationInformation);
    this.selectedEduc_toBe_Edited.splice(0,1);
    console.log(this.selectedEduc_toBe_Edited, "cleaned");
    const requestBody={
      update_data: {
        educational_information: JSON.stringify(this.educationInformation)
      }
    }
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } 
          else {
            this.apiService.displayGenericError(statusCode,response);  
          }
      }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
    });

    this.educationalInformationForm = this.formBuilder.group({
      degree: ['', Validators.required],
      institute: ['', Validators.required],
      durationFrom: ['', Validators.required],
      durationTo: ['', Validators.required]
	  })

  }

  addEducation(inputDegree: any, inputInstitute: any, inputEduDurationFrom: any,inputEduDurationTo: any){
    const DurationFrom = new Date(inputEduDurationFrom).toISOString().substr(0, 7);
    const DurationTo = new Date(inputEduDurationTo).toISOString().substr(0, 7);
    this.listEducation.push({id: this.listEducation.length, educ:{"degree": inputDegree, "institute": inputInstitute,  "duration": `${DurationFrom} to ${DurationTo}`}});
    
    console.log("here is education list----",this.listEducation, "list length", this.listEducation.length);
    this.passListOfEducation = this.listEducation.map((item: any)=>(item.educ));
    console.log("hello array education-----",this.passListOfEducation);

    this.educationalInformationForm = this.formBuilder.group({
      degree: ['', Validators.required],
      institute: ['', Validators.required],
      durationFrom: ['', Validators.required],
      durationTo: ['', Validators.required]
	  })

    console.log("newnewsss in addExp,---", this.apiEducValue_saver);
    if(this.passListOfEducation.length >0){
      this.is_EduSaveAllAllowed = false;
    }


  }

  navigateToUpdatePassword(){
    this.router.navigate(['/user/update-password']);
  }

  ToRemoveEduc(remoEdu: any){
    console.log("selec-->", remoEdu);
    this.afterRemoved_Edu = this.educationInformation;
    this.afterRemoved_Edu = this.afterRemoved_Edu.filter((en: any)=>(en.institute != remoEdu));

    const requestBody={
      update_data: {
        educational_information: JSON.stringify(this.afterRemoved_Edu)
      }
    }

    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError(" Request not send","Error");
          } 
          else {
            this.apiService.displayGenericError(statusCode,response);  
          }
      }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
    });
  
  }


  passSelected_Exp_data(item: any){   
    console.log(item, "this is selected experience---hehehe");
    this.selectedExp_toBe_Edited.push(item);
  }
  
  Save_EditedExperience(editedPosition: any, editedCompany: any, editedDurationFrom: any,editedDurationTo: any, editedWorkDescritption: any){
    console.log("huuhuhuuuuhaaahaha----", editedPosition, editedCompany, editedDurationFrom,editedDurationTo, editedWorkDescritption);
    const durationFrom = new Date(editedDurationFrom).toISOString().substr(0, 7);
    const durationTo = new Date(editedDurationTo).toISOString().substr(0, 7);
    let newExp = {"position": editedPosition, "company": editedCompany,  "duration": `${durationFrom} to ${durationTo}`, "work_Description": editedWorkDescritption};
    console.log("this is present ", this.experienceInformation);
    this.experienceInformation = this.experienceInformation.map((item: any)=>{
      if(item.position === this.selectedExp_toBe_Edited[0].position){
        item.position = newExp.position;
        item.company = newExp.company;
        item.duration = newExp.duration;
        item.work_Description = newExp.work_Description;
      }
      return item;
    })
    console.log("this is MAP", this.experienceInformation);
    this.selectedExp_toBe_Edited.splice(0, 1);
    console.log(this.selectedExp_toBe_Edited, "cleaned");
    const requestBody={
      update_data: {
        experience_information: JSON.stringify(this.experienceInformation)
      }
    }
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } 
          else {
            this.apiService.displayGenericError(statusCode,response);  
          }
      }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
    });

    this.experiencesInformationForm = this.formBuilder.group({
      position: ['', Validators.required],
      company: ['', Validators.required],
      durationFrom: ['', Validators.required],
      durationTo: ['', Validators.required],
      workdescription: ['', Validators.required]
	  })

  } 



  addExperience(inputPosition: any, inputCompany: any, inputDurationFrom: any,inputDurationTo: any, inputWorkDescription: any){
    // console.log("position:- ",inputPosition, "company",  inputCompany );
    const DurationFrom = new Date(inputDurationFrom).toISOString().substr(0, 7);
    const DurationTo = new Date(inputDurationTo).toISOString().substr(0, 7);
    this.listExperience.push({id: this.listExperience.length, exp: {"position": inputPosition, "company":  inputCompany, "duration": `${DurationFrom} to ${DurationTo}`, "work_Description": inputWorkDescription}});
    console.log("here is experience list----",this.listExperience, "list length", this.listExperience.length);
    this.passListOfExperience = this.listExperience.map((item: any)=>(item.exp));
    console.log("hello array experience-----",this.passListOfExperience);

    this.experiencesInformationForm = this.formBuilder.group({
      position: ['', Validators.required],
      company: ['', Validators.required],
      durationFrom: ['', Validators.required],
      durationTo: ['', Validators.required],
      workdescription: ['', Validators.required],
	  })

    console.log("newnewsss in addExp,---", this.apiExpValue_saver);
    if(this.passListOfExperience.length >0){
      this.isSaveAllAllowed = false;
    }
    


    
  }

  ToRemoveExp(remo: any){
    console.log("selec--->",remo);
    this.afterRemoved_Exp = this.experienceInformation;
    this.afterRemoved_Exp = this.afterRemoved_Exp.filter((en: any)=>(en.company != remo));
    const requestBody = {
      update_data: {
        experience_information: JSON.stringify(this.afterRemoved_Exp)
      }
    }
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } 
          else {
            this.apiService.displayGenericError(statusCode,response);  
          }
      }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
    });

  }

  
  removeExperience(thsSeleid: any){
    console.log("this is latest id--", thsSeleid);
    this.listExperience = this.listExperience.filter((en: any)=>(en.id != thsSeleid));
    this.passListOfExperience = this.listExperience.map((item: any)=>(item.exp));
    console.log(this.listExperience, "updated listExpereince -----")
  }
  
// Skills section functions starts here inside this.   
  search(): void{
    console.log(this.searchText);
    // console.log(this.Skills.filter((item: any)=>(item.name.toUpperCase().includes(this.searchText.toUpperCase))), "---");
    if(this.searchText == ""){
      this.filteredSkills =[];
      return;
    }
    else{
      this.filteredSkills = this.Skills.filter((item: any)=>(item.name.toUpperCase().includes(this.searchText.toUpperCase())));
      console.log(this.filteredSkills, "this is filtered skills");
    }
  }
  selectSkill(skillentered: any): void{
    
    this.selectedSkills.push(skillentered);
    // this.selectedSkills = this.selectedSkills.map((item: any)=>(item.name));
    this.filteredSkills=[];
    this.searchText = "";
    if(this.selectedSkills.length > 0){
      this.isSaveSkill_Allowed = false;
    }
    // console.log(this.selectedSkills, "this is selectedskils", skillentered, "this is skill Entered");
  }
  removeSkill(skillToBeRemoved: any): void{
    this.selectedSkills = this.selectedSkills.filter((skill: any)=>(skill.name != skillToBeRemoved.name));
    if(this.selectedSkills.length >0){
      this.isSaveSkill_Allowed = false;
    }
    else{
      this.isSaveSkill_Allowed=true;
    }
  }
  saveSkills(){
    
    this.arrayOfSkillOnly = this.selectedSkills.map((item: any)=>(item.name));
    console.log(this.arrayOfSkillOnly, "here it is");
    const requestBody={
      update_data:{
        skills: `${this.arrayOfSkillOnly}`
      }
    }
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } else {
            this.apiService.displayGenericError(statusCode,response);  
                
          }
    }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
      });

    this.selectedSkills = [];
    
    
  }
// Skills sections functions ends here above here. Go to saveThisSkill() function down.


  

  saveTheseEducations(){
    console.log("jimmy array is here--", this.apiEducValue_filter);
    this.jam=[...this.apiEducValue_filter,...this.passListOfEducation];
    console.log(this.passListOfEducation, "before body and after this is jam", this.jam);
    const requestBody={
      update_data: {
        educational_information: JSON.stringify(this.jam)
      }
    }
    console.log("sample to check--", requestBody.update_data);
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } 
          else {
            this.apiService.displayGenericError(statusCode,response);  
          }
      }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
    });
    this.apiEducValue_saver=[];
    this.listEducation=[];
    this.is_EduSaveAllAllowed=true;


  }

  // this is the api call for Skills value submission
  saveTheseExperiences(){
    // for(var i=0;i<this.apiExpValue_filter.length; i++){
    //   this.listExperience.push({id: this.listExperience.length, exp: {"position": this.apiExpValue_filter[i].position, "company": this.apiExpValue_filter[i].company, "duration": this.apiExpValue_filter[i].duration}})
    // }
    // this.passListOfExperience = this.listExperience.map((item: any)=>(item.exp));
    // if(this.apiEducValue_filter.length > 0){
    //   for(var i=0;i<this.apiEducValue_filter.length;i++){
    //     this.passListOfExperience.push(this.apiEducValue_filter[i]);
    //   }
    // }
    // if(this.apiExpValue_filter && this.apiExpValue_filter.length>0){
    //   for(var i=0;i<this.apiExpValue_filter.length;i++){
    //     this.passListOfExperience.push(this.apiEducValue_filter[i]);
    //   }
    //   // console.log("dump this lists in list experience", this.apiEducValue_filter, "right side is passlistOfExperience", this.passListOfExperience);
    //   console.log( "updated list of pastListOfExperience", this.passListOfExperience);
    // }
    // this.jimmy = [...this.passListOfEducation, ...this.apiExpValue_filter];
    console.log("jimmy array is here--", this.apiExpValue_filter);

    this.jim=[...this.apiExpValue_filter,...this.passListOfExperience];

    console.log(this.passListOfExperience,"before body and after this is jim", this.jim);
    
    const requestBody={
      update_data: {
        experience_information: JSON.stringify(this.jim)
      }
    }
    console.log("sample to check--", requestBody.update_data);
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } 
          else {
            this.apiService.displayGenericError(statusCode,response);  
          }
      }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
    });
    this.apiExpValue_saver=[];
    this.listExperience=[];
    this.isSaveAllAllowed=true;

  }

  degrees: any =[
    {id: 0, name: "MBA"},
    {id: 1, name: "B.E/B.Tech in Computer Science"},
    {id: 2, name: "B.E/B.Tech in Information and Communication Technology"},
    {id: 3, name: "B.E/B.Tech in Electronics and Communication Engineering"},
    {id: 4, name: "M.E/M.Tech in Computer Science"},
    {id: 5, name: "M.E/M.Tech in Information and Communication Technology"},
    {id: 6, name: "M.E/M.Tech in Electronics and Communication Engineering"},
    {id: 7, name: "B.E/B.Tech in Biotechnology Engineering"},
    {id: 8, name: "M.E/M.Tech in Biotechnology Engineering"}
    
  ]

  positions: any =[
    {id: 0, name: "----0----"},
    {id: 1, name: "Software Engineer"},
    {id: 2, name: "Software Developer"},
    {id: 3, name: "Cloud Architect"},
    {id: 4, name: "Cloud Automation"},
    {id: 5, name: "Script Automation"},
    {id: 6, name: "FrontEnd Developer"},
    {id: 7, name: "BackEnd Developer"},
    {id: 8, name: "FullStack Developer"},
    {id: 9, name: "It Automation"},
    {id: 10, name: "Containerization"},
    {id: 11, name: "Kubernetes"},
    {id: 12, name: "Ansible"},
    {id: 13, name: "Terraform Automatator"},
    {id: 14, name: "QA Engineer"},
    {id: 15, name: "Rest-API Tester"},
    {id: 16, name: "Penetration Tester"},
    {id: 17, name: "Application Developer"},
    {id: 18, name: "UI/UX Designer"},
    {id: 19, name: "Data Base Administrator"},
    {id: 20, name: "Product Manager"},
    {id: 21, name: "Content Startegist"},
    {id: 22, name: "Blockchain Developer"},
    {id: 23, name: "Data Analyst"},
    {id: 24, name: "Data Scientist"},
    {id: 25, name: "IOS developer"}
  ]

  Skills: any =[
    {id: 1,  name: "ReactJs"},
    {id: 2,  name: "Angular"},
    {id: 3,  name: "NodeJs"},
    {id: 4,  name: "AWS Lambda"},
    {id: 5,  name: "Git"},
    {id: 6,  name: "GitHub"},
    {id: 7,  name: "ElasticSearch"},
    {id: 8,  name: "Figma"},
    {id: 9,  name: "Python"},
    {id: 10, name: 'Docker'},
    {id: 11, name: "VueJs"},
    {id: 12, name: "Eslint"},
    {id: 13, name: "Svelite"},
    {id: 14, name: "Django"},
    {id: 15, name: "Bit Bucket"},
    {id: 16, name: "Kanban"},
    {id: 17, name: "Jira"},
    {id: 18, name: "PHP"},
    {id: 19, name: "React Native"},
    {id: 20, name: 'Kotlin'},
    {id: 21, name: "Flutter"},
    {id: 22, name: "Google Cloud Platform"},
    {id: 23, name: "Azure"},
    {id: 24, name: "Red Hat"},
    {id: 25, name: "Kubernetes"},
    {id: 26, name: "Devtron"},
    {id: 27, name: "REST Api"},
    {id: 28, name: "HTML5"},
    {id: 29, name: "C++"},
    {id: 30, name: 'Virtualization'},
    {id: 31, name: "MySQL"},
    {id: 32, name: "BigData"},
    {id: 33, name: "Hadoop"},
    {id: 34, name: "MAchine Learning"},
    {id: 35, name: "Data Science"},
    {id: 36, name: ".NET"},
    {id: 37, name: "C#"},
    {id: 38, name: "iOS developement"},
    {id: 39, name: "JAVA"},
    {id: 40, name: 'Virtualization'},
    {id: 41, name: "Ansible"},
    {id: 42, name: "Selenium"},
    {id: 43, name: "VannilaJS"},
    {id: 44, name: "MongoDB"},
    {id: 45, name: "Cloud Automation"},
    {id: 46, name: "Vesion Control Automation"},
    {id: 47, name: "FireBase"},
    {id: 48, name: "TailwindCSS"},
    {id: 49, name: "BootstrapCSS"},
    {id: 50, name: 'Swift'},
    {id: 51, name: 'ExpressJS'},


  ];
  // Skills vaue and API call visible above

  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    console.info('got webcam image', this.sysImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.photoSelected = event.target.files && event.target.files.length > 0  && file.size <= this.maxSize && file.size >= this.minSize;
    const reader = new FileReader();

    reader.onload = () => {
      const fileContents = reader.result as string;
      this.base64Output_profile = btoa(fileContents);
      console.log(this.base64Output_profile);
    };
    reader.readAsBinaryString(file);
  }

  profilPhotoUpload(){
    // const token = JSON.parse(this.local.getData('authtoken'));
    const token = this.user.getToken()
    const requestBody = {
      base64_image: this.base64Output_profile
    }
    this.spinner.show();
      this.apiService.uploadImage(requestBody).subscribe((response: any) => {
        this.spinner.hide();
        this.profile();
        const{statusCode, message} = response
        if(statusCode == 200){
          this.profile();
          this.toast.showSuccess("Image is updated");
        }
        else{
           if(statusCode == 400){
              this.toast.showError("Please check the image format, Request not send","Error");
            } else {
              this.apiService.displayGenericError(statusCode,response);  
                  
            }
      }
        },
        (error: any) => {
          // Handle connection failure or API changes
          this.toast.showError("Failed to connect to server", "Error");
          this.spinner.hide();
        });
      }

      isAnyDocumentSelected(): boolean {
        return this.photoSelected;
      }

  profile() {
    const requestBody = {
      email: this.user.getEmail(),
      authtoken: this.user.getToken()
    }
    this.spinner.show();
    this.apiService.getUserProfile(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const {data, statusCode} = response;
      if(data && statusCode == 200){
        this.profileData = data;
        this.local.saveData('role', this.profileData.userrole);

        this.personalInformationForm.controls['name'].setValue(data.name);
        this.personalInformationForm.controls['phone'].setValue(data.organization_number);

        // this.professionalInformationForm.controls['github'].setValue(data.professional_information.github);
        // this.professionalInformationForm.controls['linkedin'].setValue(data.professional_information.linkedin);
        // this.professionalInformationForm.controls['medium'].setValue(data.professional_information.medium);
        // this.professionalInformationForm.controls['other'].setValue(data.professional_information.other);
        // this.geographicalInformationForm.controls['address'].setValue(data.geographical_information.address);
        // this.geographicalInformationForm.controls['city'].setValue(data.geographical_information.city);
        // this.geographicalInformationForm.controls['state'].setValue(data.geographical_information.state);
        // this.geographicalInformationForm.controls['country'].setValue(data.geographical_information.country);
        // this.geographicalInformationForm.controls['pincode'].setValue(data.geographical_information.pincode);
        // this.educationalInformationForm.controls['degree'].setValue(data.educational_information_information.degree);
        // this.educationalInformationForm.controls['institute'].setValue(data.educational_information_information.institute);
        // this.educationalInformationForm.controls['duration'].setValue(data.educational_information_information.duration);
        this.imageUrl = this.user.image(this.profileData);
        this.professionInformation = this.profileData.professional_information;
        this.geographicInformation = this.profileData.geographical_information;
        this.educationInformation = this.profileData.educational_information;
        this.experienceInformation = this.profileData.experience_information;
        this.firstCh = this.profileData.name.charAt(0);
        // console.log(this.profileData);

        this.apiExpValue_saver=[];
        // for(var i=0; i<this.experienceInformation.length; i++){
          
        //   this.apiExpValue_saver.push({id: this.apiExpValue_saver.length, exp: {"position": this.experienceInformation[i].position, "company": this.experienceInformation[i].company, "duration": this.experienceInformation[i].duration, "work_Description": this.experienceInformation[i].work_Description}});
        // }
        this.apiExpValue_filter =  this.apiExpValue_saver.map((item: any)=>(item.exp));

        this.apiEducValue_saver=[];
        // for(var i=0; i<this.educationInformation.length; i++){
        //   this.apiEducValue_saver.push({id: this.apiEducValue_saver.length, educ: {"degree": this.educationInformation[i].degree, "institute": this.educationInformation[i].institute, "duration": this.educationInformation[i].duration}});
        // }
        this.apiEducValue_filter = this.apiEducValue_saver.map((item: any)=>(item.educ));
        
        // for(var i=0; i< this.experienceInformation.length; i++){

        //   this.apiExpValue_saver.push({id: this.apiExpValue_saver.length, exp: {"position": this.experienceInformation[i].position,"company": this.experienceInformation[i].company, "duration": this.experienceInformation[i].duration}})
        // }
        // this.apiExpValue_filter = this.apiExpValue_saver.map((item: any)=>(item.exp));
        // console.log("hey hceck--", this.apiExpValue_saver);
        // console.log("this is before edit", this.apiExpValue_saver, "mapped", this.apiExpValue_filter);


        this.skills = this.profileData.skills;
      } else if(statusCode == 400){
        this.toast.showError('Session expired, please login again', 'Error');
        this.router.navigate(['/login']);
      }
    },
    (error: any) => {
      // Handle connection failure or API changes
      this.toast.showError("Failed to connect to server", "Error");
      this.spinner.hide();
    });
    
  }


  personalInformation(){
    const requestBody = {
      update_data: {
          username: this.personalInformationForm.value.name,
          phone: this.personalInformationForm.value.phone.toString()
      }
    }
    this.spinner.show();
    this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.profile();
        this.toast.showSuccess("Information is updated");
        console.log(this.profileData.phone)
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } else {
            this.apiService.displayGenericError(statusCode,response);  
                
          }
    }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
      });
    }

    professionalInformation(){
      // const requestBody = {
      //   "update_data": {
      //     "professional_information": JSON.stringify({
      //       "github": this.professionalInformationForm.value.github,
      //       "linkedin": this.professionalInformationForm.value.linkedin,
      //       "medium": this.professionalInformationForm.value.medium,
      //       "other": this.professionalInformationForm.value.other,
      //     })
      //   }
      // }
      //const profesional :any = JSON.parse(this.local.getData('professionalInformationForm'));
      const requestBody = {
        update_data: {
          professional_information: JSON.stringify({
            github: this.professionalInformationForm.value.github,
            linkedin: this.professionalInformationForm.value.linkedin,
            medium: this.professionalInformationForm.value.medium,
            other: this.professionalInformationForm.value.other,
          })
        }
      }
      this.spinner.show();
      this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
        this.spinner.hide();
        const{statusCode, message} = response
        if(statusCode == 200){
          this.profile();
          this.toast.showSuccess("Information is updated");
        }
        else{
           if(statusCode == 400){
              this.toast.showError("Request not send","Error");
            } 
            else {
              this.apiService.displayGenericError(statusCode,response);  
            }
        }
        },
        (error: any) => {
          // Handle connection failure or API changes
          this.toast.showError("Failed to connect to server", "Error");
          this.spinner.hide();
        });
      }

      geographicalInformation(){
        const requestBody = {
          update_data: {
            geographical_information: JSON.stringify({
              address: this.geographicalInformationForm.value.address,
              city: this.geographicalInformationForm.value.city,
              state: this.geographicalInformationForm.value.state,
              country: this.geographicalInformationForm.value.country,
              pincode: this.geographicalInformationForm.value.pincode,
            })
          }
        }
        this.spinner.show();
        this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
          this.spinner.hide();
          const{statusCode, message} = response
          if(statusCode == 200){
            this.profile();
            this.toast.showSuccess("Information is updated");
          }
          else{
             if(statusCode == 400){
                this.toast.showError("Request not send","Error");
              } else {
                this.apiService.displayGenericError(statusCode,response);  
                    
              }
        }
          },
          (error: any) => {
            // Handle connection failure or API changes
            this.toast.showError("Failed to connect to server", "Error");
            this.spinner.hide();
          });
        }

        educationalInformation(){
          //const profesional :any = JSON.parse(this.local.getData('professionalInformationForm'));
          const requestBody = {
            update_data: {
              educational_information: JSON.stringify({
                degree: this.educationalInformationForm.value.degree,
                institute: this.educationalInformationForm.value.institute,
                duration: this.educationalInformationForm.value.duration,
              })
            }
          }
          this.spinner.show();
          this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
            this.spinner.hide();
            const{statusCode, message} = response
            if(statusCode == 200){
              this.profile();
              this.toast.showSuccess("Information is updated");
            }
            else{
               if(statusCode == 400){
                  this.toast.showError("Request not send","Error");
                } 
                else {
                  this.apiService.displayGenericError(statusCode,response);  
                }
            }
            },
            (error: any) => {
              // Handle connection failure or API changes
              this.toast.showError("Failed to connect to server", "Error");
              this.spinner.hide();
            });
          }

          experiencesInformation(){
            //const profesional :any = JSON.parse(this.local.getData('professionalInformationForm'));
            const requestBody = {
              update_data: {
                experience_information: JSON.stringify({
                  position: this.experiencesInformationForm.value.position,
                  company: this.experiencesInformationForm.value.company,
                  duration: this.experiencesInformationForm.value.duration,
                })
              }
            }
            this.spinner.show();
            this.apiService.updateProfileuser(requestBody).subscribe((response: any) => {
              this.spinner.hide();
              const{statusCode, message} = response
              if(statusCode == 200){
                this.profile();
                this.toast.showSuccess("Information is updated");
              }
              else{
                 if(statusCode == 400){
                    this.toast.showError("Request not send","Error");
                  } 
                  else {
                    this.apiService.displayGenericError(statusCode,response);  
                  }
              }
              },
              (error: any) => {
                // Handle connection failure or API changes
                this.toast.showError("Failed to connect to server", "Error");
                this.spinner.hide();
              });
            }
    
  changepassword(){
    const requestBody = {
      pre_password: this.changepasswordForm.value.pre_password,
      new_password: this.changepasswordForm.value.new_password
    }
    this.spinner.show();
    this.apiService.changePassword(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const{statusCode, message} = response
      if(statusCode == 200){
        this.router.navigate(['/login']);
        this.toast.showSuccess("Password has been updated");
      }
      else{
         if(statusCode == 400){
            this.toast.showError("Request not send","Error");
          } else {
            this.apiService.displayGenericError(statusCode,response);  
                
          }
    }
      },
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
      });
    } catch (err: any) {
      this.toast.showError(err,"Error");
    }
}