<!--start header -->
<header>
	<div class="topbar d-flex align-items-center">
		<nav class="navbar navbar-expand">
			<div class="mobile-toggle-menu"><i (click)="toggleSidebar()" class='bx bx-menu'></i>
			</div>
			
			<div class="top-menu ms-auto">
				
			</div>
			<div class="user-box dropdown">
				<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
					<!-- <img style="margin: 5px;"  src="{{imageUrl}}" alt="Profile Image" class="user-img" alt="user avatar"> -->
					<!-- <div class="user-img" id="profileImage">{{firstCh}}</div> -->
					<img style="margin: 5px;" *ngIf="userDetails" src="https://api.dicebear.com/9.x/initials/svg?seed={{userDetails?.name}}" alt="Profile Image" class="user-img" alt="user avatar">
					<!-- <div id="profileImage" *ngIf="!imageUrl">{{firstCh}}</div> -->

					<!-- <span id="fullName">{{name}}</span> -->
					<div class="user-info ps-3">
						<p class="user-name mb-0">{{name}}</p>
					</div>
				</a>
				<ul class="dropdown-menu dropdown-menu-end" >
					<li><a class="dropdown-item" routerLink="/user/profile"><i class="bx bx-user"></i><span>Profile</span></a>
					</li>
					<!-- <li><a class="dropdown-item" routerLink="/ecommerce/orders"><i class="bx bx-cog"></i><span>Orders</span></a>
					</li>
					<li><a class="dropdown-item" routerLink="/dashboard/e-commerce"><i class='bx bx-home-circle'></i><span>Dashboard</span></a>
					</li>-->
					<li><a class="dropdown-item" routerLink="/user/faq"><i class='bx bx-dollar-circle'></i><span>FAQ</span></a>
					</li> 
					<!-- <li><a class="dropdown-item" routerLink="/user/download"><i class='bx bx-download'></i><span>Downloads</span></a>
					</li> -->
					<!-- <li><a data-test-id="upload" class="dropdown-item" routerLink="/user/document"><i class='bx bx-upload'></i><span>Upload</span></a>
					</li> -->
					<!-- <li>
						<a class="dropdown-item" (click)="openModal()"><i class='bx bx-lock'></i><span>Change Password</span></a>
					</li> -->
					<li>
						<div class="dropdown-divider mb-0"></div>
					</li>
					<li><a *ngIf="!isNotLoggedIn" (click)="logout()" class="dropdown-item" routerLink="/login"><i class='bx bx-log-out-circle'></i><span>Logout</span></a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</header>
<!--end header -->