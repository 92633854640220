import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "./sidebar.service";

//logout dependencies
import { AuthService } from 'src/app/shared/auth.service';
import { ToastService } from 'src/app/shared/toast.service';
import { ApiService } from 'src/app/shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalService } from 'src/app/shared/local.service';
import { UserService } from 'src/app/shared/user.service';

import * as $ from 'jquery';
import { CognitoService } from 'src/app/cognito.service';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    
    isNotLoggedIn = false;
    public menuItems: any[] = [];
    isRotated = false;

  
    constructor( public sidebarservice: SidebarService,private router: Router, private auth: AuthService, private apiService: ApiService, private spinner: NgxSpinnerService, private toast: ToastService, private local: LocalService, private user: UserService, private cognitoService: CognitoService) {

        router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            // if (event instanceof NavigationEnd && $(window).width() < 1025 && ( document.readyState == 'complete' || false ) ) {

            //     this.toggleSidebar();
            //     // Hide loading indicator
               
            // }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }

        
    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
        this.isRotated = !this.isRotated;
        if ($(".wrapper").hasClass("nav-collapsed")) {
            // unpin sidebar when hovered
            $(".wrapper").removeClass("nav-collapsed");
            $(".sidebar-wrapper").unbind( "hover");
        } else {
            $(".wrapper").addClass("nav-collapsed");
            $(".sidebar-wrapper").hover(
                function () {
                    $(".wrapper").addClass("sidebar-hovered");
                },
                function () {
                    $(".wrapper").removeClass("sidebar-hovered");
                }
            )
      
        }

    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }

    // logout() {
    //     // const token = JSON.parse(this.local.getData('authtoken'));
    //     const token = this.user.getToken();
    //     const reqBody = {
    //     //   email: this.local.getData('email'),
    //     //   email: this.user.getEmail(),
    //     //   authtoken: this.local.getData("authtoken")
    //     }
    
    //     try {
    //       this.spinner.show();
    //       this.apiService.globalLogout(reqBody).subscribe((response: any) => {
    //         this.auth.logout();
    //         this.spinner.hide();
    //         this.router.navigate(['/admin']); // the route will redirect to admin portal 
    //       });
    //     } catch (err: any) {
    //       this.spinner.hide();
    //       this.toast.showError(err, "Error");
    //     }
    //   }
    
    logout(){
        this.cognitoService.signOut().then((res)=>{
          
        })
        localStorage.clear();
      }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        $.getScript('./assets/js/app-sidebar.js');
        // logout api calling here to bulona admin portal. not global logout.
        this.isNotLoggedIn = this.auth.isNotLoggedIn();

    }


}
