import { Injectable } from '@angular/core';
import { Subject , BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private websocket!: WebSocket;
  private messageSubject = new BehaviorSubject<string>('');
  public message$ = this.messageSubject.asObservable();
  parsedData: any;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private reconnectInterval = 3000;

  constructor() {}

  connect(userId: string): void {
    const wsUrl = `wss://2lph2ejr31.execute-api.ap-south-1.amazonaws.com/production?userId=${userId}`;
    this.websocket = new WebSocket(wsUrl);

    this.websocket.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.websocket.onmessage = (message) => {
      // console.log('Message received from server:', message.data);
      this.parsedData = message.data ? JSON.parse(message.data) : message.data;
      this.messageSubject.next(this.parsedData);
      // Handle incoming messages here
    };

    this.websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.websocket.onclose = () => {
      console.log('WebSocket connection closed');
      this.handleReconnection(userId)
    };
  }

  private handleReconnection(userId: string): void {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Attempting to reconnect... Attempt ${this.reconnectAttempts}`);
      setTimeout(() => {
        this.connect(userId);
      }, this.reconnectInterval);
    } else {
      console.error('Max reconnection attempts reached');
    }
  }

  disconnect(): void {
    if (this.websocket) {
      this.websocket.close();
    }
  }

  sendMessage(message: any): void {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(JSON.stringify(message));
      console.log("Message sent")
    } else {
      console.error('WebSocket connection is not open');
    }
  }

  close(): void {
    if (this.websocket) {
      this.websocket.close();
    }
  }
}
