<form [formGroup]="multistep">
  <section
    class="login_page_main"
    style="
      background-image: url(../../../assets/images/landing/hero-1-bg-img.png);
    "
  >
    <div class="container-fluid">
      <div class="row align-items-center">
        <div *ngIf="step == 3" class="col-lg-6 p-5 d-none d-sm-block">
          <div class="left_img">
            <h1>
              Explore Interesting <br />
              Cyber Projects
            </h1>
            <img src="assets/login.png" />
          </div>
        </div>
        <div *ngIf="step == 4" class="col-lg-6 p-5 d-none d-sm-block">
          <div class="left_img">
            <h1>Your chosen Invinsense tools and services</h1>
            <img src="../assets/images/decentralized-application.png" alt="" />
          </div>
        </div>
        <div *ngIf="step == 5" class="col-lg-6 p-5 d-none d-sm-block">
          <div class="left_img">
            <h1>Start your cybersecurity through SAAS</h1>
            <img src="../assets/images/cloud-storage.png" alt="" />
          </div>
        </div>
        <div *ngIf="step == 6" class="col-lg-6 p-5 d-none d-sm-block">
          <div class="left_img">
            <h1>
              As per your account information and selected solutions we will
              deploy all tools in environment.
            </h1>
            <img src="../assets/images/cloud-collaboration.png" alt="" />
          </div>
        </div>
        <div *ngIf="step == 7" class="col-lg-6 p-5 d-none d-sm-block">
          <div class="left_img">
            <h1>
              May be you can have your coffee, while these solutions are spined
              in a few mins
            </h1>
            <img src="../assets/images/cloud-collaboration.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6 p-3">
          <div class="right_form_main">
            <div class="form_top_text">
              <div>
                <h2 *ngIf="step == 3">Verify your Invinsense account</h2>
                <h2 *ngIf="step == 4">Your Selected Component</h2>
                <h2 *ngIf="step == 5">Your Cloud</h2>
              </div>
            </div>
            <div class="right_form_inside">
              <!-- ...Your Account... -->
              <section id="account" class="tab" *ngIf="step == 3">
                <div class="info">
                  <h3 class="form_tital">Account Information</h3>
                  <ng-container *ngIf="customer">
                    <div class="row">
                      <div class="col-lg-6">
                        <label for="">User Name</label>
                        <div class="form-group formfield">
                          <input
                            disabled
                            class="form-control"
                            type="text"
                            name="full-name"
                            value="{{ customer.name }}"
                          />
                        </div>
                        <!-- <h6>Supporting Text</h6> -->
                      </div>
                      <div class="col-lg-6">
                        <label for="">Organization Name</label>
                        <div class="form-group formfield">
                          <input
                            disabled
                            class="form-control"
                            type="text"
                            name="company-name"
                            value="{{ customer.organization }}"
                          />
                          <!-- <h6>Supporting Text</h6> -->
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label for="">Job Title</label>
                        <div class="form-group formfield">
                          <input
                            disabled
                            class="form-control"
                            type="text"
                            name="job-title"
                            value="{{ customer.job_title }}"
                          />
                          <!-- <h6>Supporting Text</h6> -->
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label for="">Phone Number</label>
                        <div class="form-group formfield">
                          <input
                            disabled
                            class="form-control"
                            type="phone"
                            name="buss-phone"
                            value="{{ customer.mobile_number }}"
                          />
                          <!-- <h6>Supporting Text</h6> -->
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label for="">Email</label>
                        <div class="form-group formfield">
                          <input
                            disabled
                            class="form-control"
                            type="email"
                            name="alt-email"
                            value="{{ customer.email }}"
                          />
                          <!-- <h6>Supporting Text</h6> -->
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label for="">Deployment Tpye</label>
                        <div class="form-group formfield">
                          <input
                            disabled
                            class="form-control"
                            type="text"
                            name="alt-job-title"
                            value="{{ customer.own_cloud ? 'BYOD' : 'SAAS' }}"
                          />
                          <!-- <h6>Supporting Text</h6> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-12 confirm">
                                            <input type="checkbox" name="terms" checked={{customer.terms}}>
                                            <label for="terms">Please confirm that you agree to our
                                                terms & conditions.<br>Your
                                                details will help our team to be well
                                                connected.</label>
                                        </div> -->
                  </ng-container>
                  <hr />
                  <div>
                    <div class="row">
                      <!-- <div (click)="prev()" class="col"><button type="submit"
                                                    class="btn btn-primary float-start">Previous</button>
                                                </div> -->
                      <button
                        *ngIf="customer?.own_cloud"
                        style="
                          background-image: url(&quot;../assets/images/cloudformation-launch-stack.png&quot;);
                          background-size: auto;
                          width: 144px;
                          height: 27px;
                          border: none;
                          cursor: pointer;
                        "
                        (click)="redirectToCloudFormation()"
                      ></button>
                      <div (click)="next()" class="col">
                        <button
                          type="submit"
                          [disabled]="customer?.own && !clicked"
                          class="btn btn-primary"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- ...Your Components... -->
              <section id="components" class="tab" *ngIf="step == 4">
                <div class="wrapper">
                  <div class="row">
                    <div class="col">
                      <ng-container *ngIf="customer">
                        <div class="row">
                          <div class="col-lg-12">
                            <h3 class="form_tital">Solutions</h3>
                            <div *ngIf="!xdrAvailable && !xdrpAvailable && !oxdrAvailable && !gsosAvailable">
                              <div class="dropdown">
                                <div
                                  class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                  style="min-width: 200px"
                                >
                                  <button
                                    class="btn dropdown-toggle hide-border-btn"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="bi bi-chevron-down"></i>
                                  </button>
                                  <span>XDR Components</span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <ng-container
                                      *ngFor="let xdr of xdrComponents"
                                    >
                                      <div class="dropdown-item">
                                        <input
                                          disabled
                                          type="checkbox"
                                          id="{{ xdr.id }}"
                                          name="{{ xdr.name }}"
                                          value="{{ xdr.title }}"
                                          [attr.checked]="
                                            xdr.status === '1' ? 'checked' : null
                                          "
                                        />&nbsp;
                                        <label
                                          for="{{ xdr.id }}"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="{{ xdr.description }}"
                                          >{{ xdr.title }}</label
                                        >
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <!-- <input
                                                                  [attr.checked]="customer.solutions.xdr === 'true' ? 'checked' : null"
                                                                  type="checkbox" id="list1" name="list1"> -->
                              </div>
                              <div class="dropdown">
                                <div
                                  class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                  style="min-width: 200px"
                                >
                                  <button
                                    class="btn dropdown-toggle hide-border-btn"
                                    type="button"
                                    id="dropdownMenuButtonOne"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="bi bi-chevron-down"></i>
                                  </button>
                                  <span>XDR+ Components</span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButtonOne"
                                  >
                                    <ng-container
                                      *ngFor="let xdr of xdrPlusComponents"
                                    >
                                      <div class="dropdown-item">
                                        <input
                                          disabled
                                          type="checkbox"
                                          id="{{ xdr.id }}"
                                          name="{{ xdr.name }}"
                                          value="{{ xdr.title }}"
                                          [attr.checked]="
                                            xdr.status === '1' ? 'checked' : null
                                          "
                                        />&nbsp;
                                        <label
                                          for="{{ xdr.id }}"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="{{ xdr.description }}"
                                          >{{ xdr.title }}</label
                                        >
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <!-- <input
                                                                  [attr.checked]="customer.solutions.xdrplus === 'true' ? 'checked' : null"
                                                                  type="checkbox" id="list2" name="list2"> -->
                              </div>
                              <div class="dropdown">
                                <div
                                  class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                  style="min-width: 200px"
                                >
                                  <button
                                    class="btn dropdown-toggle hide-border-btn"
                                    type="button"
                                    id="dropdownMenuButtonTwo"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="bi bi-chevron-down"></i>
                                  </button>
                                  <span>OXDR Components</span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButtonTwo"
                                  >
                                    <ng-container
                                      *ngFor="let xdr of oxdrComponents"
                                    >
                                      <div class="dropdown-item">
                                        <input
                                          disabled
                                          type="checkbox"
                                          id="{{ xdr.id }}"
                                          name="{{ xdr.name }}"
                                          value="{{ xdr.title }}"
                                          [attr.checked]="
                                            xdr.status == '1' ? 'checked' : null
                                          "
                                        />&nbsp;
                                        <label
                                          for="{{ xdr.id }}"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="{{ xdr.description }}"
                                          >{{ xdr.title }}</label
                                        >
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <!-- <input
                                                                  [attr.checked]="customer.solutions.oxdr === 'true' ? 'checked' : null"
                                                                  type="checkbox" id="list3" name="list3"> -->
                              </div>
                              <div class="dropdown">
                                <div
                                  class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                  style="min-width: 200px"
                                >
                                  <button
                                    class="btn dropdown-toggle hide-border-btn"
                                    type="button"
                                    id="dropdownMenuButtonThree"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="bi bi-chevron-down"></i>
                                  </button>
                                  <span>GSOS Components</span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButtonThree"
                                  >
                                    <ng-container
                                      *ngFor="let xdr of gsosComponents"
                                    >
                                      <div class="dropdown-item">
                                        <input
                                          disabled
                                          type="checkbox"
                                          id="{{ xdr.id }}"
                                          name="{{ xdr.name }}"
                                          value="{{ xdr.title }}"
                                          [attr.checked]="
                                            xdr.status == '1' ? 'checked' : null
                                          "
                                        />&nbsp;
                                        <label
                                          for="{{ xdr.id }}"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="{{ xdr.description }}"
                                          >{{ xdr.title }}</label
                                        >
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <!-- <input
                                                                  [attr.checked]="customer.solutions.gsos === 'true' ? 'checked' : null"
                                                                  type="checkbox" id="list4" name="list4"> -->
                              </div>
                            </div>
                            <div class="dropdown" *ngIf="xdrAvailable">
                              <div
                                class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                style="min-width: 200px"
                              >
                                <button
                                  class="btn dropdown-toggle hide-border-btn"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="bi bi-chevron-down"></i>
                                </button>
                                <span>XDR Components</span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <ng-container
                                    *ngFor="let xdr of xdrComponents"
                                  >
                                    <div class="dropdown-item">
                                      <input
                                        disabled
                                        type="checkbox"
                                        id="{{ xdr.id }}"
                                        name="{{ xdr.name }}"
                                        value="{{ xdr.title }}"
                                        [attr.checked]="
                                          xdr.status === '1' ? 'checked' : null
                                        "
                                      />&nbsp;
                                      <label
                                        for="{{ xdr.id }}"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="{{ xdr.description }}"
                                        >{{ xdr.title }}</label
                                      >
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                              <!-- <input
                                                                [attr.checked]="customer.solutions.xdr === 'true' ? 'checked' : null"
                                                                type="checkbox" id="list1" name="list1"> -->
                            </div>
                            <div class="dropdown" *ngIf="xdrpAvailable">
                              <div
                                class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                style="min-width: 200px"
                              >
                                <button
                                  class="btn dropdown-toggle hide-border-btn"
                                  type="button"
                                  id="dropdownMenuButtonOne"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="bi bi-chevron-down"></i>
                                </button>
                                <span>XDR+ Components</span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButtonOne"
                                >
                                  <ng-container
                                    *ngFor="let xdr of xdrPlusComponents"
                                  >
                                    <div class="dropdown-item">
                                      <input
                                        disabled
                                        type="checkbox"
                                        id="{{ xdr.id }}"
                                        name="{{ xdr.name }}"
                                        value="{{ xdr.title }}"
                                        [attr.checked]="
                                          xdr.status === '1' ? 'checked' : null
                                        "
                                      />&nbsp;
                                      <label
                                        for="{{ xdr.id }}"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="{{ xdr.description }}"
                                        >{{ xdr.title }}</label
                                      >
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                              <!-- <input
                                                                [attr.checked]="customer.solutions.xdrplus === 'true' ? 'checked' : null"
                                                                type="checkbox" id="list2" name="list2"> -->
                            </div>
                            <div class="dropdown" *ngIf="oxdrAvailable">
                              <div
                                class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                style="min-width: 200px"
                              >
                                <button
                                  class="btn dropdown-toggle hide-border-btn"
                                  type="button"
                                  id="dropdownMenuButtonTwo"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="bi bi-chevron-down"></i>
                                </button>
                                <span>OXDR Components</span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButtonTwo"
                                >
                                  <ng-container
                                    *ngFor="let xdr of oxdrComponents"
                                  >
                                    <div class="dropdown-item">
                                      <input
                                        disabled
                                        type="checkbox"
                                        id="{{ xdr.id }}"
                                        name="{{ xdr.name }}"
                                        value="{{ xdr.title }}"
                                        [attr.checked]="
                                          'checked'
                                        "
                                      />&nbsp;
                                      <label
                                        for="{{ xdr.id }}"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="{{ xdr.description }}"
                                        >{{ xdr.title }}</label
                                      >
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                              <!-- <input
                                                                [attr.checked]="customer.solutions.oxdr === 'true' ? 'checked' : null"
                                                                type="checkbox" id="list3" name="list3"> -->
                            </div>
                            <div class="dropdown" *ngIf="gsosAvailable">
                              <div
                                class="dropdown-toggle-wrapper d-inline-flex flex-row-reverse align-items-center justify-content-between"
                                style="min-width: 200px"
                              >
                                <button
                                  class="btn dropdown-toggle hide-border-btn"
                                  type="button"
                                  id="dropdownMenuButtonThree"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="bi bi-chevron-down"></i>
                                </button>
                                <span>GSOS Components</span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButtonThree"
                                >
                                  <ng-container
                                    *ngFor="let xdr of gsosComponents"
                                  >
                                    <div class="dropdown-item">
                                      <input
                                        disabled
                                        type="checkbox"
                                        id="{{ xdr.id }}"
                                        name="{{ xdr.name }}"
                                        value="{{ xdr.title }}"
                                        [attr.checked]="
                                          xdr.status == '1' ? 'checked' : null
                                        "
                                      />&nbsp;
                                      <label
                                        for="{{ xdr.id }}"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="{{ xdr.description }}"
                                        >{{ xdr.title }}</label
                                      >
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                              <!-- <input
                                                                [attr.checked]="customer.solutions.gsos === 'true' ? 'checked' : null"
                                                                type="checkbox" id="list4" name="list4"> -->
                            </div>
                          </div>
                          <!-- <div class="col-lg-6">
                                                        <h1>Services</h1>
                                                        <div class="service">
                                                            <span class="service1">MDR</span>
                                                            <input
                                                                [attr.checked]="customer.services.mdr === 'true' ? 'checked' : null"
                                                                type="checkbox" id="service1" name="service1">
                                                        </div>
                                                        <div class="service">
                                                            <span class="service2">OMDR</span>
                                                            <input
                                                                [attr.checked]="customer.services.omdr === 'true' ? 'checked' : null"
                                                                type="checkbox" id="service2" name="service2">
                                                        </div>
                                                        <div class="service">
                                                            <span class="service3">MDR+</span>
                                                            <input
                                                                [attr.checked]="customer.services.mdrplus === 'true' ? 'checked' : null"
                                                                type="checkbox" id="service3" name="service3">
                                                        </div>
                                                        <div class="service">
                                                            <span class="service4">VCISO & Compliance</span>
                                                            <input
                                                                [attr.checked]="customer.services.vciso === 'true' ? 'checked' : null"
                                                                type="checkbox" id="service4" name="service4">
                                                        </div>
                                                    </div> -->
                        </div>
                        <hr />
                        <div class="submit">
                          <input
                            type="button"
                            class="float-start btn btn-primary"
                            value="Previous"
                            (click)="prev()"
                          />
                          <!-- <input type="button" class="next" value="Next" (click)="next()"> -->
                          <button
                            type="submit"
                            class="next btn btn-primary"
                            (click)="next()"
                          >
                            Continue
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </section>

              <!-- ...Your CLoud... -->
              <section
                id="cloud"
                class="tab"
                *ngIf="step == 5 && customer.own_cloud == false"
              >
                <div class="wrapper">
                  <div class="row">
                    <div class="col-lg-12">
                      <ng-container *ngIf="customer">
                        <div class="row">
                          <div class="cloud-info">
                            <h1>Cloud Services</h1>
                            <div class="services-list">
                              <ul style="list-style-type: none">
                                <li>
                                  <div class="details">
                                    <img
                                      src="../assets/images/cloud1.png"
                                      alt=""
                                    />
                                    <div class="text">
                                      <h5>
                                        AWS
                                        <input
                                          disabled
                                          type="checkbox"
                                          name="cloud1"
                                          [checked]="cloud_platform === 'AWS'"
                                        />
                                      </h5>
                                      <h6>
                                        Build, Deploy, and Manage Websites,
                                        Apps, On AWS' Secure, Reliable Network.
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <hr />
                                <li>
                                  <div class="details">
                                    <img
                                      src="../assets/images/cloud2.png"
                                      alt=""
                                    />
                                    <div class="text">
                                      <h5>
                                        Azure
                                        <input
                                          type="checkbox"
                                          name="cloud2"
                                          [checked]="cloud_platform === 'azure'"
                                          disabled
                                        />
                                      </h5>
                                      <h6>
                                        Supporting line text lorem ipsum dolor
                                        sit amet, consectetur
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <hr />
                                <li>
                                  <div class="details">
                                    <img
                                      src="../assets/images/cloud3.png"
                                      alt=""
                                    />
                                    <div class="text">
                                      <h5>
                                        Google Cloud Platform
                                        <input
                                          type="checkbox"
                                          name="cloud3"
                                          [checked]="cloud_platform === 'gcp'"
                                          disabled
                                        />
                                      </h5>
                                      <h6>
                                        Supporting line text lorem ipsum dolor
                                        sit amet, consectetur
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div class="submit">
                              <input
                                type="button"
                                class="prev float-start btn btn-primary"
                                value="Previous"
                                (click)="prev()"
                              />
                              <!-- <input type="button" class="next" value="Next" (click)="next()"> -->
                              <button
                                type="submit"
                                class="next btn btn-primary float-end"
                                (click)="next()"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </section>

              <!-- ...Your CLoud... -->
              <section
                id="cloud"
                class="tab"
                *ngIf="step == 5 && customer.own_cloud == true"
              >
                <div class="wrapper">
                  <div class="row">
                    <div class="col-lg-12">
                      <ng-container *ngIf="customer">
                        <div class="row">
                          <div class="cloud-info">
                            <!-- <h1>Cloud Services</h1>
                            <hr> -->
                            <div
                              class="service-list"
                              style="
                                background-color: #f2f2f2;
                                padding: 20px;
                                border-radius: 5px;
                              "
                            >
                              <div style="text-align: center">
                                <h5
                                  style="
                                    color: #333;
                                    font-size: 24px;
                                    margin-bottom: 10px;
                                  "
                                >
                                  Cloud Platform
                                </h5>
                                <p style="color: #666; font-size: 18px">
                                  {{ customer.cloud_platform }}
                                </p>
                              </div>
                              <hr />
                              <div style="text-align: center">
                                <h5
                                  style="
                                    color: #333;
                                    font-size: 24px;
                                    margin-bottom: 10px;
                                  "
                                >
                                  Account Id
                                </h5>
                                <p style="color: #666; font-size: 18px">
                                  {{ customer.account_id }}
                                </p>
                              </div>
                            </div>
                            <!-- <div class="services-list">
                              <ul>
                                <li>
                                  <div class="details">
                                    <img
                                      src="../assets/images/cloud1.png"
                                      alt=""
                                    />
                                    <div class="text">
                                      <h5>AWS</h5>
                                      <h6>
                                        Build, Deploy, and Manage Websites,
                                        Apps, On AWS' Secure, Reliable Network.
                                      </h6>
                                    </div>
                                  </div>
                                  <input
                                    disabled
                                    type="checkbox"
                                    name="cloud1"
                                    [attr.checked]="true"
                                  />
                                </li>
                                <hr />
                                <li>
                                  <div class="details">
                                    <img
                                      src="../assets/images/cloud2.png"
                                      alt=""
                                    />
                                    <div class="text">
                                      <h5>Azure</h5>
                                      <h6>
                                        Supporting line text lorem ipsum dolor
                                        sit amet, consectetur
                                      </h6>
                                    </div>
                                  </div>
                                  <input
                                    type="checkbox"
                                    name="cloud2"
                                    [attr.checked]="null"
                                    disabled
                                  />
                                </li>
                                <hr />
                                <li>
                                  <div class="details">
                                    <img
                                      src="../assets/images/cloud3.png"
                                      alt=""
                                    />
                                    <div class="text">
                                      <h5>Google Cloud Platform</h5>
                                      <h6>
                                        Supporting line text lorem ipsum dolor
                                        sit amet, consectetur
                                      </h6>
                                    </div>
                                  </div>
                                  <input
                                    type="checkbox"
                                    name="cloud3"
                                    [attr.checked]="null"
                                    disabled
                                  />
                                </li>
                              </ul>
                            </div> -->
                            <hr />
                            <div class="submit">
                              <input
                                type="button"
                                class="prev float-start btn btn-primary"
                                value="Previous"
                                (click)="prev()"
                              />
                              <!-- <input type="button" class="next" value="Next" (click)="next()"> -->
                              <button
                                type="submit"
                                class="next btn btn-primary float-end"
                                (click)="next()"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </section>

              <!-- ...Your Cloud Details... -->
              <section id="cloud-details" class="tab" *ngIf="step == 6">
                <div class="wrapper">
                  <div class="row">
                    <div class="col-lg-12">
                      <div>
                        <h2 style="margin-bottom: 30px;">Deploy Solutions</h2>
                        <div class="selected-cloud-info">
                          <!-- <h1>FULL VPC (Virtual Private Cloud)</h1> -->
                          <div class="services-details">
                            <div *ngFor="let item of showTools">
                              <div *ngFor="let key of toolsArr">
                                <div *ngIf="key.name === item.name">
                                  <h5>{{ item.name }}</h5>
                                  <br />
                                  <p>{{ key.description }}</p>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="xdrAvailable">
                              <div *ngFor="let component of xdrComponents">
                                <h5>{{ component.title }}</h5>
                                <p>{{ component.description }}</p>
                                <br />
                              </div>
                            </div>
                            <div *ngIf="xdrpAvailable">
                              <div *ngFor="let component of xdrPlusComponents">
                                <h5>{{ component.title }}</h5>
                                <p>{{ component.description }}</p>
                                <br />
                              </div>
                            </div>
                            <div *ngIf="oxdrAvailable">
                              <div *ngFor="let component of oxdrComponents">
                                <h5>{{ component.title }}</h5>
                                <p>{{ component.description }}</p>
                                <br />
                              </div>
                            </div>
                            <div *ngIf="gsosAvailable">
                              <div *ngFor="let component of gsosComponents">
                                <h5>{{ component.title }}</h5>
                                <p>{{ component.description }}</p>
                                <br />
                              </div>
                            </div>
                            <div class="submit">
                              <input
                                type="button"
                                class="prev"
                                value="Previous"
                                (click)="prev()"
                                class="btn btn-primary float-start"
                              />
                              <!-- <input type="button" class="sub" value="Submit" (click)="next()"> -->
                              <button
                                type="submit"
                                class="btn btn-primary float-end"
                                (click)="open(content)"
                              >
                                Launch
                              </button>

                              <ng-template #content let-modal>
                                <div class="modal-header">
                                  <h4
                                    class="modal-title"
                                    id="modal-basic-title"
                                  >
                                    Tools
                                  </h4>
                                  <button
                                    type="button"
                                    class="close"
                                    aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"
                                  >
                                    <span
                                      style="padding: 5px"
                                      aria-hidden="true"
                                      >&times;</span
                                    >
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <!-- Modal content goes here -->
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Check</th>
                                        <th>Tools Name</th>
                                      </tr>
                                    </thead>
                                    <tbody *ngFor="let item of showTools">
                                      <tr >
                                        <td >
                                          <input
                                            type="checkbox"
                                            
                                            (change)="updateArray($event, item)"
                                          />
                                        </td>
                                        <td >
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            
                                            >{{ item.name }}</span
                                          >
                                        </td>
                                      </tr>
                                    </tbody>
                                    <!-- <tbody *ngFor="let tool of tools">
                                      <tr>
                                        <td>
                                          <input
                                            type="checkbox"
                                            [checked]="tool.checked"
                                            (change)="updateArray($event, tool)"
                                          />
                                        </td>
                                        <td>
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="{{ tool.description }}"
                                          >
                                            {{ tool.name }}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody> -->
                                  </table>

                                  <button
                                    type="button"
                                    (click)="pass()"
                                    class="action-button btn btn-primary"
                                    [disabled]="itemsArray.length === 0"
                                  >
                                    Launch
                                  </button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
