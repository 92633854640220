import { Component, VERSION, OnInit } from '@angular/core';
import * as apex from 'ng-apexcharts';

import { ApiService } from 'src/app/shared/api.service';
import {
  Router,
} from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/shared/toast.service';
import { LocalService } from 'src/app/shared/local.service';
import { UserService } from 'src/app/shared/user.service';
import { CognitoService } from 'src/app/cognito.service';
import { interval, Subscription } from 'rxjs';
import { WebsocketService } from 'src/app/websocket.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  selectAllChecked: any = false;
  tools: any;
  TableData: any;
  modifyTableData: any;
  payloadTools: any = [];
  buttonActive: any = false;
  activeButton: any;

  toolsArr = [
    {
      name: 'siem',
      description: `SIEM solutions allow organizations to efficiently collect and analyse log data from all of their digital assets in one place. 
  This gives them the ability to recreate past incidents or analyse new ones to investigate suspicious activity and implement more effective security processes.`,
    },
    {
      name: 'soar',
      description: `SOAR (Security Orchestration, Automation, and Response) refers to a collection of software solutions and tools that allow organizations to streamline security operations in three key areas: threat and vulnerability management, incident response, and security operations automation.`,
    },
    {
      name: 'EDR',
      description: `EDR security solutions analyse events from laptops, desktop PCs, mobile devices, servers, and even IoT and cloud workloads, to identify suspicious activity. They generate alerts to help security operations analysts uncover, investigate and remediate issues.`,
    },
    {
      name: 'threat-intel',
      description: `Threat intelligence, is information an organization uses to understand the threats that have, will, or are currently targeting the organization. This info is used to prepare, prevent, and identify cyber threats looking to take advantage of valuable resources. It is an essential aspect of maintaining the security of an organization and can help to prevent data breaches and other cyber-attacks.`,
    },
    {
      name: 'case-management',
      description: `Case management solutions are applications designed to support a complex process that requires a combination of human tasks and electronic workflow, such as an incoming application, a submitted claim, a complaint, or a claim that is moving to litigation.`,
    },
    {
      name: 'Threat Exchange',
      description: `threat exchange solutions can help organizations to comply with various regulatory requirements, such as those related to incident reporting and information sharing, by providing a mechanism for sharing information in a secure and compliant manner.`,
    },
    {
      name: 'Deception',
      description: `Deception is a category of cybersecurity solutions that detect threats early with low rates of false positives. It deploys realistic decoys (e.g., domains, databases, directories, servers, apps, files, credentials, breadcrumbs) in a network alongside real assets to act as lures.`,
    },
    {
      name: 'patch-management',
      description: `Patch management is the practice of deploying firmware, driver, operating system (OS), and application updates to your computing endpoints. The main purpose of patch management is to ensure the security and performance of devices`,
    },
    {
      name: 'vulnerability-management',
      description: `The purpose of a vulnerability management program is to keep your network safe from known exploitations and ensure it stays compliant with any regulatory requirements. It does this by analysing your network for any incompatibilities, missed updates and common weaknesses within the software you use.`,
    },
    {
      name: 'bas',
      description: `Breach and Attack Simulation (BAS) is a proactive cybersecurity technique that involves simulating various types of attacks and breaches to identify vulnerabilities and weaknesses in an organization's security infrastructure. BAS tools enable organizations to assess their security posture and evaluate the effectiveness of their security controls.`,
    },
    {
      name: 'Attack Surface Management',
      description: `Attack Surface Management helps organizations to identify and mitigate potential vulnerabilities before they can be exploited by attackers. By taking a proactive approach to security, organizations can reduce the risk of cyber-attacks and protect their sensitive data from being compromised.`,
    },
    {
      name: 'RedOps',
      description: `RedOps helps organizations to improve their incident response capabilities by providing an opportunity to test their response procedures in a controlled environment. This can help to ensure that organizations are well-prepared to respond effectively to real-world cyber-attacks.`,
    },
    {
      name: 'Vision Mission',
      description: `This module helps you in clearly defining the vision and mission of your organization. Easy to print and distribute per your needs. A web form-based tool that allows you to provide mission and vision statement as input. Fully customizable with logo and trademarks of your organization`,
    },
    {
      name: 'CISO Dashboard',
      description: `It provides Dynamic Visibility to the team of security solution with KPI Dashboards. It showcase the visual depiction of key indicators to help organization with presenting and communicating the security posture and needs of the organization with top management. It has Single-pane-of-glass visibility of people, processes and technologies. Provides real-time details on a single click that can be used as a presentation deck. Eliminates the need for PowerPoint slides every time discussing cyber security with top management.`,
    },
    {
      name: 'Table top',
      description: `This module to give your security team hands-on experience of real incidents and enables management to review security posture and take the right decision.`,
    },
    {
      name: 'gsos',
      description: `Compliance helps organizations to protect sensitive data, maintain the trust of customers and partners, and avoid legal and financial penalties for non-compliance. Compliance also promotes a culture of security awareness and best practices, ensuring that employees are well-informed and equipped to respond effectively to potential security threats.`,
    },
    {
      name: 'Awareness',
      description: `Cyber Security Awareness module provides you with tons of security awareness content including posters, newsletters, screen savers, educational videos, interactive quiz and many more.This module leverages web based digital asset management (DAM) to offer you a digital and customizable asset and content management platform. This will improve and strengthen overall organization’s security maturity amongst people and processes.`,
    },
    {
      name: 'Risk Assessment',
      description: `A Well-structure module which helps you understand the overall Enterprise Risk. Helps in management of the asset and network risks. It is designed Based on a well-known and widely used integrated GRC platform for the organization’s top management. 
      Helps top management to identify, analyse and plan response to the network risks`,
    },
    {
      name: 'Project Management',
      description: `This module helps you gain visibility of all cyber security solutions running in your organization. It further gives insights like patch status, software update status, risk score, compliance, vulnerability management, etc. for those solutions. This is the overall module that pulls together the vision and mission of an organization with appropriate security policies, vulnerability assessment guidelines, compliances and standards and helps corporate leaders understand the importance of them to work in accord with them.`,
    },
  ];

  cybersecurityQuotes = [
    "Securing the digital world, one byte at a time...",
    "Defending networks, protecting futures...",
    "Building digital shields against invisible threats...",
    "Every second counts in the world of cybersecurity",
  
    "Initializing advanced threat detection protocols...",
    "Calibrating security algorithms...",
    "Scanning the digital landscape...",
    "Establishing secure communication channels...",
  
    "In cyberspace, vigilance is your strongest weapon",
    "Security is not a product, but a process",
    "Anticipating threats before they emerge...",
    "Your digital safety is our mission",
  
    "Optimizing security protocols...",
    "Fine-tuning threat detection engines...",
    "Compiling advanced security modules...",
    "Integrating cutting-edge defense mechanisms...",
    "Constructing an impenetrable digital shield...",
    "Cybersecurity: Where innovation meets protection",
    "Hackers never sleep, and neither do our defenses",
    "Code is poetry, security is the rhythm",
    "Digital fortresses are built line by line of code",
    "In the chess game of cyber warfare, we're always three moves ahead",
    "Encryption: The language of digital confidentiality",
    "Firewall: More than a barrier, it's a guardian",
    "Threat intelligence is our sixth sense",
    "Cybersecurity isn't just an IT problem, it's everyone's responsibility",
    "We don't just detect threats, we predict them",
    "Digital resilience is our true strength",
    "Behind every secure system is a team of relentless defenders",
    "Transforming vulnerabilities into opportunities for protection",
    "Proactive defense: Our mantra in the digital realm",
    "Where data flows, security follows",
    "Breaking barriers, not systems",
    "Cybersecurity: The silent guardian of the digital ecosystem",
    "Decoding threats, encoding safety",
    "Our algorithms are sharper than their exploits",
    "Digital immunity: Stronger with every challenge",
    "Risk management is an art, and we are the artists",
    "Protecting pixels, preserving privacy",
    "Cybersecurity: Where logic meets intuition",
    "We turn potential breaches into learning opportunities",
    "Digital trust is our most valuable currency",
    "Navigating the complex terrain of cyber threats",
    "Security isn't about perfection, it's about constant improvement",
    "Every patch tells a story of prevented disaster",
    "Transforming complexity into clarity",
    "Our defense is adaptive, our response is instantaneous",
    "Beyond firewalls: Building comprehensive digital resilience",
    "In the world of zeros and ones, we speak the language of safety",
    "Threat hunting: Our relentless pursuit of digital peace",
    "Security isn't a destination, it's a continuous journey",
    "Breaking the code of cyber vulnerabilities",
    "Where technology meets trust",
    "Turning potential weaknesses into our greatest strengths",
    "Cybersecurity: The bridge between innovation and protection",
    "We don't just respond to threats, we anticipate them",
    "Digital guardianship is our commitment",
    "Layers of security, depths of protection",
    "Crafting digital armor, one algorithm at a time",
    "In the spectrum of cybersecurity, we paint with precision",
    "Our shield is intelligent, our strategy is dynamic"
  ];

  currentQuote: string = '';
  private quoteInterval: any;
  private currentIndex: number = 0;

  message: any;
  private messageSubscription?: Subscription;
  icons: string[] = [
    'lock',
    'verified_user',
    'admin_panel_settings',
    'vpn_lock',
    'pattern',
    'enhanced_encryption',
    'add_moderator',
    'password',
    'lock_clock',
    'security_update_good',
    'mail_lock',
    'private_connectivity',
    'policy',
  ];
  currentIcon: string = this.icons[0];
  private iconInterval: any;
  colors: string[] = ['#EA4335', '#34A853', '#4285F4', '#FBBC05', '#000000'];
  currentColor: string = this.colors[0];


  submit() {
    let selectedCheckbox = this.selectedCheckboxes();
    console.log(selectedCheckbox);
    selectedCheckbox.forEach((item: any) => {
      console.log(item.name);
      const toolsObj = {
        name: item.name,
        user_tool_id: item.user_tool_id,
        tool_id: item.tool_id
      }
      this.payloadTools.push(toolsObj);
    });
    console.log(this.payloadTools);


    const tools = this.payloadTools;
    const requestBody = {
      tools: tools,
    };

  }

  reloadTools() {
    const email = this.user.getEmail();
    const requestBody = {
      email: email,
    };
  }

  selectedCheckboxes() {
    return this.modifyTableData.filter((value: any) => value.selected);
  }

  toggleSelectAll() {
    if (this.selectAllChecked) {
      this.modifyTableData.forEach((value: any) => {
        if (value.status == 200 || value.status == 400) {
          value.selected = false;
        } else {
          value.selected = true;
          this.activeButton = this.selectedCheckboxes();
        }
      });
    } else {
      this.modifyTableData.forEach((value: any) => {
        value.selected = false;
        this.activeButton = this.selectedCheckboxes();
      });
    }
  }

  checkboxChecked() {
    this.activeButton = this.selectedCheckboxes();
    if (this.isAllcheckedSelected()) {
      this.selectAllChecked = true;
    } else {
      this.selectAllChecked = false;
    }
  }

  isAllcheckedSelected() {
    return this.modifyTableData.every((value: any) => value.selected);
  }

  selectAll() {
    throw new Error('Method not implemented.');
  }

  series: apex.ApexNonAxisChartSeries = [];
  chart: apex.ApexChart = { type: 'radialBar' };
  title: any;
  stroke: any = { linecap: 'round' };
  labels: any = ['Profile'];
  remainingFields: any;
  organization : any;
  profileData: any = {};
  is_deploy: boolean | undefined;

  constructor(
    private router: Router,
    private auth: AuthService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private local: LocalService,
    private user: UserService,
    private cognitoService: CognitoService,
    private websocketService: WebsocketService
  ) {}
  percentage: number = 0;

  private displayNextQuote() {
    this.currentQuote = this.cybersecurityQuotes[this.currentIndex];

    this.currentIndex = (this.currentIndex + 1) % this.cybersecurityQuotes.length;
  }

  private getRandomColor(): string {
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    return this.colors[randomIndex];
  }

  ngOnInit() {
    let index = 0;

    this.iconInterval = setInterval(() => {
      index = (index + 1) % this.icons.length;
      this.currentIcon = this.icons[index];
      this.currentColor = this.getRandomColor();
    }, 5000); 

    this.displayNextQuote();
    this.quoteInterval = setInterval(() => {
      this.displayNextQuote();
    }, 5000);

    this.message = "No status"
    this.percentage = 0
    const email = this.local.getData("email");
    const token = this.user.getToken();
    const requestBody = {
      email: email,
      authtoken: token,
    };

    this.spinner.show();
    this.apiService.getUser({email:email}).subscribe(
      (res: any)=>{
        // console.log("RESPONSE",res)
        this.is_deploy = res?.body?.data.user?.is_deploy
        const userObj = {
          userId:res?.body?.data.user.user_id,
          email:res?.body?.data.user.email,
          name:res?.body?.data.user.name,
          organization:res?.body?.data.user.organization,
          mobile:res?.body?.data.user.mobile_number,
          deploy:res?.body?.data.user?.is_launch,
        };
        this.organization = res?.body?.data?.user?.organization

        this.local.saveData("user",JSON.stringify(userObj));

        this.spinner.hide();
          const modifiedResponse = res?.body?.data?.tool.map((obj: any) => ({
            ...obj,
            selected: false,
          }));

          if(res?.body?.data.user.is_deploy === false){
            if(res?.body?.data.user.user_id){
              this.websocketService.connect(res?.body?.data.user.user_id);
              this.messageSubscription = this.websocketService.message$.subscribe({
                next: (data) => {
                  this.message = data;
                  this.percentage = parseInt(this.message?.status?.body?.current_state?.Percentage)
                },
                error: (error) => {
                  console.error("WebSocket message error:", error);
                }
              });
              this.percentage = 0
            }
          }

          this.reloadTools();
          this.modifyTableData = modifiedResponse;
      }
    );
  }

  ngOnDestroy() {
    if (this.quoteInterval) {
      clearInterval(this.quoteInterval);
    }
    if (this.iconInterval) {
      clearInterval(this.iconInterval); 
    }
  }

  goToProfile(){
    this.router.navigate(['/user/profile'])
  }

  pokeSocket(){
    this.websocketService.sendMessage("Socket is up!")
  }

  logout(){
    this.cognitoService.signOut().then((res)=>{
      
    })
  }
}
