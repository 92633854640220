import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from '../shared/auth.guard';
import { UserLayoutComponent } from './user-layout/user-layout.component';
import { VerificationComponent } from './verification/verification.component';
import { VerifyImportComponent } from './verify-import/verify-import.component';
import { UserauthLayoutComponent } from './user-auth-layout/user-auth-layout.component';
import { DownloadComponent } from './download/download.component';
import { FaqComponent } from './faq/faq.component';
import { LandingComponent } from './landing/landing.component';
import { DocumentComponent } from './document/document.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ForgotKeyComponent } from './forgot-key/forgot-key.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { FirstLoginPasswordResetComponent } from './first-login-password-reset/first-login-password-reset.component';

const routes: Routes = [
  {
    path: '',
    component: UserauthLayoutComponent,
    children: [
      {path:"",component:LandingComponent},
      {path:"signup",component:SignupComponent},
      {path:"login",component:LoginComponent},
      {path:"verification",component:VerificationComponent},
      {path:"forgot-password",component:ForgotPasswordComponent},
      {path:"first-login",component:FirstLoginPasswordResetComponent},
      {path:"reset-password",component:ResetPasswordComponent},
      {path:"verify-signup",component:VerifyImportComponent},
      {path:"otp-verification",component:OtpVerificationComponent},
      {path:"account-info",component:AccountInfoComponent},
      {path:"thank-you",component:ThankYouComponent},
      {path:"forgot-key",component:ForgotKeyComponent}
    ]
  },
  {
    path: 'user',
    component: UserLayoutComponent,
    children: [
      {path:"profile",component:ProfileComponent},
      {path:"dashboard",component:DashboardComponent,canActivate:[AuthGuard]},
      {path:"update-password",component:UpdatePasswordComponent},
      {path:"download",component:DownloadComponent, canActivate:[AuthGuard]},
      {path:"faq",component:FaqComponent, canActivate:[AuthGuard]},
      {path:"document",component:DocumentComponent, canActivate:[AuthGuard]},
    ],
    // canActivate:[AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
