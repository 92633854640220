<section
  class="login_page_main"
  style="
    background-image: url(../../../assets/images/landing/hero-1-bg-img.png);
  "
>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 p-5 d-none d-sm-block">
        <div class="left_img">
          <h1>
            Get Yourself ready against <br />
            Cyber Problems
          </h1>
          <img src="assets/login.png" />
        </div>
      </div>
      <div class="col-lg-6 p-3">
        <div class="right_form_main">
          <div class="form_top_text">
            <div>
              <h2>Login to your Invinsense account</h2>
            </div>
          </div>
          <div class="right_form_inside">
            <h3 class="form_tital">Log In</h3>
            <form
              [formGroup]="loginForm"
              id="msform"
              autocomplete="off"
              class="needs-validation"
              novalidate
            >
            <!-- <div class="form-group formfield">
              <input
                type="number"
                formControlName="phone"
                name="phone"
                id="phone"
                required
                value=""
                autocomplete="off"
                class="form-control"
              />
              <label for="phone" placeholder="">Phone no</label>
              <div
                *ngIf="
                  loginForm.get('phone')?.invalid &&
                  (loginForm.get('phone')?.dirty ||
                    loginForm.get('phone')?.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="loginForm.get('email')?.errors?.['required']">
                  Phone is required.
                </div>
              </div>
            </div> -->
              <div class="form-group formfield">
                <input
                  type="text"
                  formControlName="email"
                  name="email"
                  id="email"
                  required
                  value=""
                  placeholder=" "
                  autocomplete="off"
                  pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
                  class="form-control"
                />
                <label for="email" placeholder="">Email</label>
                <div
                  *ngIf="
                    loginForm.get('email')?.invalid &&
                    (loginForm.get('email')?.dirty ||
                      loginForm.get('email')?.touched)
                  "
                  class="invalid-feedback"
                >
                  <div *ngIf="loginForm.get('email')?.errors?.['required']">
                    Email is required.
                  </div>
                </div>
              </div>
              <div class="form-group formfield">
                <input
                  type="password"
                  formControlName="password"
                  name="password"
                  id="password"
                  required
                  value=""
                  placeholder=" "
                  autocomplete="off"
                  class="form-control"
                />
                <label for="password" placeholder="">Password</label>
                <div
                  *ngIf="
                    loginForm.get('password')?.invalid &&
                    (loginForm.get('password')?.dirty ||
                      loginForm.get('password')?.touched)
                  "
                  class="invalid-feedback"
                >
                  <div *ngIf="loginForm.get('password')?.errors?.['required']">
                    Password is required.
                  </div>
                </div>
              </div>
              <div *ngIf="environment.enableRecaptcha">
                <div class="form-group">
                  <ngx-recaptcha2
                    #captchaElem
                    [siteKey]="environment.recaptcha.siteKey"
                    (reset)="handleReset()"
                    (expire)="handleExpire()"
                    (load)="handleLoad()"
                    (success)="handleSuccess($event)"
                    formControlName="recaptcha"
                  >
                  </ngx-recaptcha2>
                </div>
              </div>
              <hr />
              <!-- <div class="float-start">
                                <a routerLink="/forgot-password">Forgot Password</a>
                            </div>
                            <br>
                            <div class="float-start">
                                <a routerLink="/signup">Don't have an account? Click here to create...</a>
                            </div>
                            <br>
                            <div class="float-start">
                                <a class= "finish" routerLink="/verification">Finish verification</a>
                            </div> -->
              <div class="btnandforgot">
                <a routerLink="/forgot-password">Forgot password?</a>
                <button
                  (click)="login()"
                  [disabled]="loginForm.invalid"
                  type="submit"
                  data-test-id="login_in"
                  class="btn btn-primary"
                >
                  Log in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
