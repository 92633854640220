import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserRoutingModule } from './user-routing.module';
import { UserLayoutComponent } from './user-layout/user-layout.component';
import { UserauthLayoutComponent } from './user-auth-layout/user-auth-layout.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { VerificationComponent } from './verification/verification.component';
import { WebcamModule } from 'ngx-webcam';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NavbarComponent } from './navbar/navbar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DownloadComponent } from './download/download.component';
import { FaqComponent } from './faq/faq.component';
import { LandingComponent } from './landing/landing.component';
import { DocumentComponent } from './document/document.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { AuthFooterComponent } from './auth-footer/auth-footer.component';
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ForgotKeyComponent } from './forgot-key/forgot-key.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { FirstLoginPasswordResetComponent } from './first-login-password-reset/first-login-password-reset.component';

@NgModule({
  declarations: [
    UserauthLayoutComponent,
    UserLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    LoginComponent,
    ProfileComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerificationComponent,
    SidebarComponent,
    NavbarComponent,
    DownloadComponent,
    FaqComponent,
    LandingComponent,
    DocumentComponent,
    AuthFooterComponent,
    PasswordStrengthMeterComponent,
    OtpVerificationComponent,
    AccountInfoComponent,
    ThankYouComponent,
    ForgotKeyComponent,
    UpdatePasswordComponent,
    FirstLoginPasswordResetComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    WebcamModule,
    PerfectScrollbarModule,
    NgApexchartsModule,
    NgxCaptchaModule,
    RecaptchaV3Module,
    MatProgressBarModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showSubtitle:false
    })
  ],
  providers: [{
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  },
],
})
export class UserModule { }
