import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from '../../shared/toast.service';
import { CognitoService } from 'src/app/cognito.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit{
    verificationForm!: FormGroup;

    constructor(private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private local: LocalService, private toast: ToastService, private cognitoService: CognitoService) { }

    ngOnInit() {
      this.local.clearData();
  
      $.getScript('/assets/js/form-validations.js');
      
      this.verificationForm = this.formBuilder.group({
        // email: ['', Validators.email],
        code: ['', Validators.required],
      });
    }
    

    // resendConfirmation(){
    //   const requestBody = {
    //     email: this.verificationForm.value.email,
    //   }
    //   this.spinner.show();
    //   this.apiService.resendConfirmation(requestBody).subscribe((response: any) => {
    //     this.spinner.hide();
    //     const { data, statusCode, message } = response;
    //     if (statusCode == 200) {
    //         this.toast.showSuccess("OTP is resend");
    //       }
    //     else {
    //       if(statusCode == 400){
    //         this.toast.showError("Request not send.","Error");
    //       } else {
    //         this.toast.showError(message,"Error");
    //       }
    //     }
    //   });
    // }

    async verifyOTP() {
      const requestBody = {
        confirmationCode: this.verificationForm.value.code
      }
      try {
        const result = await this.cognitoService.confirmSignInWithSMSCode(requestBody);
        // The function handles navigation and success messages
      } catch (error) {
        // Error is already handled in the function
        console.error('OTP verification failed:', error);
      }
    }
    
    verification(){
      const requestBody = {
        // email: this.verificationForm.value.email,
        confirmationCode: this.verificationForm.value.code
      }
      this.spinner.show();

      this.cognitoService.verifyOTP(requestBody).then((res)=>{
        console.log(res);
        this.spinner.hide()
        // this.router.navigate(['/account-info']);
      })
      this.spinner.hide()

      // this.apiService.confirmSignUp(requestBody).subscribe((response: any) => {
      //   this.spinner.hide();
      //   const { data, statusCode, message } = response;
      //   if (data && statusCode == 200) {
      //       this.router.navigate(['/login']).then(()=>{ this.toast.showSuccess("Your Account is Confirmed");})
      //     }
      //   else {
      //     if(statusCode == 400){
      //       this.toast.showError("Request not send.","Error");
      //     } else {
      //       this.toast.showError(message,"Error");
      //     }
      //   }
      // }
      
      ,
      (error: any) => {
        // Handle connection failure or API changes
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
      }
    } catch (err: any) {
      this.toast.showError(err,"Error");
    }
  }