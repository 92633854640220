<div class="strength">
    <ul class="strengthBar mt-2 mb-0">
      <li class="point" [style.background-color]="bar0"></li>
      <li class="point" [style.background-color]="bar1"></li>
      <li class="point" [style.background-color]="bar2"></li>
      <li class="point" [style.background-color]="bar3"></li>
    </ul>
    <ul class="mb-0">
      <li><p class="text-center mb-0" [style.color]="messageColor" *ngIf="passwordToCheck?.length">{{ message }}</p></li>
    </ul>
  </div>