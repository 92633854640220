import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute , Router, ParamMap} from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from 'src/app/shared/toast.service';
import { CognitoService } from 'src/app/cognito.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm!: any;

  constructor(private formBuilder: FormBuilder, private toast: ToastService, private apiService: ApiService, private spinner: NgxSpinnerService, private route : ActivatedRoute, private router : Router, private local: LocalService, private cognitoService: CognitoService) { }

  ngOnInit(){

    $.getScript('/assets/js/form-validations.js');

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['',[Validators.required,Validators.email]],
    });
  }

  recoverPassword() {
    const emailAddress = this.forgotPasswordForm.value.email
    this.local.saveData('email', emailAddress);
    const requestBody = {
      username: emailAddress
    }
    this.spinner.show();

    this.cognitoService.forgotPassword(requestBody).then((res)=>{
      console.log(res);
      this.spinner.hide();
    }).catch((err)=>{
      console.log(err);
      this.spinner.hide();
    })
    // this.apiService.forgotPassword(requestBody).subscribe((response: any) => {
    //   this.spinner.hide();
    //   const{statusCode, message} = response;
    //   if(response && response.statusCode === 200){
    //     this.router.navigate(['/reset-password']);
    //   }
    //   else {
    //   this.apiService.displayGenericError(statusCode,response);  
          
    // }
    // },
    // (error: any) => {
    //   this.toast.showError("Failed to connect to server", "Error");
    //   this.spinner.hide();
    // });
  }
}