import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { ToastService } from '../../shared/toast.service';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalService } from '../../shared/local.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss','../../../assets/sass/scss/landing/_variables.scss','../../../assets/sass/scss/landing/_menu.scss']
})
export class HeaderComponent implements OnInit{

  isNotLoggedIn = false;

  constructor(private router: Router, private auth: AuthService, private apiService: ApiService, private spinner: NgxSpinnerService, private toast: ToastService, private local: LocalService, private user: UserService) { }

  ngOnInit() {
    this.isNotLoggedIn = this.auth.isNotLoggedIn();
  }
  

  logout() {
    // const token = JSON.parse(this.local.getData('authtoken'));
    const token = this.user.getToken();
    const reqBody = {
      // email: this.local.getData('email'),
      email: this.user.getEmail(),
      authtoken: this.local.getData("authtoken")
    }

    try {
      this.spinner.show();
      this.apiService.globalLogout(reqBody).subscribe((response: any) => {
        this.auth.logout();
        this.spinner.hide();
        this.router.navigate(['/login']);
      });
    } catch (err: any) {
      this.spinner.hide();
      this.toast.showError(err, "Error");
    }
  }
}