import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../../shared/toast.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ApiService } from '../../shared/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-key',
  templateUrl: './forgot-key.component.html',
  styleUrls: ['./forgot-key.component.scss'],
})
export class ForgotKeyComponent {
  public environment = environment;
  loginForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
this.loginForm = this.formBuilder.group({
  email: ['', Validators.email],
  recaptcha: ['', environment.enableRecaptcha ? Validators.required : null ],
});
  }

  handleReset() {}
  handleExpire() {}
  handleLoad() {}
  handleSuccess(event: any) {}

  forgot() {
    const requestBody = {
      email: this.loginForm.value.email,
    };
    console.log(requestBody);
    try {
      this.apiService.forgotkey(requestBody).subscribe((response: any) => {
        this.spinner.hide();
        const { statusCode, message } = response;
        if (message && statusCode == 200) {
          this.toast.showInfo(message, 'Success');
        }
        else{
          this.toast.showError(message, 'Error');
        }
      });
    } catch (err: any) {
      this.toast.showError(err, 'Error');
    }
  }
}
