import { Component } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from '../../shared/toast.service';
import { UserService } from 'src/app/shared/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {
  base64Output_resume: string="";

  constructor(private http: HttpClient, private apiService: ApiService, private spinner: NgxSpinnerService, private route : ActivatedRoute, private router : Router, private local : LocalService, private toast: ToastService, private user: UserService) { }
  
  download_aadhaar(): void {
    const requestBody = {
      document_name: 'aadhaar', 
    };
    this.spinner.show();
    this.apiService.documentDownload(requestBody).subscribe(
      (res: any) => {
        this.spinner.hide();
        const base64Data = res;
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
  
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        saveAs(blob, "aadhaar_card.pdf");
        this.toast.showSuccess("aadhaar_card.pdf Downloaded successfully");
      },
      (error) => {
        console.error('Error downloading Aadhaar card:', error.message);
      }
    );
  }

  download_pan(): void {
    const requestBody = {
      document_name: 'pan', 
    };
    this.spinner.show();
    this.apiService.documentDownload(requestBody).subscribe((res: any) => {
      this.spinner.hide();
        const base64Data = res;
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
  
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
  
        saveAs(blob, "pan_card.pdf");
        this.toast.showSuccess("pan_card.pdf Downloaded successfully");
      },
      (error) => {
        console.error('Error downloading Aadhaar card:', error.message);
      }
    );
  }

  download_driving(): void {
    const requestBody = {
      document_name: 'driving', 
    };
    this.spinner.show();
    this.apiService.documentDownload(requestBody).subscribe(
      (res: any) => {
        this.spinner.hide();
        const base64Data = res;
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
  
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
  
        saveAs(blob, "driving_licence.pdf");
        this.toast.showSuccess("driving_licence.pdf Downloaded successfully");
      },
      (error) => {
        console.error('Error downloading driving licence:', error.message);
      }
    );
  }

  download_resume(): void {
    const requestBody = { 
    };
    this.spinner.show();
    this.apiService.resumePdf(requestBody).subscribe((res: any) => {
      this.spinner.hide();
      const base64Data = res;
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      saveAs(blob, "resume.pdf");
      this.toast.showSuccess("resume.pdf Downloaded successfully");
    },
    (error) => {
      console.error('Error downloading Resume:', error.message);
    });
  }


  // download_resume(){
  //   const requestBody = {
  //     user_data: this.base64Output_resume
  //   }
  //   this.spinner.show();
  //     this.apiService.uploadImage(requestBody).subscribe((response: any) => {
  //       this.spinner.hide();
  //       const{statusCode, message} = response
  //       if(statusCode == 200){
  //         this.toast.showSuccess("PDF is downloaded");
  //       }
  //       else{
  //          if(statusCode == 400){
  //             this.toast.showError("Request not send","Error");
  //           } else {
  //             this.apiService.displayGenericError(statusCode,response);  
                  
  //           }
  //     }
  //       },
  //       (error: any) => {
  //         // Handle connection failure or API changes
  //         this.toast.showError("Failed to connect to server", "Error");
  //         this.spinner.hide();
  //       });
  //     }
}
