<div class="card radius-15">
	<div class="card-body">
		<div class="card-title">
			<h4 class="mb-0">Upload Documents</h4>
            <h6>document size(50kb - 5mb)</h6>
		</div>
		<hr/>
        <h5>Aadhaar Card</h5>
        <!-- <button style="display:block;width:120px; height:30px;" onclick="document.getElementById('fancy-file-upload').click()">Your text here</button> -->
		<input id="fancy-file-upload" type="file" (change)="onaadhaar($event)" name="aadhaar" accept=".pdf"  >
        <br>
        <br>
        <h5>PAN Card</h5>
		<input id="fancy-file-upload" type="file" (change)="onpan($event)" name="pan" accept=".pdf" >
        <br>
        <br>
        <h5>Driving Licence</h5>
		<input id="fancy-file-upload" type="file" (change)="ondriving($event)" name="driving" accept=".pdf" >
        <br>
        <br>
        <button type="button" class="btn btn-primary" [disabled]="!isAnyDocumentSelected()" (click)="importDocument()">Upload</button>
	</div>
</div>
