<section class="login_page_main" style="background-image: url(../../../assets/images/landing/hero-1-bg-img.png)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 p-5 d-none d-sm-block">
                <div class="left_img">
                    <h1>May be you can have your coffee, while these solutions are spined in a
                        few mins</h1>
                    <img src="../assets/images/cloud-collaboration.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>