
<section class="login_page_main" style="background-image: url(../../../assets/images/landing/hero-1-bg-img.png)">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-5 d-none d-sm-block">
                <div class="left_img">
                    <h1>Explore Interesting <br> Cyber Projects</h1>
                    <img src="assets/login.png">
                </div>
            </div>
            <div class="col-lg-6 py-5 px-3">
                <div class="right_form_main">
                    <div class="form_top_text">
                        <div>
                            <h2>Enter email address to recover your <br>Invinsource account</h2>
                        </div>
                    </div>
                    <div class="right_form_inside">
                        <h3 class="form_tital">Forgot Password?</h3>
                        <form [formGroup]="forgotPasswordForm" id="msform" autocomplete="off
                        " class="needs-validation">
                            <div class="form-group formfield">
                                <input type="email" formControlName="email" name="email" id="email" required value=""
                                    autocomplete="off" class="form-control" placeholder=" ">
                                <label for="email" placeholder="">Email</label>
                                <!-- <div *ngIf="forgotPasswordForm.get('email')?.invalid && (forgotPasswordForm.get('email')?.dirty || forgotPasswordForm.get('email')?.touched)" class="invalid-feedback">
                                    <div *ngIf="forgotPasswordForm.get('email')?.errors?.['required']">
                                        Email is required.
                                    </div>
                                </div> -->
                                <div
                                    *ngIf="
                                    forgotPasswordForm.controls.email.touched &&
                                    forgotPasswordForm.controls.email.invalid
                                    "
                                >
                                    <div
                                    *ngIf="forgotPasswordForm.controls.email.errors.required"
                                    style="color: red; font-size: 0.875em"
                                    >
                                    Email is required
                                    </div>
                                    <div
                                    *ngIf="forgotPasswordForm.controls.email.errors.email"
                                    style="color: red; font-size: 0.875em"
                                    >
                                    Email must be a valid email address
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <button (click)="recoverPassword()" [disabled]="forgotPasswordForm.invalid"  type="submit" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>