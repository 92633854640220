import { Component , OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { ModalControlService } from '../user-layout/modal-control.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastService } from 'src/app/shared/toast.service';
import { ApiService } from 'src/app/shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalService } from 'src/app/shared/local.service';
import { UserService } from 'src/app/shared/user.service';
import { CognitoService } from 'src/app/cognito.service';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit{
    isNotLoggedIn = false;
    profileData: any = {};
    name = '';
    firstCh = " ";
    imageUrl = "";
    userDetails: any;
    constructor(private modalControlService: ModalControlService, public sidebarservice: SidebarService, private router: Router, private auth: AuthService, private apiService: ApiService, private spinner: NgxSpinnerService, private toast: ToastService, private local: LocalService, private user: UserService, private cognitoService: CognitoService) { }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    }
    
    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }

    openModal() {
      this.modalControlService.show();
    }  

    logout(){
      this.cognitoService.signOut().then((res)=>{
        
      })
      localStorage.clear();
    }

    // logout() {
    //     const token = this.user.getToken();
    //     const reqBody = {
    //       email: this.user.getEmail(),
    //       authtoken: this.local.getData("authtoken")
    //     }
    
    //     try {
    //       this.spinner.show();
    //       this.apiService.globalLogout(reqBody).subscribe((response: any) => {
    //         this.auth.logout();
    //         this.spinner.hide();
    //         this.router.navigate(['/login']); // the route will redirect to admin portal 
    //       });
    //     } catch (err: any) {
    //       this.spinner.hide();
    //       this.toast.showError(err, "Error");
    //     }
    //   }

      profile() {
        const requestBody = {
          email: this.local.getData("email"),
          authtoken: this.local.getData("authtoken")
        }
        // this.spinner.show();
        // this.apiService.getUserProfile(requestBody).subscribe((response: any) => {
        //   this.spinner.hide();
        //   const {data, statusCode} = response;
        //   if(data && statusCode == 200){
        //     this.name = data.name;
        //     this.firstCh = this.name.charAt(0);
        //     this.profileData = data;
        //     this.imageUrl = this.user.image(this.profileData);
        //   } else if(statusCode == 400){
        //     this.toast.showError('Session expired, please login again', 'Error');
        //     this.router.navigate(['/login']);
        //   }
        // });
      }

    ngOnInit() {

      setTimeout(() => {
        this.loadUserDetails()
      }, 2000);

      // this.profile();
        // logout api calling here to bulona admin portal. not global logout.
        this.isNotLoggedIn = this.auth.isNotLoggedIn();

        /* Search Bar */
        $(document).ready(function () {
            $(".mobile-search-icon").on("click", function () {
                $(".search-bar").addClass("full-search-bar")
            }), 
            $(".search-close").on("click", function () {
                $(".search-bar").removeClass("full-search-bar")
            })
        });
        


    }

    loadUserDetails() {
      const user = this.local.getData('user');
      this.userDetails = user ? JSON.parse(user) : null;
    }
}
