<footer class="">
	<main class="container">
		<div class="row text-center">

			<div class="col-lg-12 align-self-center p-3">
				<h6>© Copyright Invinsense. {{year}}. All rights reserved.</h6>
			</div>
		
		</div>


	</main>
</footer>
