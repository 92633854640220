export const environment = {
    production: false,
    apiUrl: "https://391148ine0.execute-api.ap-south-1.amazonaws.com/dev",
    recaptcha: {
        siteKey: '6Ld3InAnAAAAAK6OiM74y3oIFp3J7pT0oFk-W2U6',
        },
    enableRecaptcha: false,
    userPoolId: "ap-south-1_gOt0N13fT",
    userPoolClientId: '5j03db36460sjlbrhu76rj454g'
};
