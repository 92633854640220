import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormsModule } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from '../../shared/toast.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { CognitoService } from 'src/app/cognito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit{
  currentTime: Date | null = null;
  public environment = environment;
  loginForm!: FormGroup;
  public captcha: any;
  public alphabets: any = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz"
  public status: any = document.getElementById('status');
  // loginObj: Login = new Login();
  // user: User = {} as User;
  // email: string = '';
  // password: string = '';
  // errorMessage: string = '';


  constructor(private reCaptchaV3Service: ReCaptchaV3Service, private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private local: LocalService, private toast: ToastService, private cognitoservice: CognitoService) { }

  ngOnInit() {
    this.local.clearData();

    $.getScript('/assets/js/form-validations.js');

    this.generate();

    this.loginForm = this.formBuilder.group({
      // phone: ['', Validators.required],
      email: ['', Validators.email],
      password: ['', Validators.required],
      recaptcha: ['', environment.enableRecaptcha ? Validators.required : null ],
    });
  }

  handleReset() {}
  handleExpire() {}
  handleLoad() {}
  handleSuccess(event: any) {}


  generate(){
    let first = this.alphabets[Math.floor(Math.random() * this.alphabets.length)];
    let second = Math.floor(Math.random() * 10);
    let third = Math.floor(Math.random() * 10);
    let fourth = this.alphabets[Math.floor(Math.random() * this.alphabets.length)];
    let fifth = this.alphabets[Math.floor(Math.random() * this.alphabets.length)];
    let sixth = Math.floor(Math.random() * 10);
    this.captcha = first.toString()+second.toString()+third.toString()+fourth.toString()+fifth.toString()+sixth.toString();
    // console.log(this.captcha);
    // document.getElementById('generated-captcha').value = captcha;
    // document.getElementById("entered-captcha").value = '';
    // status.innerText = "Captcha Generator"
  }

  login() {
    const requestBody = {
      // phone:this.loginForm.value.phone,
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password
    }
    this.spinner.show();
    try {
      this.cognitoservice.signIn(requestBody).then((res)=>{
        // this.router.navigate(['/reset-password']);
        this.spinner.hide()
      }).catch((err)=>{
        console.log(err)
        this.spinner.hide()
      })

      // this.apiService.login(requestBody).subscribe((response: any) => {
      //   this.spinner.hide();
      //   const { statusCode, message } = response;
      //   if (message && statusCode == 200) {
      //     const currentTime = new Date().getTime();
      //     // if (ChallengeName == "NEW_PASSWORD_REQUIRED" && Session) {
      //     //   this.local.saveData('session', Session);
      //     //   this.local.saveData('email', this.loginForm.value.email);
      //     //   this.router.navigate(['/change-password']);
      //     // } else
      //     {
      //       // this.local.saveData('authtoken', JSON.stringify(data || {}));
      //       this.local.saveData('email', this.loginForm.value.email.toLowerCase());
      //       this.local.saveData('key', this.loginForm.value.password);
      //       this.local.saveData('login_time', currentTime.toString());
      //       this.local.saveData('userrole', "user");
      //       this.toast.showInfo("Please check OTP and Verify.");
      //       setTimeout(()=>{
      //         this.router.navigate(['/otp-verification']);
      //       },1000);
      //     }
      //   }
      //   else {
      //     this.apiService.displayGenericError(statusCode,response);
      //     this.toast.showError(statusCode,message );
      //   }
      // },
      // (error: any) => {
      //   this.spinner.hide();
      //   this.toast.showError("Failed to connect to server", "Error");
      // }
      // );
    } catch (err: any) {
      this.toast.showError(err,"Error");
      this.spinner.hide()
    }
  }
}
