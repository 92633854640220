<section class="login_page_main" style="background-image: url(../../../assets/images/landing/hero-1-bg-img.png)">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-5 d-none d-sm-block">
                <div class="left_img">
                    <h1>Explore Interesting <br> Cyber Projects</h1>
                    <img src="assets/login.png">
                </div>
            </div>
            <div class="col-lg-6 p-3">
                <div class="right_form_main">
                    <div class="form_top_text">
                        <div>
                            <h2>Verify your <br>Invinsource account</h2>
                        </div>
                    </div>
                    <div class="right_form_inside">
                        <h3 class="form_tital">Verify Signup</h3>
                        <form [formGroup]="verifyImportsForm" id="msform" autocomplete="off
                        ">
                            <div class="form-group formfield">
                                <input type="text" formControlName="email" name="" id="email" required value=""
                                    autocomplete="off">
                                <label for="email" placeholder="">Email</label>
                            </div>

                            <div class="form-group formfield">
                                <input type="password" formControlName="password" name="" id="password" required
                                    value="" autocomplete="off">
                                <label for="password" placeholder="">Password</label>
                            </div>

                            <div class="form-group formfield">
                                <input type="password" formControlName="confirm_password" name="" id="confirm_password" required
                                    value="" autocomplete="off">
                                <label for="confirm_password" placeholder="">confirm Password</label>
                            </div>
                            <hr>
                            <div>
                                <button (click)="verifyImports()" [disabled]="verifyImportsForm.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>