import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute , Router, ParamMap} from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from 'src/app/shared/toast.service';
import { ConfirmedValidator } from 'src/app/confirm-validator';
import { UserService } from 'src/app/shared/user.service';
import { CognitoService } from 'src/app/cognito.service';

@Component({
  selector: 'app-first-login-password-reset',
  templateUrl: './first-login-password-reset.component.html',
  styleUrls: ['./first-login-password-reset.component.scss']
})
export class FirstLoginPasswordResetComponent {
  resetPasswordForm!: FormGroup;

  constructor(private formBuilder: FormBuilder,private toast: ToastService, private apiService: ApiService, private spinner: NgxSpinnerService, private route : ActivatedRoute, private router : Router, private local: LocalService, private user: UserService, private cognitoService: CognitoService) { }

  ngOnInit(){

    $.getScript('/assets/js/form-validations.js');
    this.resetPasswordForm = this.formBuilder.group({
      // email: ['',Validators.required],
      newPassword: ['',Validators.required],
      confirmPassword: ['',Validators.required],
    });
  }

  resetPassword() {

    if(this.resetPasswordForm.value.newPassword !== this.resetPasswordForm.value.confirmPassword){
      this.toast.showError("Passwords do not match!");
      return;
    };
  
    const email = this.route.snapshot.paramMap.get('email');
    const requestBody = {
      password:this.resetPasswordForm.value.confirmPassword,
    };

    this.spinner.show();

    this.cognitoService.confirmSignIn(requestBody).then((res)=>{
      this.spinner.hide();
      this.router.navigate(['/login']);
      this.toast.showSuccess("Password has been updated");
    }).catch((err)=>{
      console.log(err);
      // this.toast.showError("Please check the password");
      this.spinner.hide();
    })
  }

}
