import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent {
  verificationForm!: FormGroup;
  email: any;
  key: any;
  disableResend = true;
  resendTimeout: any;
  remainingTime = 180; // 3 minutes in seconds

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private local: LocalService, private toast: ToastService) { }

  ngOnInit() {
    this.email = this.local.getData('email');
    this.key = this.local.getData('key');
    this.local.removeData('key');

    $.getScript('/assets/js/form-validations.js');

    this.verificationForm = this.formBuilder.group({
      email: [this.email, Validators.email],
      code: ['', Validators.required],
    });

    this.startResendTimer();

  }

  startResendTimer() {
    this.resendTimeout = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.disableResend = false;
        clearInterval(this.resendTimeout);
      }
    }, 1000); // 1 second
  }

  ngOnDestroy() {
    if (this.resendTimeout) {
      clearInterval(this.resendTimeout);
    }
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  resendConfirmation() {
    const requestBody = {
      email: this.verificationForm.value.email,
      key: this.key
    }
    this.spinner.show();
    this.apiService.resendConfirmation(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const { data, statusCode, message } = response;
      if (statusCode == 200) {
        this.toast.showSuccess("OTP has been sent again.");
      }
      else {
        if (statusCode == 400) {
          this.toast.showError("Request not send.", "Error");
        } else {
          this.toast.showError(message, "Error");
        }
      }
    });
  }

  verification() {
    let otp = this.verificationForm.value.code;
    const requestBody = {
      email: this.verificationForm.value.email,
      otp: otp.trim(),
    }
    this.spinner.show();
    this.apiService.verifyOTP(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const { statusCode, data, message } = response;
      console.log(response);
      if (message && statusCode == 200) {
        this.local.saveData("authtoken", data.token);
          this.router.navigate(['account-info']).then(() => { this.toast.showSuccess("Your Key is verified & you are logged in successfully."); })
      }
      else {
        if (statusCode == 400) {
          this.toast.showError("Request not send.", "Error");
        } else {
          this.toast.showError("Please contact admin.", "Error");
        }
      }
    },
      (error: any) => {
        this.toast.showError("Failed to connect to server", "Error");
        this.spinner.hide();
      });
  } catch(err: any) {
    this.toast.showError(err, "Error");
  }
}
