import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { LocalService } from './local.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  apiUrl: string = environment.apiUrl;
  
  constructor(private http: HttpClient, private toast: ToastService, private local: LocalService) {}

  // private getHeaders() {
  //   const token = this.local.getData('token'); // Fetch token dynamically
  //   const headers = new HttpHeaders({
  //     'x-auth-token': `${token}`,
  //   });
  //   return headers;
  // }

  public getAllProfiles(reqBody: any){
    return this.http.post(`${this.apiUrl}/customers`, reqBody);
  }

  public testHelloWorld(){
    return this.http.get(`https://m7s9ltvocb.execute-api.ap-south-1.amazonaws.com/dev/helloWorld`);
  }

  public getUser(reqBody: any){
    // const headers = this.getHeaders();
    const token = this.local.getData('token');
    return this.http.post(`${this.apiUrl}/user`,reqBody, {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  }

  public registerCustomer(reqBody: any){
    return this.http.post(`${this.apiUrl}/register`, reqBody);
  }

  private imageUrl = new BehaviorSubject(" ");

  setImageUrl(imageUrl: string){
    this.imageUrl.next(imageUrl);
  }

  getImageUrl = this.imageUrl.asObservable();

  private name = new BehaviorSubject(" ");

  setName(name: string){
    this.name.next(name);
  }

  getName = this.name.asObservable();

  public getUserProfile(reqBody: any){
    const role = this.local.getData("userrole");
    const apiEndPoint = (role=="admin")?"admin-profile":"get-customer-info"
    return this.http.post(`${this.apiUrl}/${apiEndPoint}`, reqBody);
  }

  public getAdminProfile(reqBody: any){
    return this.http.post(`${this.apiUrl}/admin-profile`, reqBody);
  }

  public onBoardUser(reqBody: any){
    const token = this.local.getData('token');
    return this.http.post(`${this.apiUrl}/admin/onboard`, reqBody , {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  }

  public getCustomers(){
    const token = this.local.getData('token');
    return this.http.get(`${this.apiUrl}/admin/customers` , {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public getCloudData(reqBody: any){
    const token = this.local.getData('token');
    return this.http.post(`${this.apiUrl}/admin/rowdata`, reqBody , {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public getToolsData(){
    const token = this.local.getData('token');
    return this.http.get(`${this.apiUrl}/admin/get-tools` , {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public createTool(reqBody: any){
    const token = this.local.getData('token');
    return this.http.post(`${this.apiUrl}/admin/add-tools`, reqBody , {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public getTool(){
    const token = this.local.getData('token');
    return this.http.get(`${this.apiUrl}/admin/add-tools`, {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public getToolType(){
    const token = this.local.getData('token');
    return this.http.get(`${this.apiUrl}/admin/get-tool-type`, {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public createInstance(reqBody: any){
    const token = this.local.getData('token');
    return this.http.post(`${this.apiUrl}/admin/instance`, reqBody , {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  };

  public launchStack(reqBody: any){
    const token = this.local.getData('token');
    return this.http.post(`${this.apiUrl}/user/deploy`, reqBody, {
      headers:{
        'x-auth-token': `${token}`
      }
    });
  }

  public getStatistics(){
    return this.http.get(`/assets/stats.json`);
  }

  public createProfile(reqBody: any){
    return this.http.post(`${this.apiUrl}/register`,reqBody);
  }

  public login(reqBody: any){
    return this.http.post(`${this.apiUrl}/login`,reqBody);
  }
  public verifyOTP(reqBody: any){
    return this.http.post(`${this.apiUrl}/verify-otp`,reqBody);
  }

public status(reqBody: any){
  return this.http.post(`${this.apiUrl}/status`,reqBody);
}

  public confirmSignUp(reqBody: any){
    return this.http.post(`${this.apiUrl}/confirmsignup`,reqBody);
  }
  public logout(reqBody: any){
    return this.http.post(`${this.apiUrl}/auth`,reqBody);
  }

  public forgotPassword(reqBody: any){
    return this.http.post(`${this.apiUrl}/auth/recover`,reqBody);
  }
  public forgotkey(reqBody: any){
    return this.http.post(`${this.apiUrl}/forgot`,reqBody);
  }

  public confirmForgotPassword(reqBody: any){
    return this.http.post(`${this.apiUrl}/auth/confirm-forgot-password-code`,reqBody);
  }

  public updateProfile(id: any, reqBody: any){
    return this.http.post(`${this.apiUrl}/users/update-user`,reqBody);
  }

  public updateUser(reqBody: any){
    return this.http.post(`${this.apiUrl}/update`,reqBody);
  }

  public showLogs(reqBody: any){
    return this.http.post(`${this.apiUrl}/log`,reqBody);
  }

  public start(reqBody: any){
    return this.http.post(`${this.apiUrl}/start`,reqBody);
  }

  public stop(reqBody: any){
    return this.http.post(`${this.apiUrl}/stop`,reqBody);
  }

  public reboot(reqBody: any){
    return this.http.post(`${this.apiUrl}/reboot`,reqBody);
  }

  public disableUser(reqBody: any){
    return this.http.post(`${this.apiUrl}/remove-customer`,reqBody);
  }

  public loginas(reqBody: any){
    return this.http.post(`${this.apiUrl}/user-dashboard`,reqBody);
  }

  public resendToken(reqBody: any){
    return this.http.post(`${this.apiUrl}/resend-key`,reqBody);
  }

  public destroyUser(reqBody: any){
    return this.http.post(`${this.apiUrl}/destroy`,reqBody);
  }

  public globalLogout(reqBody: any){
    return this.http.post(`${this.apiUrl}/logout`,reqBody);
  }

  public changePassword(reqBody: any){
    return this.http.post(`${this.apiUrl}/users/change-password`,reqBody);
  }

  public enableUser(reqBody: any){
    return this.http.post(`${this.apiUrl}/users/enable-user`,reqBody);
  }

  public admintoUser(reqBody: any){
    return this.http.post(`${this.apiUrl}/make-admin-to-user`,reqBody);
  }

  public usertoAdmin(reqBody: any){
    return this.http.post(`${this.apiUrl}/make-user-to-admin`,reqBody);
  }

  public updateProfileAdmin(reqBody:any){
    return this.http.post(`${this.apiUrl}/auth/update-admin`,reqBody);
  }

  public updateProfileuser(reqBody:any){
    return this.http.post(`${this.apiUrl}/users/update-user`,reqBody);
  }

  public adminLogin(reqBody:any){
    return this.http.post(`${this.apiUrl}/admin-login`,reqBody);
  }

  public importUser(reqBody: any){
    return this.http.post(`${this.apiUrl}/user-import`, reqBody);
  }

  public uploadImage(reqBody:any){
    return this.http.post(`${this.apiUrl}/upload`,reqBody);
  }

  public resendConfirmation(reqBody:any){
    return this.http.post(`${this.apiUrl}/login`,reqBody);
  }

  public verifyImport(reqBody:any){
    return this.http.post(`${this.apiUrl}/auth/import-signup`,reqBody);
  }

  public refreshToken(reqBody: any){
    return this.http.post(`${this.apiUrl}/auth/refresh-token`,reqBody);
  }

  public resumePdf(reqBody:any){
    return this.http.post(`${this.apiUrl}/users/resume`,reqBody);
  }

  public falseBgTestEmail(reqBody: any){
    return this.http.post(`${this.apiUrl}/users/bg-test`, reqBody);
  }

  public falseAptTestEmail(reqBody: any){
    return this.http.post(`${this.apiUrl}/users/bg-test`, reqBody);
    }

  public documentUpload(reqBody:any){
    return this.http.post(`${this.apiUrl}/users/document`,reqBody);
  }

  public documentDownload(reqBody:any){
    return this.http.post(`${this.apiUrl}/users/download-document`,reqBody);
  }

  public displayGenericError(statusCode: number, response: any){
    const{message} = response
    if(statusCode == 400){
      return this.toast.showError(message,"Error");
    }
    else if(statusCode == 401){
      return this.toast.showError("Incorrect Username and Password","Error");
    }
    else if(statusCode == 404){
      return this.toast.showError("Page Not Found","Error");
    }
  }

}
