import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SidebarService } from '../sidebar/sidebar.service';
import { ModalControlService } from './modal-control.service';
import { ToastService } from '../../shared/toast.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmedValidator } from 'src/app/confirm-validator';

declare var $:any;

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit, AfterViewInit {
  changepasswordForm!: FormGroup;
  showModal: boolean = false;
  strongPassword= false;

  constructor(
    public sidebarservice: SidebarService,
    private modalControlService: ModalControlService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toast: ToastService
  ) {}

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  ngOnInit() {
    this.modalControlService.showModalEvent.subscribe(
      (showModal: boolean) => {
        this.showModal = showModal;
      }
    );

    this.changepasswordForm = this.formBuilder.group(
      {
        pre_password: ['', Validators.required],
        new_password: ['', Validators.required],
        confirm_password: ['', Validators.required]
      },
      {
        validator: ConfirmedValidator('new_password', 'confirm_password')
      }
    );
  }

  ngAfterViewInit() {
    // Subscribe to showModalEvent after the view has been initialized
    this.modalControlService.showModalEvent.subscribe((showModal: boolean) => {
      this.showModal = showModal; // Update the value of showModal
      console.log(showModal);
      if (showModal) {
        // Show the modal only if showModal is true
        const modalElement = document.getElementById('changePassword');
        if (modalElement) {
          // Use jQuery and Bootstrap to show the modal
          $(modalElement).modal('show');
        }
      }
    });
  }

  changepassword() {
    try {
      const requestBody = {
        pre_password: this.changepasswordForm.value.pre_password,
        new_password: this.changepasswordForm.value.new_password
      };
      this.spinner.show();
      this.apiService.changePassword(requestBody).subscribe(
        (response: any) => {
          this.spinner.hide();
          const { statusCode, message } = response;
          if (statusCode == 200) {
            this.router.navigate(['/login']);
            this.toast.showSuccess('Password has been updated');
          } else {
            if (statusCode == 400) {
              this.toast.showError(
                'Please check the password, Request not send',
                'Error'
              );
            } else {
              this.apiService.displayGenericError(statusCode, response);
            }
          }
        },
        (error: any) => {
          // Handle connection failure or API changes
          this.toast.showError('Failed to connect to the server', 'Error');
          this.spinner.hide();
        }
      );
    } catch (err: any) {
      this.toast.showError(err, 'Error');
    }
  }
}
