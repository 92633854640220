import { Injectable } from '@angular/core';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private local: LocalService) { }

  isNotLoggedIn(){
    // return !this.local.getData('authtoken');
    return !localStorage.getItem('token')
  }

  UserRole(){
    return !this.local.getData('userrole');
  }

  logout() {
    this.local.clearData();
  }

  isAdmin(){
      return false;
      //return this.local.getData('userrole').indexOf('admin') > -1;
      console.log(this.local.getData('userrole'))
      if (this.local.getData('userrole')=='admin'){
        return true;
      }
      else{
        return false;
      }
  }

}
