import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {signIn , signOut , getCurrentUser, fetchAuthSession, confirmSignIn, fetchUserAttributes , resetPassword , confirmResetPassword , AuthSession , updatePassword} from 'aws-amplify/auth';
import { Router } from '@angular/router';
import { LocalService } from './shared/local.service';
import { ToastService } from './shared/toast.service';
import { ApiService } from './shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

interface User {
  email: string;
  password: string;
}

interface ConfirmPassword {
  password: string;
}

interface ResetPasswordInput {
  username: string;
}

interface VerifyOTPInput {
  // username: string;
  confirmationCode: string;
}

interface ConfirmResetPasswordInput {
  username: string;
  newPassword: string;
  confirmationCode: string;
}

@Injectable({
  providedIn: 'root'
})

export class CognitoService {
  storedOTP: any;
  storedUsername: any;

  constructor(private router: Router, private local: LocalService,  private toast: ToastService, private apiService: ApiService, private spinner: NgxSpinnerService) { 
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.userPoolId,
          userPoolClientId: environment.userPoolClientId
        }
      }
    });
  }

  // login/signin using aws cognito
  async signIn(user: User): Promise<any> {
    try {
      const signInResult = await signIn({
        username: user.email,
        password:user.password,
      })

      this.spinner.show();
      if (signInResult.isSignedIn) {
        const currentUser = await this.getCurrentUser();
        const session = await this.getAuthSession();
        this.local.saveData('userrole', session?.tokens?.idToken?.payload?.['custom:role'])
        this.local.saveData('email', user.email);
        this.local.saveData('token', session.tokens?.idToken?.toString());
        this.local.getData('token')

        this.apiService.getUser({email:user.email}).subscribe((res:any)=>{
          if(session?.tokens?.idToken?.payload?.['custom:role'] === "1"){
            this.spinner.hide();
            this.router.navigate(["/admin/dashboard"]);
            this.toast.showSuccess("Logged In!");
          }else{
            if(res.data?.user?.is_launch === true){
              this.spinner.hide();
              this.router.navigate(["/user/dashboard"]);
              this.toast.showSuccess("Logged In!");
              
            }else{
              this.spinner.hide();
              this.router.navigate(["/account-info"]);
              this.toast.showSuccess("Logged In!");
            };
          };
        });

        return { 
          signInResult, 
          currentUser,
          idToken: session.tokens?.idToken?.toString(),
          accessToken: session.tokens?.accessToken?.toString(),
          sessionDetails: session
        }
      }else if(signInResult.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'){
        this.router.navigate(["/first-login"]);
      } else if (signInResult.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
        this.router.navigate(["/verification"]);
      }else if(signInResult.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION'){
        this.router.navigate(["/verification"]);
      }
      return signInResult;
    } catch (error) {
      this.toast.showError("Incorrect email or password")
      throw error;
    }
  }

  //this function is called when user logs in for first time when reseting its password
  async confirmSignIn(confirmPassword: ConfirmPassword): Promise<any> {
    try {
      const signInResult = await confirmSignIn({
        challengeResponse: confirmPassword.password,
      });
      
      if (signInResult.isSignedIn) {
        const currentUser = await this.getCurrentUser();
        const session = await this.getAuthSession();
        // console.log(fetchUserAttributes())
        return { 
          signInResult, 
          currentUser,
          idToken: session.tokens?.idToken?.toString(),
          accessToken: session.tokens?.accessToken?.toString()
        };
      }
      
      return signInResult;
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  async confirmSignInWithSMSCode(requestBody: { confirmationCode: string }): Promise<any> {
    try {
      const signInResult = await confirmSignIn({
        challengeResponse: requestBody.confirmationCode
      });
  
      this.spinner.show();
      
      if (signInResult.isSignedIn) {
        const currentUser = await this.getCurrentUser();
        const session = await this.getAuthSession();
        
        // Save user data in local storage
        this.local.saveData('userrole', session?.tokens?.idToken?.payload?.['custom:role']);
        this.local.saveData('email', currentUser.username);
        this.local.saveData('token', session.tokens?.idToken?.toString());
        
        // Get user details and handle navigation
        this.apiService.getUser({email: currentUser.username}).subscribe((res: any) => {
          if(session?.tokens?.idToken?.payload?.['custom:role'] === "1") {
            this.spinner.hide();
            this.router.navigate(["/admin/dashboard"]);
            this.toast.showSuccess("Logged In Successfully!");
          } else {
            if(res.data.user.is_launch === true) {
              this.spinner.hide();
              this.router.navigate(["/user/dashboard"]);
              this.toast.showSuccess("Logged In Successfully!");
            } else {
              this.spinner.hide();
              this.router.navigate(["/account-info"]);
              this.toast.showSuccess("Logged In Successfully!");
            }
          }
        });
  
        return {
          signInResult,
          currentUser,
          idToken: session.tokens?.idToken?.toString(),
          accessToken: session.tokens?.accessToken?.toString(),
          sessionDetails: session
        };
      }
      
      return signInResult;
    } catch (error) {
      this.spinner.hide();
      this.toast.showError("Invalid verification code");
      throw error;
    }
  }
  
  //To get the current user details
  async getCurrentUser(): Promise<any> {
    try {
      return await getCurrentUser();
    } catch (error) {
      console.error('Error getting current user:', error);
      throw error;
    }
  }

  // signout or logout
  async signOut(): Promise<void> {
    try {
      await signOut();
      this.toast.showSuccess("Logged Out!")
      this.router.navigate(["/login"])
      localStorage.clear();
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }


  // To get current user session details
  async getAuthSession(): Promise<any> {
    try {
      return await fetchAuthSession();
    } catch (error) {
      console.error('Error fetching auth session:', error);
      throw error;
    }
  }


  // forgot password method
  // async forgotPassword(input: ResetPasswordInput): Promise<any> {
  //   try {
  //     const resetResult = await resetPassword({ username: input.username });
      
  //     if (resetResult.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
  //       this.toast.showSuccess("OTP sent to your email!")
  //       this.router.navigate(['/verification']);
  //     }
      
  //     return resetResult;
  //   } catch (error) {
  //     console.error('Error initiating password reset:', error);
  //     throw error;
  //   }
  // }

  async verifyOTP(input: VerifyOTPInput): Promise<boolean> {
    try {
      // Store OTP for the final step
      this.storedOTP = input.confirmationCode;
      
      // Verify the OTP is 6 digits
      if (!/^\d{6}$/.test(input.confirmationCode)) {
        throw new Error('Invalid OTP format');
      }
      // If OTP is valid, navigate to reset password page
      this.router.navigate(['/reset-password']);
      return true;
    } catch (error) {
      console.error('Error verifying OTP:', error);
      this.toast.showError('Invalid OTP. Please try again.');
      throw error;
    }
  }

  // confirm new password after OTP verification
  // async confirmForgotPassword(input: ConfirmResetPasswordInput): Promise<any> {
  //   try {
  //     const confirmResetResult = await confirmResetPassword({
  //       username: input.username,
  //       newPassword: input.newPassword,
  //       confirmationCode: this.storedOTP
  //     });
      
  //     // if (confirmResetResult.isSuccess) {
  //     //   this.router.navigate(['/login']);
  //     // }
  //       this.toast.showSuccess('Password reset successful!');
  //       this.clearStoredData();
  //       this.router.navigate(['/login']);
      
  //     return confirmResetResult;
  //   } catch (error) {
  //     console.error('Error confirming password reset:', error);
  //     this.toast.showError('Failed to reset password. Please try again.');
  //     throw error;
  //   }
  // }

  async forgotPassword(requestBody: { username: string }): Promise<any> {
    try {
      const response = await resetPassword({ 
        username: requestBody.username 
      });
      if (response.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        this.toast.showSuccess('Verification code sent to your email');
        this.router.navigate(['/reset-password',{ email: requestBody.username }]);
        return response;
      }
      
      return response;
    } catch (error: any) {
      if (error.name === 'UserNotFoundException') {
        this.toast.showError('No account found with this email address');
      } else if (error.name === 'LimitExceededException') {
        this.toast.showError('Too many attempts. Please try again later');
      } else if (error.name === 'InvalidParameterException') {
        this.toast.showError('Please enter a valid email address');
      } else {
        this.toast.showError('An error occurred. Please try again');
      }
      throw error;
    }
  }

  // Verify code and set new password
  async confirmForgotPassword(requestBody: {
    username: string,
    code: string,
    newPassword: string
  }): Promise<any> {
    try {
      const response = await confirmResetPassword({
        username: requestBody.username,
        confirmationCode: requestBody.code,
        newPassword: requestBody.newPassword
      });
      
      this.toast.showSuccess('Password reset successfully');
      this.router.navigate(['/login']);
      
      return response;
    } catch (error: any) {
      console.log("error",error);
      this.toast.showError(error.message)
      throw error;
    }
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      this.spinner.show();
      const currentUser = await this.getCurrentUser();
      
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }

      const updatePasswordResult = await updatePassword({
        oldPassword: oldPassword,
        newPassword: newPassword
      });
      this.spinner.hide();
      this.toast.showSuccess('Password changed successfully');
      this.router.navigate(['/user/profile']);
    } catch (error: any) {
      this.spinner.hide();
      this.toast.showError(error.message);
      throw error;
    }
  }  

  private clearStoredData(): void {
    this.storedUsername = '';
    this.storedOTP = '';
  }
}