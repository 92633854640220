import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalControlService {

  constructor() { }

  showModalEvent = new EventEmitter<boolean>();

  show() {
    this.showModalEvent.emit(true);
  }

  hide() {
    this.showModalEvent.emit(false);
  }
}
