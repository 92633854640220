<body>
    <!-- Loader -->
    <!-- <div id="preloader">
        <div id="status">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    </div> -->


    <!-- Navbar End -->

    <!-- Hero Start -->
    <section class="hero-1-bg bg-light" style="background-image: url(../../../assets/images/landing/hero-1-bg-img.png)" id="home">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6">
                    <h4 class="hero-1-title fw-normal text-dark mb-4">Cybersecurity with an Attacker’s Mind and a Defender’s Brain.</h4>
                    <p class="text-muted mb-4 pb-3 style"><b>Invinsense is a consolidated platform which integrates various approaches of cybersecurity: offensive security, defensive security and security compliance.</b></p>
                    <a routerLink="/login" class="btn btn-primary">Get Started <span class="ms-2 right-icon">&#8594;</span></a>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class=" mt-5 mt-lg-0">
                        <img src="../../../assets/images/landing/hero-img.png" alt="" class="img-fluid d-block mx-auto">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Hero End -->

    <!-- About us Start -->
    <section class="section" id="about">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="text-center mb-5">
                      <h2 class="hero-1-title fw-normal text-dark mb-4">An Intelligence Sharing Ecosystem of Landscapes, Approaches, Technologies, and People</h2>
                    </div>
                </div>
            </div>
			<div>
				<p class="text-muted mb-2 pb-2"><b>The Invinsense platform encompasses a wide range of approaches, including defensive security, deception and patch management, offensive security, and security compliances. It provides dedicated tools and technologies for each of these approaches.</b></p>
			</div>
			<div>
				<p class="text-muted mb-2 pb-3"><b>These technologies and tools seamlessly share intelligence with one another, enhancing your overall cybersecurity posture with an attacker’s mind, a defender’s brain and security compliances.</b></p>
			</div>
      <div>
				<p class="text-muted mb-4 pb-3"><b>Invinsense can also be adapted to all kinds of landscapes including on-premise, cloud, OT and IOT.</b></p>
			</div>


            <!-- <div class="row gy-3"> -->
            <!-- <div class="col-lg-4">

                    <h2 class="fw-normal line-height-1_4 mb-4">A Marketplace for Cyber Talent and Cyber Projects</h2>
                    <p class="text-muted mb-4">All cybersecurity plans require people, processes, and technology for execution. People are the most important aspect, and with Invinsource, we are working to enhance this aspect with the power of our platform.</p>

                </div> -->
                <!-- <div class="col-lg-4">
                    <div class="border-0">
                        <div class="bg-soft-primary about-img rounded">
                            <img src="../../../assets/images/landing/about/img-1.png" alt="" class="img-fluid d-block mx-auto">
                        </div>
                        <div class="mt-3">

                            <h4 class="f-18">Cyber Talent</h4>
                            <p class="text-muted">Acquire relevant cyber talent for all your cybersecurity projects.</p>
                            <a href="#" class="read-more">Read More <span class="ms-2 right-icon">&#8594;</span></a>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-lg-4">
                    <div class="border-0">
                        <div class="bg-soft-info about-img rounded">
                            <img src="../../../assets/images/landing/about/img-2.png" alt="" class="img-fluid d-block mx-auto">
                        </div>
                        <div class="mt-3">

                            <h4 class="f-18">Cyber Projects</h4>
                            <p class="text-muted">Find the best-suited project according to your skills and career path.</p>
                            <a href="#" class="read-more">Read More <span class="ms-2 right-icon">&#8594;</span></a>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </section>
    <!-- About us End -->



    <!-- Footer Start -->

    <!-- Footer End -->

    <!-- javascript -->
    <script src="../../../assets/js/landing/jquery.min.js"></script>
    <script src="../../../assets/js/landing/bootstrap.bundle.min.js"></script>
    <script src="../../../assets/js/landing/scrollspy.min.js"></script>
    <script src="../../../assets/js/landing/jquery.easing.min.js"></script>
    <!-- feather icons -->
    <script src="https://unpkg.com/feather-icons"></script>

    <script src="../../../assets/js/landing/contact.js"></script>

    <!-- carousel -->
    <script src="../../../assets/js/landing/owl.carousel.min.js"></script>

    <!-- Main Js -->
    <script src="../../../assets/js/landing/app.js"></script>

</body>
