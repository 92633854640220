<!-- wrapper -->
<div class="wrapper">
	<nav class="navbar navbar-expand-lg navbar-light bg-white rounded fixed-top rounded-0 shadow-sm">
		<div class="container-fluid">
			<a class="navbar-brand ms-1" href="#">
				<img src="assets/images/Invinsource_logo-01-dark.png" width="200" alt="" />
			</a>
			<!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent1">
				<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
					<li class="nav-item"> <a class="nav-link active" aria-current="page" href="#"><i class='bx bx-home-alt me-1'></i>Home</a>
					</li>
					<li class="nav-item"> <a class="nav-link" href="#"><i class='bx bx-user me-1'></i>About</a>
					</li>
					<li class="nav-item"> <a class="nav-link" href="#"><i class='bx bx-category-alt me-1'></i>Features</a>
					</li>
					<li class="nav-item"> <a class="nav-link" href="#"><i class='bx bx-microphone me-1'></i>Contact</a>
					</li>
				</ul>
			</div> -->
		</div>
	</nav>
	<div class="error-404 d-flex align-items-center justify-content-center">
		<div class="container">
			<div class="card py-5">
				<div class="row g-0">
					<div class="col col-xl-5">
						<div class="card-body p-4">
							<h1 class="display-1"><span class="text-primary">4</span><span class="text-danger">0</span><span class="text-success">4</span></h1>
							<h2 class="font-weight-bold display-4">Lost in Space</h2>
							<p>You have reached the edge of the universe.
								<br>The page you requested could not be found.
								<br>Dont'worry and return to the previous page.</p>
							<div class="mt-5"> <a href="#" class="btn btn-primary btn-lg px-md-5 radius-30">Go Home</a>
								<!-- <a href="#" (click)="goBack()" class="btn btn-outline-dark btn-lg ms-3 px-md-5 radius-30">Back</a> -->
							</div>
						</div>
					</div>
					<div class="col-xl-7">
						<img src="https://cdn.searchenginejournal.com/wp-content/uploads/2019/03/shutterstock_1338315902.png" class="img-fluid" alt="">
					</div>
				</div>
				<!--end row-->
			</div>
		</div>
	</div>
	<div class="row bg-white p-3 fixed-bottom border-top shadow">
		<div class="col-12">
			<!-- <ul class="list-inline">
			</ul> -->
			<p class="mb-0 text-center">© Copyright Invinsense 2024. All right reserved.</p>
		</div>
	</div>
</div>
<!-- end wrapper -->
