import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../../shared/local.service';
import { ToastService } from '../../shared/toast.service';
import { ConfirmedValidator } from '../../confirm-validator';

@Component({
  selector: 'app-verify-import',
  templateUrl: './verify-import.component.html',
  styleUrls: ['./verify-import.component.scss']

})
export class VerifyImportComponent implements OnInit{

  verifyImportsForm !: FormGroup;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private local: LocalService, private toast: ToastService) { }


  ngOnInit() {
    this.local.clearData();

    this.verifyImportsForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
      confirm_password: ['',Validators.required]
    },
    {
      validator: ConfirmedValidator('Password', 'confirm_password')
    });
  }

  verifyImports(){
    const requestBody = {
      email: this.verifyImportsForm.value.email,
      password: this.verifyImportsForm.value.password,
    }
    this.spinner.show();

    this.apiService.verifyImport(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      const { data, statusCode, message } = response;
      if (data && statusCode == 200) {
          this.router.navigate(['/verification']).then(()=>{ this.toast.showSuccess("Your Account is Confirmed");})
        }
      else {
        if(statusCode == 400){
          this.toast.showError("Request not send.","Error");
        } else {
          this.toast.showError(message,"Error");
        }
      }
    },
    (error: any) => {
      // Handle connection failure or API changes
      this.toast.showError("Failed to connect to server", "Error");
      this.spinner.hide();
    });
  } catch (err: any) {
    this.toast.showError(err,"Error");
  }
}
