import { Component , OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/shared/user.service';
import { LocalService } from 'src/app/shared/local.service';
import { ApiService } from 'src/app/shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/shared/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  clicked: any = false;
  customer: any;
  name: any;
  submitted: any;
  step: any;
  multistep: any;
  customers: any;
  xdrComponents: any;
  xdrPlusComponents: any;
  oxdrComponents: any;
  gsosComponents: any;
  showTools: any;
  showAllTools: any;
  itemsArray: any[] = [];
  tools: any[] = [];

  cloud_platform: string | null = "AWS";
  toolsArr = [
    {
      name: 'siem',
      description: `SIEM solutions allow organizations to efficiently collect and analyse log data from all of their digital assets in one place. This gives them the ability to recreate past incidents or analyse new ones to investigate suspicious activity and implement more effective security processes.`,
    },
    {
      name: 'soar',
      description: `SOAR (Security Orchestration, Automation, and Response) refers to a collection of software solutions and tools that allow organizations to streamline security operations in three key areas: threat and vulnerability management, incident response, and security operations automation.`,
    },
    {
      name: 'EDR',
      description: `EDR security solutions analyse events from laptops, desktop PCs, mobile devices, servers, and even IoT and cloud workloads, to identify suspicious activity. They generate alerts to help security operations analysts uncover, investigate and remediate issues.`,
    },
    {
      name: 'threat-intel',
      description: `Threat intelligence, is information an organization uses to understand the threats that have, will, or are currently targeting the organization. This info is used to prepare, prevent, and identify cyber threats looking to take advantage of valuable resources. It is an essential aspect of maintaining the security of an organization and can help to prevent data breaches and other cyber-attacks.`,
    },
    {
      name: 'case-management',
      description: `Case management solutions are applications designed to support a complex process that requires a combination of human tasks and electronic workflow, such as an incoming application, a submitted claim, a complaint, or a claim that is moving to litigation.`,
    },
    {
      name: 'Threat Exchange',
      description: `threat exchange solutions can help organizations to comply with various regulatory requirements, such as those related to incident reporting and information sharing, by providing a mechanism for sharing information in a secure and compliant manner.`,
    },
    {
      name: 'Deception',
      description: `Deception is a category of cybersecurity solutions that detect threats early with low rates of false positives. It deploys realistic decoys (e.g., domains, databases, directories, servers, apps, files, credentials, breadcrumbs) in a network alongside real assets to act as lures.`,
    },
    {
      name: 'patch-management',
      description: `Patch management is the practice of deploying firmware, driver, operating system (OS), and application updates to your computing endpoints. The main purpose of patch management is to ensure the security and performance of devices`,
    },
    {
      name: 'vulnerability-management',
      description: `The purpose of a vulnerability management program is to keep your network safe from known exploitations and ensure it stays compliant with any regulatory requirements. It does this by analysing your network for any incompatibilities, missed updates and common weaknesses within the software you use.`,
    },
    {
      name: 'bas',
      description: `Breach and Attack Simulation (BAS) is a proactive cybersecurity technique that involves simulating various types of attacks and breaches to identify vulnerabilities and weaknesses in an organization's security infrastructure. BAS tools enable organizations to assess their security posture and evaluate the effectiveness of their security controls.`,
    },
    {
      name: 'Attack Surface Management',
      description: `Attack Surface Management helps organizations to identify and mitigate potential vulnerabilities before they can be exploited by attackers. By taking a proactive approach to security, organizations can reduce the risk of cyber-attacks and protect their sensitive data from being compromised.`,
    },
    {
      name: 'RedOps',
      description: `RedOps helps organizations to improve their incident response capabilities by providing an opportunity to test their response procedures in a controlled environment. This can help to ensure that organizations are well-prepared to respond effectively to real-world cyber-attacks.`,
    },
    {
      name: 'Vision Mission',
      description: `This module helps you in clearly defining the vision and mission of your organization. Easy to print and distribute per your needs. A web form-based tool that allows you to provide mission and vision statement as input. Fully customizable with logo and trademarks of your organization`,
    },
    {
      name: 'CISO Dashboard',
      description: `It provides Dynamic Visibility to the team of security solution with KPI Dashboards. It showcase the visual depiction of key indicators to help organization with presenting and communicating the security posture and needs of the organization with top management. It has Single-pane-of-glass visibility of people, processes and technologies. Provides real-time details on a single click that can be used as a presentation deck. Eliminates the need for PowerPoint slides every time discussing cyber security with top management.`,
    },
    {
      name: 'Table top',
      description: `This module to give your security team hands-on experience of real incidents and enables management to review security posture and take the right decision.`,
    },
    {
      name: 'gsos',
      description: `Compliance helps organizations to protect sensitive data, maintain the trust of customers and partners, and avoid legal and financial penalties for non-compliance. Compliance also promotes a culture of security awareness and best practices, ensuring that employees are well-informed and equipped to respond effectively to potential security threats.`,
    },
    {
      name: 'Awareness',
      description: `Cyber Security Awareness module provides you with tons of security awareness content including posters, newsletters, screen savers, educational videos, interactive quiz and many more.This module leverages web based digital asset management (DAM) to offer you a digital and customizable asset and content management platform. This will improve and strengthen overall organization’s security maturity amongst people and processes.`,
    },
    {
      name: 'Risk Assessment',
      description: `A Well-structure module which helps you understand the overall Enterprise Risk. Helps in management of the asset and network risks. It is designed Based on a well-known and widely used integrated GRC platform for the organization’s top management. Helps top management to identify, analyse and plan response to the network risks`,
    },
    {
      name: 'Project Management',
      description: `This module helps you gain visibility of all cyber security solutions running in your organization. It further gives insights like patch status, software update status, risk score, compliance, vulnerability management, etc. for those solutions. This is the overall module that pulls together the vision and mission of an organization with appropriate security policies, vulnerability assessment guidelines, compliances and standards and helps corporate leaders understand the importance of them to work in accord with them.`,
    },
  ];

  navName: any;
  imageUrl: any;
  profileData: any = {};
  oxdrAvailable: any;
xdrpAvailable: any;
xdrAvailable: any;
gsosAvailable: any;
  constructor(
    private http: HttpClient,
    private user: UserService,
    private local: LocalService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.name = new FormControl('');
    this.submitted = false;
    this.step = 3;

    this.xdrComponents = [
      {
        name: 'siem',
        title: 'SIEM',
        id: 'x1',
        status: '0',
        description: `SIEM solutions allow organizations to efficiently collect and analyse log data from all of their digital assets in one place. This gives them the ability to recreate past incidents or analyse new ones to investigate suspicious activity and implement more effective security processes.`,
      },
      {
        name: 'soar',
        title: 'SOAR',
        id: 'x2',
        description: `SOAR (Security Orchestration, Automation, and Response) refers to a collection of software solutions and tools that allow organizations to streamline security operations in three key areas: threat and vulnerability management, incident response, and security operations automation.`,
        status: '0',
      },
      {
        name: 'case-management',
        title: 'Case Management',
        id: 'x3',
        status: '0',
        description: `Case management solutions are applications designed to support a complex process that requires a combination of human tasks and electronic workflow, such as an incoming application, a submitted claim, a complaint, or a claim that is moving to litigation.`,
      },
      {
        name: 'threat-intel',
        title: 'Threat Intel',
        id: 'x4',
        status: '0',
        description: `Threat intelligence, is information an organization uses to understand the threats that have, will, or are currently targeting the organization. This info is used to prepare, prevent, and identify cyber threats looking to take advantage of valuable resources. It is an essential aspect of maintaining the security of an organization and can help to prevent data breaches and other cyber-attacks.`,
      },
    ];

    this.xdrPlusComponents = [
      {
        name: 'network-deception',
        title: 'Network Deception',
        id: 'xp1',
        status: '0',
        description:
          'Deception is a category of cybersecurity solutions that detect threats early with low rates of false positives. It deploys realistic decoys (e.g., domains, databases, directories, servers, apps, files, credentials, breadcrumbs) in a network alongside real assets to act as lures.',
      },
      {
        name: 'patch-management',
        title: 'Patch Management',
        id: 'xp2',
        status: '0',
        description: `Patch management is the practice of deploying firmware, driver, operating system (OS), and application updates to your computing endpoints. The main purpose of patch management is to ensure the security and performance of devices`,
      },
    ];

    this.oxdrComponents = [
      {
        name: 'vulnerability-management',
        title: 'Vulnerability Management',
        id: 'ox1',
        status: '0',
        description: `Centrally manages all kinds of vulnerabilities.`,
      },
      {
        name: 'bas',
        title: 'Breach and Attack Simulation',
        id: 'ox2',
        status: '0',
        description: `Launch attack simulations to test your defence.`,
      },
      {
        name: 'asm',
        title: 'Attack Surface Monitoring',
        id: 'ox2',
        status: '0',
        description: `Scans the external environment like adversaries.`,
      },
      {
        name: 'red-ops',
        title: 'Red Ops',
        id: 'ox2',
        status: '0',
        description: `Continuous offensive operations to improve cybersecurity posture.`,
      },
      {
        name: 'phishing',
        title: 'See Phish',
        id: 'ox2',
        status: '0',
        description: `A phishing tool demonstrates its ability to simulate realistic attacks, tracking user engagement to uncover security weaknesses and measure awareness. It provides insights into potential risks, emphasizing the need for training and security improvements.`,
      },
    ];

    this.gsosComponents = [
      {
        name: 'gsos',
        title: 'Compliance Platform',
        id: 'gs1',
        status: '0',
        description: `Compliance helps organizations to protect sensitive data, maintain the trust of customers and partners, and avoid legal and financial penalties for non-compliance. Compliance also promotes a culture of security awareness and best practices, ensuring that employees are well-informed and equipped to respond effectively to potential security threats.`,
      },
      {
        name: 'enterprise_risk_management',
        title: 'Enterprise Risk Management',
        id: 'gs2',
        status: '0',
        description: `A Well-structure module which helps you understand the overall Enterprise Risk. Helps in management of the asset and network risks. It is designed Based on a well-known and widely used integrated GRC platform for the organization’s top management. Helps top management to identify, analyse and plan response to the network risks`,
      },
      {
        name: 'project_management',
        title: 'Project Management',
        id: 'gs3',
        status: '0',
        description: `This module helps you gain visibility of all cyber security solutions running in your organization. It further gives insights like patch status, software update status, risk score, compliance, vulnerability management, etc. for those solutions. This is the overall module that pulls together the vision and mission of an organization with appropriate security policies, vulnerability assessment guidelines, compliances and standards and helps corporate leaders understand the importance of them to work in accord with them.`,
      },
    ];
    this.multistep = new FormGroup({});
    // this.getCustomerInfo();
  }

  toolsDescription = [
    {
      name: 'siem',
      description: 'SIEM solutions allow organizations to efficiently collect and analyse log data from all of their digital assets in one place.This gives them the ability to recreate past incidents or analyse new ones to investigate suspicious activity and implement more effective security processes.'
    },
    {
      name: 'soar',
      description: 'SOAR is a security orchestration, automation and response solution that allows organizations to collect and aggregate data from multiple security tools and devices, and then use that data to automate response processes.'
    },
    {
      name: 'case-management',
      description: 'Case management solutions are used to manage and track incidents, investigations, and other security-related events. They help organizations to manage and track security incidents, investigations, and other security-related events.'
    },
    {
      name: 'threat-intel',
      description: 'Threat intelligence solutions help organizations to gather, analyse, and understand information about potential or current threats that could impact their organization. This information is then used to make informed decisions and implement effective security measures.'
    },
    {
      name: 'network-deception',
      description: 'Network deception solutions are used to detect and respond to threats that have bypassed traditional security measures. They do this by creating decoy systems and data to lure attackers into revealing themselves.'
    },
    {
      name: 'patch-management',
      description: 'Patch management solutions help organizations to keep their systems and software up to date and secure by identifying, deploying, and verifying the installation of patches.'
    },
    {
      name: 'vulnerability-management',
      description: 'Vulnerability management solutions help organizations to identify, classify, and remediate security vulnerabilities in their systems and software. This helps to reduce the risk of a security breach.'
    },
    {
      name: 'bas',
      description: 'Breach and attack simulation solutions help organizations to identify and remediate security vulnerabilities in their systems and software by simulating real-world attacks.'
    },
    {
      name: 'gsos',
      description: 'Compliance platform solutions help organizations to manage and track compliance requirements and activities across their organization. This helps to ensure that the organization is meeting its legal and regulatory obligations.'
    },
    {
      name: 'enterprise_risk_management',
      description: 'Enterprise risk management solutions help organizations to identify, assess, and mitigate risks to their business. This helps to ensure that the organization is able to achieve its objectives and protect its assets.'
    },
    {
      name: 'project_management',
      description: 'Project management solutions help organizations to plan, execute, and track projects across their organization. This helps to ensure that projects are completed on time and within budget.'
    }
  ];

  ngOnInit() {


    this.spinner.show()
    this.apiService.getUser({email:this.local.getData("email")}).subscribe(
      (res: any)=>{
        this.spinner.hide()
        this.customer = res?.body?.data?.user
        console.log("this customer----------",this.customer)
        //console.log(this.customer)
        this.cloud_platform = res?.body?.data?.user?.cloud_type
        this.showTools = res?.body?.data?.tool.map((obj: any) => (
          {
            name: obj.name,
            user_tool_id: obj.user_tool_id,
            tool_id: obj.tool_id,
            checked:obj.launch
          }
        ));
        this.tools = res?.body?.data?.tool
        this.oxdrAvailable = this.tools.some(t => t.name === 'OXDR');
      if(res?.data.tool.map((obj: any) => obj.name) == 'gsos') {
        this.showAllTools
      }


      if (res?.data && res?.data?.tool) {

        let tools = res?.data?.tool.map((obj: any) => obj.name);
        console.log(tools)

        for (let xdr of this.xdrComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
        for (let xdr of this.xdrPlusComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
        for (let xdr of this.oxdrComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
        for (let xdr of this.gsosComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
      }
      },
      (err:any)=>{
        console.log("Err",err);
        this.spinner.hide()
      }
    );
  }


  next() {
    // console.log(this.navName);
    this.apiService.setName(this.navName);
    // console.log(this.imageUrl);
    this.apiService.setImageUrl(this.imageUrl);
    const launchEvent = localStorage.getItem('launch');
    // console.log(launchEvent);
    if (launchEvent == 'true') {
      this.router.navigate(['user/dashboard']);
    } else {
      this.submitted = true;
      this.step = this.step + 1;
      this.submitted = false;
    }
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  updateArray(event: any, tool: any) {
    const toolObject = {
      user_tool_id: tool.user_tool_id,
      tool_id: tool.tool_id,
      name:tool.name
    };
  
    if (event.target.checked) {
      if (!this.itemsArray.some(t => t.tool_id === toolObject.tool_id)) {
        this.itemsArray.push(toolObject);
      }
    } else {
      this.itemsArray = this.itemsArray.filter(t => t.tool_id !== toolObject.tool_id);
    }
  }

  pass() {

    const requestBody = {
      user_id:this.customer.user_id,
      tools: this.itemsArray,
    };
    this.spinner.show();

    this.apiService.launchStack(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      // console.log(response)
      const { statusCode, message } = JSON.parse(response.body);
      if (statusCode == 200) {
        this.router.navigate(['user/dashboard']).then(() => {
          this.toast.showSuccess('Tools Spinning in progress.');
        });
      } else {
        if (statusCode == 400) {
          this.toast.showError('Request not send.', 'Error');
        } else {
          this.toast.showError(message, 'Error');
        };
      };
    });

    this.modalService.dismissAll();
    // this.router.navigate(['user/dashboard']);
  }

  redirectToCloudFormation() {
    console.log('Redirecting to AWS CloudFormation console...');
     // Replace 'YOUR_S3_URL_HERE' with the desired Amazon S3 URL
     var s3Url = encodeURIComponent('https://invinsense-package.s3.ap-south-1.amazonaws.com/5-step/Invinsense-Cloudformation-template/Invinsense-bring-your-own-cloud.yml');

     // Constructing the URL for redirection
     var redirectUrl = `https://ap-south-1.console.aws.amazon.com/cloudformation/home?region=ap-south-1#/stacks/create?templateURL=${s3Url}`;

     this.clicked = true;

     // Redirecting to the AWS CloudFormation console
    //  window.location.href = redirectUrl;
    window.open(redirectUrl, '_blank');
  }

  prev() {
    this.step = this.step - 1;
  }
  submit() {
    this.step = 1;
  }
  getCustomerInfo() {
    const requestBody = {
      email: this.user.getEmail(),
      authtoken: this.user.getToken(),
    };
    // this.spinner.show();
    this.apiService.getUserProfile(requestBody).subscribe((response: any) => {
      this.spinner.hide();
      // console.log(response);
      this.navName = response.data.name;
      this.profileData = response.data;
      this.imageUrl = this.user.image(this.profileData);
      localStorage.setItem('launch', response.data.launch);
      const { data, statusCode, message } = response;
      this.customer = data;
      this.cloud_platform = data.cloud_platform;

      // console.log(data.tools.map((obj: any) => obj.name));
      this.showTools = data.tool.map((obj: any) => obj.name);
      // console.log(this.showTools);
      // console.log(this.showTools);

      if(data.tool.map((obj: any) => obj.name) == 'gsos') {
        this.showAllTools
      }


      if (data && data.tool) {

        let tools = data.tool.map((obj: any) => obj.name);

        for (let xdr of this.xdrComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
        for (let xdr of this.xdrPlusComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
        for (let xdr of this.oxdrComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
        for (let xdr of this.gsosComponents) {
          if (tools.includes(xdr.name)) {
            xdr.status = '1';
          }
        }
      }

      if (statusCode == 200) {
        this.toast.showSuccess('Please verify you account details.');
      } else {
        if (statusCode == 400) {
          this.toast.showError('Request not send.', 'Error');
          this.spinner.hide()
        } else {
          this.toast.showError(message, 'Error');
          this.spinner.hide()
        }
      }
    });
  }

  // launchStack() {
  //   const requestBody = {
  //     email: this.user.getEmail(),
  //     authtoken: this.user.getToken(),
  //   };
  //   this.spinner.show();
  //   this.apiService.launchStack(requestBody).subscribe((response: any) => {
  //     this.spinner.hide();
  //     const { statusCode, message } = response;
  //     if (statusCode == 200) {
  //       this.router.navigate(['thank-you']).then(() => {
  //         this.toast.showSuccess(
  //           'We are launching your stack, please wait. You will receive an email with solution details and login credentials.',
  //         );
  //       });
  //     } else {
  //       if (statusCode == 400) {
  //         this.toast.showError('Request not send.', 'Error');
  //       } else {
  //         this.toast.showError(message, 'Error');
  //       }
  //     }
  //   });
  // }
}
