<section
  class="login_page_main"
  style="
    background-image: url(../../../assets/images/landing/hero-1-bg-img.png);
  "
>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 p-5 d-none d-sm-block">
        <div class="left_img">
          <h1>
            Get Yourself ready against <br />
            Cyber Problems
          </h1>
          <img src="assets/login.png" />
        </div>
      </div>
      <div class="col-lg-6 p-3">
        <div class="right_form_main">
          <div class="form_top_text">
            <div>
              <h2>Forgot Key for your Invinsense account</h2>
            </div>
          </div>
          <div class="right_form_inside">
            <h3 class="form_tital">Forgot Key</h3>
            <form
              [formGroup]="loginForm"
              id="msform"
              autocomplete="off"
              class="needs-validation"
              novalidate
            >
              <div class="form-group formfield">
                <input
                  type="text"
                  formControlName="email"
                  name=""
                  id="email"
                  required
                  value=""
                  autocomplete="off"
                  pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                  class="form-control"
                />
                <label for="email" placeholder="">Email</label>
              </div>
              <div *ngIf="environment.enableRecaptcha">
                <div class="form-group">
                    <ngx-recaptcha2 #captchaElem
                        [siteKey]="environment.recaptcha.siteKey"
                        (reset)="handleReset()"
                        (expire)="handleExpire()"
                        (load)="handleLoad()"
                        (success)="handleSuccess($event)"
                        formControlName="recaptcha">
                    </ngx-recaptcha2>
                </div>
            </div>
              <hr />
              <div>
                <button
                (click)="forgot()"
                  type="submit"
                  data-test-id="login_in"
                  class="btn btn-primary"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
