import { Component , OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';

import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastService } from 'src/app/shared/toast.service';
import { ApiService } from 'src/app/shared/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalService } from 'src/app/shared/local.service';
import { UserService } from 'src/app/shared/user.service';
import { CognitoService } from 'src/app/cognito.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit{
    imageUrl = "";
    isNotLoggedIn = false;
    profileData: any = {};
    name = '';
    firstCh = " ";
  userDetails: any;

    constructor(public sidebarservice: SidebarService, private router: Router, private auth: AuthService, private apiService: ApiService, private spinner: NgxSpinnerService, private toast: ToastService, private local: LocalService, private user: UserService, private cognitoService: CognitoService) { }
        
    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    }
    
    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }

    logout(){
      this.cognitoService.signOut().then((res)=>{
        
      })
      localStorage.clear();
    }

    // logout() {
    //     // const token = JSON.parse(this.local.getData('authtoken'));
    //     const token = this.user.getToken();
    //     const reqBody = {
    //       email: this.local.getData('email'),
    //       token : this.user.getToken(),
    //     }
    
    //     try {
    //       this.spinner.show();
    //       this.apiService.globalLogout(reqBody).subscribe((response: any) => {
    //         this.auth.logout();
    //         this.spinner.hide();
    //         this.router.navigate(['/admin']); // the route will redirect to admin portal 
    //       });
    //     } catch (err: any) {
    //       this.spinner.hide();
    //       this.toast.showError(err, "Error");
    //     }
    //   }

      // profile() {
      //   // const token = JSON.parse(this.local.getData('authtoken'));
      //   const token = this.user.getToken();
      //   const requestBody = {
      //     email: this.local.getData('email'),
      //     authtoken: token
      //   }
      //   this.spinner.show();
      //   this.apiService.getUserProfile(requestBody).subscribe((response: any) => {
      //     this.spinner.hide();
      //     const {data, statusCode} = response;
      //     if(data && statusCode == 200){
      //       this.profileData = data;
      //       this.firstCh = this.profileData.name.charAt(0);
      //       this.imageUrl = this.user.image(this.profileData);
      //     } else if(statusCode == 400){
      //       this.toast.showError('Session expired, please login again', 'Error');
      //       this.router.navigate(['/login']);
      //     }
      //   },
      //   (error: any) => {
      //     // Handle connection failure or API changes
      //     this.toast.showError("Failed to connect to server", "Error");
      //     this.spinner.hide();
      //   });
      // }

    ngOnInit() {
      setTimeout(() => {
        this.loadUserDetails()
      }, 2000);
      // this.profile();
        // logout api calling here to bulona admin portal. not global logout.
        this.isNotLoggedIn = this.auth.isNotLoggedIn();

        /* Search Bar */
        $(document).ready(function () {
            $(".mobile-search-icon").on("click", function () {
                $(".search-bar").addClass("full-search-bar")
            }), 
            $(".search-close").on("click", function () {
                $(".search-bar").removeClass("full-search-bar")
            })
        });
        


    }

    
    loadUserDetails() {
      const user = this.local.getData('user');
      this.userDetails = user ? JSON.parse(user) : null;
    }
}
