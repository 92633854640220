<section class="login_page_main" style="background-image: url(../../../assets/images/landing/hero-1-bg-img.png)">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-5 d-none d-sm-block">
                <div class="left_img">
                    <h1>Explore Interesting <br> Cyber Projects</h1>
                    <img src="assets/login.png">
                </div>
            </div>
            <div class="col-lg-6 p-3">
                <div class="right_form_main">
                    <div class="form_top_text">
                        <div>
                            <h2>Verify your <br>Invinsource account</h2>
                        </div>
                    </div>
                    <div class="right_form_inside">
                        <h3 class="form_tital">Verification</h3>
                        <form [formGroup]="verificationForm" id="msform" autocomplete="off" class="needs-validation" novalidate>
                            <!-- <div class="form-group formfield">
                                <input type="text" formControlName="email" name="email" id="email" required value=""
                                    autocomplete="off" class="form-control">
                                <label for="email" placeholder="Email">Email</label>
                                <div *ngIf="verificationForm.get('email')?.invalid && (verificationForm.get('email')?.dirty || verificationForm.get('email')?.touched)" class="invalid-feedback">
                                    <div *ngIf="verificationForm.get('email')?.errors?.['required']">
                                        Email is required.
                                      </div>
                                </div>
                            </div> -->

                            <div class="form-group formfield">
                                <input type="text" formControlName="code" name="" id="code" required
                                    value="" autocomplete="off" class="form-control">
                                <label for="code" placeholder="OTP">OTP</label>
                                <div *ngIf="verificationForm.get('code')?.invalid && (verificationForm.get('code')?.dirty || verificationForm.get('code')?.touched)" class="invalid-feedback">
                                    <div *ngIf="verificationForm.get('code')?.errors?.['required']">
                                        OTP is required.
                                      </div>
                                </div>
                            </div>
                            <hr>
                            <div>
                            <div class="row">
                                <!-- <div class="col-3"><button (click)="resendConfirmation()" type="submit" class="btn btn-primary">Resend OTP</button></div> -->
                                <div class="col"><button (click)="verification()" [disabled]="verificationForm.invalid" type="submit" class="btn btn-primary">Submit</button></div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>