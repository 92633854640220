<!-- <footer class="">
	<main class="container">
		<div class="row text-center">

			<div class="col-lg-12 align-self-center p-3">
				<h6>© Copyright Invinsense. {{year}}. All rights reserved.</h6>
			</div>
		
		</div>


	</main>
</footer> -->
<!--Start Back To Top Button--> 
<a href="#" (click)="$event.preventDefault()" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
<!--End Back To Top Button-->
<!--footer -->
<footer class="page-footer">
	<p class="mb-0">© Copyright Invinsense {{year}}. All right reserved.</p>
</footer>
<!-- end footer -->