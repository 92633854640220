import { Component } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss','../../../assets/css/pe-icon-7.css','../../../assets/css/owl.theme.default.min.css','../../../assets/css/owl.carousel.min.css','../../../assets/css/style.min.css','../../../assets/sass/scss/landing/_menu.scss','../../../assets/sass/scss/landing/_variables.scss']
})
export class LandingComponent {

}
