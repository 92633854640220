import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalService } from 'src/app/shared/local.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userDataSubject = new BehaviorSubject<any>(null);
  public userData$: Observable<any> = this.userDataSubject.asObservable(); 

  public setUserData(data: any): void {
    this.userDataSubject.next(data);
  }

  public getUserData(): any {
    return this.userDataSubject.getValue(); 
  }

  mobile_number(mobile_number: any) {
    throw new Error('Method not implemented.');
  }
  name(name: any) {
    throw new Error('Method not implemented.');
  }
  profileData: any = {};
  imageUrl = "";
  constructor(private local: LocalService) { }

  public getEmail(){
    return this.local.getData('email');
  }
  public getToken(){
    return this.local.getData('authtoken');
  }

  public image(profileData: any){
    if(profileData.image){
      this.imageUrl = profileData.image;
      const base64Data = profileData.image;
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpg' });

      // saveAs(blob, "profile-pic.jpg");

      this.imageUrl = URL.createObjectURL(blob);
      return this.imageUrl;

    } else {
      this.imageUrl = "";
      return this.imageUrl;
    }
  }


}
