<div class="card radius-15">
	<div class="card-body">
		<div class="card-title">
			<h4 class="mb-0">Download Documents</h4>
		</div>
		<hr/>
        <h5>Resume</h5>
        <div class="col">
        <button type="button" (click)="download_resume()" class="btn btn-outline-primary px-5 radius-30 m-3 fs-5"><i class='bx bx-cloud-download mr-1 fs-2'></i>Download</button>
        </div>
        <h5>Aadhaar Card</h5>
        <div class="col">
        <button type="button" (click)="download_aadhaar()" class="btn btn-outline-primary px-5 radius-30 m-3 fs-5"><i class='bx bx-cloud-download mr-1 fs-2'></i>Download</button>
        </div>
        <h5>Pancard</h5>
        <div class="col">
        <button type="button" (click)="download_pan()" class="btn btn-outline-primary px-5 radius-30 m-3 fs-5"><i class='bx bx-cloud-download mr-1 fs-2'></i>Download</button>
        </div>
        <h5>Driving Licence</h5>
        <div class="col">
        <button type="button" (click)="download_driving()" class="btn btn-outline-primary px-5 radius-30 m-3 fs-5"><i class='bx bx-cloud-download mr-1 fs-2'></i>Download</button>
        </div>
	</div>
</div>


<!-- 
<div class="col">
    <button type="button" class="btn btn-outline-primary px-5 radius-30 m-3 fs-3"><i class='bx bx-cloud-download mr-1 fs-3'></i>Downloads</button>
</div> -->
