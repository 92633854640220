<section class="login_page_main" >
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <!-- <div class="col-lg-6 p-5 d-none d-sm-block">
                <div class="left_img">
                    <h1>Explore Interesting <br> Cyber Projects</h1>
                    <img src="assets/login.png">
                </div>
            </div> -->
            <div class="col-lg-6 p-3">
                <div class="right_form_main">
                    <div class="form_top_text">
                        <div>
                            <h2>Update password to continue with <br>Invinsource account</h2>
                        </div>
                    </div>
                    <div class="right_form_inside">
                        <h3 class="form_tital">Update Password</h3>
                        <form [formGroup]="resetPasswordForm" id="msform" autocomplete="off" class="needs-validation" novalidate>
                            <!-- <div class="form-group formfield">
                                <input type="text" formControlName="code" name="" id="code" required value=""
                                    autocomplete="off" class="form-control" placeholder=" ">
                                <label for="code" placeholder="">OTP</label>
                                <div *ngIf="resetPasswordForm.get('code')?.invalid && (resetPasswordForm.get('code')?.dirty || resetPasswordForm.get('code')?.touched)" class="invalid-feedback">
                                    <div *ngIf="resetPasswordForm.get('code')?.errors?.['required']">
                                        OTP is required.
                                      </div>
                                </div>
                            </div> -->

                            <div class="form-group formfield">
                                <input type="password" formControlName="newPassword" name="" id="newpassword" required
                                    value="" autocomplete="off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" class="form-control" placeholder=" ">
                                <label for="newpassword" placeholder="">Old Password</label>
                                <div *ngIf="resetPasswordForm.get('newPassword')?.invalid && (resetPasswordForm.get('newPassword')?.dirty || resetPasswordForm.get('newPassword')?.touched)" class="invalid-feedback">
                                    <div *ngIf="resetPasswordForm.get('newPassword')?.errors?.['required']">
                                        Password is required.
                                      </div>
                                    <div *ngIf="resetPasswordForm.get('newPassword')?.errors?.['pattern']">
                                        Password should have minimum 8 characters, 1 Uppercase, 1 lowercase, 1 special character, 1 number.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group formfield">
                                <input type="password" formControlName="confirmPassword" name="" id="confirmpassword"
                                    required value="" autocomplete="off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" class="form-control" placeholder=" ">
                                <label for="confirmpassword" placeholder="">New Password</label>
                                <div *ngIf="resetPasswordForm.get('confirmPassword')?.invalid && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched)" class="invalid-feedback">
                                    <div *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']">
                                        Confirm password is required.
                                      </div>
                                    <div *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['pattern']">
                                        Password should should be same.
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <button  type="submit" (click)="updatePassword()" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>