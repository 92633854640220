<!-- wrapper -->
<div class="wrapper">
	<nav class="navbar navbar-expand-lg navbar-light bg-white rounded fixed-top rounded-0 shadow-sm">
		<div class="container-fluid">
			<a class="navbar-brand ms-1" href="#">
				<img src="assets/images/Invinsource_logo-01-dark.png" width="200" alt="" />
			</a>
		</div>
	</nav>
	<div class="error-404 d-flex align-items-center justify-content-center">
		<div class="container">
			<div class="card">
				<div class="row g-0">
					<div class="col-xl-5">
						<div class="card-body p-4">
							<h1 class="display-1"><span class="text-warning">5</span><span class="text-danger">0</span><span class="text-primary">0</span></h1>
							<h2 class="font-weight-bold display-4">Sorry, unexpected error</h2>
							<p>Looks like you are lost!
								<br>May be you are not connected to the internet!</p>
							<div class="mt-5">	<a href="#" class="btn btn-lg btn-primary px-md-5 radius-30">Go Home</a>
							</div>
						</div>
					</div>
					<div class="col-xl-7">
						<img src="assets/images/errors-images/505-error.png" class="img-fluid" alt="">
					</div>
				</div>
				<!--end row-->
			</div>
		</div>
	</div>
	<div class="row bg-white p-3 fixed-bottom border-top shadow">
		<div class="col-12">
			<!-- <ul class="list-inline">
			</ul> -->
			<p class="mb-0 text-center">© Copyright Invinsense 2024. All right reserved.</p>
		</div>
	</div>
</div>
<!-- end wrapper -->
