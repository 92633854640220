<div class="col-md-9">
  <!-- <div class="card mb-3"> -->
  <div class="card-body">
    <!-- <div class="row"> -->
    <div *ngIf="is_deploy===true" class="col-12">
      <div class="col" style="display: flex">
        <h6
          style="margin-right: 20px; align-content: flex-end"
          class="text-uppercase row"
        >
          Tools
        </h6>
        <!-- <button (click)="reloadTools()" class="btn btn-primary">
          <i class="bi bi-arrow-clockwise row"></i>
        </button> -->
        <hr />
      </div>
      <hr />
      <!-- <div class="card"> -->
      <div class="card-body">
        <ul class="nav nav-tabs nav-primary" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#personal"
              role="tab"
              aria-selected="true"
            >
              <div class="d-flex align-items-center">
                <div class="tab-icon">
                  <i class="bx bx-user-pin font-18 me-1"></i>
                </div>
                <div class="tab-title">Subscription</div>
              </div>
            </a>
          </li>
          <!-- <li class="nav-item" role="presentation"> <a data-test-id="subscription" class="nav-link" data-bs-toggle="tab" href="#subscription" role="tab" aria-selected="false">
                          <div class="d-flex align-items-center">
                              <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i> </div>
                              <div class="tab-title">Subscription</div>
                          </div>
                      </a> </li> -->
          <!-- <li class="nav-item" role="presentation"> <a data-test-id="domain" class="nav-link" data-bs-toggle="tab" href="#domain" role="tab" aria-selected="false">
                          <div class="d-flex align-items-center">
                              <div class="tab-icon"><i class='bx bx-trophy font-18 me-1'></i> </div>
                              <div class="tab-title">Solutions</div>
                          </div>
                      </a> </li> -->
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="personal" role="tabpanel">
            <div class="rounded-3 mt-3">
              <table>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        [(ngModel)]="selectAllChecked"
                        (change)="toggleSelectAll()"
                      />
                    </th>
                    <th>Tool Name</th>
                    <th>Tool Status</th>
                    <th>Action</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody *ngFor="let item of modifyTableData">
                  <tr>
                    <!-- <tr *ngFor="let key of toolsArr"> -->
                    <!-- <td *ngIf="item.name === key.name"> -->
                      <td>
                        <input
                        type="checkbox"
                        [disabled]="item.status"
                        [(ngModel)]="item.selected"
                        (change)="checkboxChecked()"
                      />
                      </td>
                    <!-- </td>
                    <td *ngIf="item.name === key.name"> -->
                      <td>
                        <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        
                      >
                        {{ item.name }}</span
                      >
                      </td>
                    <!-- </td>
                    <td *ngIf="item.name === key.name"> -->
                      <td>
                        <!-- <span
                        *ngIf="item.status; else noStatus"
                        [ngClass]="{
                          'status-dot-green': item.status === '200',
                          'status-dot-red': item.status === '400'
                        }"
                        class="status-dot"
                      >
                        <span *ngIf="item.status === '0'">Destroyed</span>
                      </span> -->

                      <span
                        *ngIf="is_deploy===true; else noStatus"
                      >
                        DEPLOYED
                      </span>

                      <ng-template #noStatus>
                        <span>No Status</span>
                      </ng-template>
                      </td>
                    <!-- </td> -->
                    <!-- <td *ngIf="item.name === key.name"> -->
                      <td>
                        <a [href]="'https://' + organization + '.mg.invinsense.io'" class="action_btn" target="_blank" rel="noopener noreferrer">View</a>
                      </td>
                      <!-- <input type="button" class="action_btn" value="View" /> -->
                    <!-- </td> -->
                    <!-- <td>
                                      <button type="button" [disabled]="item.status" [ngClass]="{'button-disabled': item.status, 'button-enabled': !item.status}" (click)="submit()">Launch</button>
                                    </td> -->
                  <!-- </tr> -->
                  </tr>
                </tbody>
              </table>
              <!-- <button type="button" style="margin-top: 10px;" [ngClass]="activeButton.length > 0 ? 'buttonActive' : 'button-disabled'" (click)="submit()">Launch</button> -->
              <!-- <button type="button" style="margin-top: 10px;" [ngClass]="activeButton?.length > 0 ? 'button-enabled' : 'button-disabled'" (click)="submit()">Launch</button> -->

              <div
              class="col-sm-9 text-secondary"
                [ngClass]="
                  activeButton?.length > 0
                    ? 'col-sm-9 text-secondary mt-2'
                    : 'col-sm-9 text-secondary mt-2'
                "
              >
                <input
                  type="button"
                  [ngClass]="
                    activeButton?.length > 0
                      ? 'btn btn-primary px-4'
                      : 'btn btn-primary px-4 disabled'
                  "
                  (click)="submit()"
                  class="btn btn-primary px-4"
                  value="Launch"
                />
              </div>
              <!-- <div *ngIf="message" class="message-container">
                {{ message?.status }}
              </div> -->
            </div>

            <div class="tab-pane fade" id="subscription" role="tabpanel"></div>

            <div class="tab-pane fade" id="domain" role="tabpanel"></div>
          </div>
        </div>
      </div>
      <!-- <div (click)="logout()">
        LOGOUT
      </div> -->
    </div>

    <div *ngIf="is_deploy===false">
      <div *ngIf="percentage===100" class="col-12">
        <div class="col" style="display: flex">
          <h6
            style="margin-right: 20px; align-content: flex-end"
            class="text-uppercase row"
          >
            Tools
          </h6>
          <!-- <button (click)="reloadTools()" class="btn btn-primary">
            <i class="bi bi-arrow-clockwise row"></i>
          </button> -->
          <hr />
        </div>
        <hr />
        <!-- <div class="card"> -->
        <div class="card-body">
          <ul class="nav nav-tabs nav-primary" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#personal"
                role="tab"
                aria-selected="true"
              >
                <div class="d-flex align-items-center">
                  <div class="tab-icon">
                    <i class="bx bx-user-pin font-18 me-1"></i>
                  </div>
                  <div class="tab-title">Subscription</div>
                </div>
              </a>
            </li>
            <!-- <li class="nav-item" role="presentation"> <a data-test-id="subscription" class="nav-link" data-bs-toggle="tab" href="#subscription" role="tab" aria-selected="false">
                            <div class="d-flex align-items-center">
                                <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i> </div>
                                <div class="tab-title">Subscription</div>
                            </div>
                        </a> </li> -->
            <!-- <li class="nav-item" role="presentation"> <a data-test-id="domain" class="nav-link" data-bs-toggle="tab" href="#domain" role="tab" aria-selected="false">
                            <div class="d-flex align-items-center">
                                <div class="tab-icon"><i class='bx bx-trophy font-18 me-1'></i> </div>
                                <div class="tab-title">Solutions</div>
                            </div>
                        </a> </li> -->
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="personal" role="tabpanel">
              <div class="rounded-3 mt-3">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          [(ngModel)]="selectAllChecked"
                          (change)="toggleSelectAll()"
                        />
                      </th>
                      <th>Tool Name</th>
                      <th>Tool Status</th>
                      <th>Action</th>
                      <!-- <th>Action</th> -->
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of modifyTableData">
                    <tr>
                      <!-- <tr *ngFor="let key of toolsArr"> -->
                      <!-- <td *ngIf="item.name === key.name"> -->
                        <td>
                          <input
                          type="checkbox"
                          [disabled]="item.status"
                          [(ngModel)]="item.selected"
                          (change)="checkboxChecked()"
                        />
                        </td>
                      <!-- </td>
                      <td *ngIf="item.name === key.name"> -->
                        <td>
                          <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          
                        >
                          {{ item.name }}</span
                        >
                        </td>
                      <!-- </td>
                      <td *ngIf="item.name === key.name"> -->
                        <td>
                          <!-- <span
                          *ngIf="item.status; else noStatus"
                          [ngClass]="{
                            'status-dot-green': item.status === '200',
                            'status-dot-red': item.status === '400'
                          }"
                          class="status-dot"
                        >
                          <span *ngIf="item.status === '0'">Destroyed</span>
                        </span> -->
  
                        <span
                        >
                          DEPLOYED
                        </span>
  
                        </td>
                      <!-- </td> -->
                      <!-- <td *ngIf="item.name === key.name"> -->
                        <td>
                          <a [href]="'https://' + organization + '.mg.invinsense.io'" class="action_btn" target="_blank" rel="noopener noreferrer">View</a>
                        </td>
                        <!-- <input type="button" class="action_btn" value="View" /> -->
                      <!-- </td> -->
                      <!-- <td>
                                        <button type="button" [disabled]="item.status" [ngClass]="{'button-disabled': item.status, 'button-enabled': !item.status}" (click)="submit()">Launch</button>
                                      </td> -->
                    <!-- </tr> -->
                    </tr>
                  </tbody>
                </table>
                <!-- <button type="button" style="margin-top: 10px;" [ngClass]="activeButton.length > 0 ? 'buttonActive' : 'button-disabled'" (click)="submit()">Launch</button> -->
                <!-- <button type="button" style="margin-top: 10px;" [ngClass]="activeButton?.length > 0 ? 'button-enabled' : 'button-disabled'" (click)="submit()">Launch</button> -->
  
                <div
                class="col-sm-9 text-secondary"
                  [ngClass]="
                    activeButton?.length > 0
                      ? 'col-sm-9 text-secondary mt-2'
                      : 'col-sm-9 text-secondary mt-2'
                  "
                >
                  <input
                    type="button"
                    [ngClass]="
                      activeButton?.length > 0
                        ? 'btn btn-primary px-4'
                        : 'btn btn-primary px-4 disabled'
                    "
                    (click)="submit()"
                    class="btn btn-primary px-4"
                    value="Launch"
                  />
                </div>
                <!-- <div *ngIf="message" class="message-container">
                  {{ message?.status }}
                </div> -->
              </div>
  
              <div class="tab-pane fade" id="subscription" role="tabpanel"></div>
  
              <div class="tab-pane fade" id="domain" role="tabpanel"></div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="percentage!==100" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <circle-progress
            [percent]="percentage"
            [radius]="100"
            [outerStrokeWidth]="8"
            [innerStrokeWidth]="4"
            [outerStrokeColor]="'#0091ff'"
            [innerStrokeColor]="'#75c3ff'"
            [animation]="true"
            [animationDuration]="300"
          ></circle-progress>
  
          <div style="margin-top: 20px;">
            {{message?.status?.body?.current_state?.Description}}
          </div>

          <span class="loader" style="margin-top: 20px;"></span>
      </div>
    </div>

    <!-- <div *ngIf="is_deploy===false" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <circle-progress
          [percent]="percentage"
          [radius]="100"
          [outerStrokeWidth]="8"
          [innerStrokeWidth]="4"
          [outerStrokeColor]="'#0091ff'"
          [innerStrokeColor]="'#75c3ff'"
          [animation]="true"
          [animationDuration]="300"
        ></circle-progress>

        <div style="margin-top: 20px;">
          {{message?.status?.body?.current_state?.Description}}
        </div>
    </div> -->
  </div>
</div>
