import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { LocalService } from 'src/app/shared/local.service';
import { ToastService } from 'src/app/shared/toast.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent {
  maxSize: number = 5000000; 
  minSize: number = 50000;
  aadhaarSelected: boolean = false;
  panSelected: boolean = false;
  drivingSelected: boolean = false;

  base64Output_aadhaar : string="";
  base64Output_pan : string="";
  base64Output_driving : string="";

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private local: LocalService, private toast: ToastService) { }
  
  onaadhaar(event: any) {
    const file: File = event.target.files[0];
    this.aadhaarSelected = event.target.files && event.target.files.length > 0 && file.size <= this.maxSize && file.size >= this.minSize;

    const reader = new FileReader();

    reader.onload = () => {
      const fileContents = reader.result as string;
      this.base64Output_aadhaar = btoa(fileContents); // for addhar      

    };
    reader.readAsBinaryString(file);
  }

  onpan(event: any) {
    const file: File = event.target.files[0];
    this.panSelected = event.target.files && event.target.files.length > 0 && file.size <= this.maxSize && file.size >= this.minSize;

    const reader = new FileReader();

    reader.onload = () => {
      const fileContents = reader.result as string;
      this.base64Output_pan = btoa(fileContents); // for pan

    };
    reader.readAsBinaryString(file);
  }

  ondriving(event: any) {
    const file: File = event.target.files[0];
    this.drivingSelected = event.target.files && event.target.files.length > 0 && file.size <= this.maxSize && file.size >= this.minSize;
    const reader = new FileReader();

    reader.onload = () => {
      const fileContents = reader.result as string;
      this.base64Output_driving = btoa(fileContents); // for driving
      

    };
    reader.readAsBinaryString(file);
  }

  isAnyDocumentSelected(): boolean {
    return this.aadhaarSelected || this.panSelected || this.drivingSelected ;
  }
  

  importDocument(){
    const requestBody : any = { document: {}};

    if(this.base64Output_aadhaar){
      requestBody.document.aadhaar = this.base64Output_aadhaar;
    }
    if(this.base64Output_pan){
      requestBody.document.pan = this.base64Output_pan;
    }
    if(this.base64Output_driving){
      requestBody.document.driving = this.base64Output_driving;
    }
  
    this.spinner.show();
    this.apiService.documentUpload(requestBody).subscribe((response: any)=>{
      this.spinner.hide();
      const {statusCode, message} = response;
      if (statusCode == 200) {
        this.toast.showSuccess("Document Uploaded");
        this.base64Output_driving = "";
        this.base64Output_pan= "";
        this.base64Output_aadhaar= "";
      }
      else {
        if(statusCode == 400){
          this.toast.showError("Request not send.","Error");
        } 
        else {
            this.toast.showError(message,"Error");
        }
      }
    },
    (error: any) => {
      // Handle connection failure or API changes
      this.toast.showError("Failed to connect to server", "Error");
      this.spinner.hide();
    })
    this.aadhaarSelected = false;
    this.panSelected = false;
    this.drivingSelected = false;
  }
}
