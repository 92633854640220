<div class="wrapper" [ngClass]="{'nav-collapsed' : getSideBarState()}">
    <app-sidebar></app-sidebar>
    <app-navbar></app-navbar>
    <div class="page-wrapper">
		<div class="page-content-wrapper">
			<div class="page-content">
                <router-outlet></router-outlet>
                <div class="overlay" (click)="toggleSidebar()" [ngClass]="{'show' : getSideBarState()}">
                </div>

                <div class="modal fade" id="changePassword" tabindex="-1" aria-hidden="true" [class.show]="showModal">
                    <div class="modal-dialog modal-dialog-centered ">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Password Change</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <form  [formGroup]="changepasswordForm" class="needs-validation row g-3" novalidate>
                                <div class="">
                                  <label for="inputOldPassword" class="form-label">Enter Old Password</label>
                                  <div class="input-group input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-lock'></i></span>
                                    <input type="password" required require formControlName="pre_password" class="form-control border-start-0" id="pre_password" placeholder="Enter old Password"/>
                                  </div>
                                </div>
                                <div class="">
                                  <label for="inputNewPassword" class="form-label">Enter new Password</label>
                                  <div class="input-group input-group"> 
                                    <span class="input-group-text bg-transparent"><i class='bx bxs-lock-open'></i></span>
                                    <input type="password" required require formControlName="new_password" class="form-control border-start-0" id="new_password" placeholder="Enter new Password" 
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" name="pwd1" />
                        
                                    <!-- <app-password-strength-meter [passwordToCheck]="changepasswordForm.get('new_password')?.value" (passwordStrength)="onPasswordStrengthChanged($event)"></app-password-strength-meter> -->
                                      <div class="invalid-feedback">
                                        <span >Password is required</span>
                                        <span >Password must be at least...</span>
                                      </div>
                                  </div>
                                  <app-password-strength-meter [passwordToCheck]="changepasswordForm.get('new_password')?.value" (passwordStrength)="onPasswordStrengthChanged($event)"></app-password-strength-meter>

                                </div>
                                <div class="">
                                  <label for="inputConfirmPassword" class="form-label">Enter Confirm Password</label>
                                  <div class="input-group input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-lock-open'></i></span>
                                    <input type="password" required require formControlName="confirm_password" class="form-control border-start-0" id="inputConfirmPassword" placeholder="Enter confirm Password" 
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" name="pwd2"/>
                                  </div>
                                </div>
                              </form>
                            </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button (click)="changepassword()" [disabled]="changepasswordForm.invalid" type="button" data-bs-dismiss="modal" class="btn btn-primary">Save </button>
                              </div>
                          </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
    <app-footer></app-footer>
</div>