<div class="container-fluid">
  <div class="main-body">
      <!-- Breadcrumb -->
      <!-- <nav aria-label="breadcrumb" class="main-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">User</a></li>
            <li class="breadcrumb-item active" aria-current="page">User Profile</li>
          </ol>
        </nav> -->
      <!-- /Breadcrumb -->
      <div class="row gutters-sm">
          <div class="col-md-3 mb-3">
              <div class="card rounded-3">
                  <div class="card-body">
                      <div class="d-flex flex-column align-items-center text-center position-relative">
                          <div>
                            <img src="https://api.dicebear.com/9.x/initials/svg?seed={{userDetails.name}}" alt="User Image" class="rounded-circle" width="150" height="150"/>
                            <!-- <div id="profileImage" *ngIf="!imageUrl">{{firstCh}}</div> -->
                            <!-- <a type="button" class="image btn btn-primary position-absolute end-0 top-50 align-items-center" style="width: 40px; height: 40px; padding: 0; border-radius: 50%; justify-content: center; display: flex" data-bs-toggle="modal" data-bs-target="#uploadImage">
                                <i class="bx bx-image-add"></i></a>  -->
                            </div>
                          <div class="mt-3">
                              <h4>{{userDetails.name}}</h4>
                              <p class="text-secondary mb-1">{{userDetails?.job_title}}</p> <!-- <p class="text-muted font-size-sm">Bay Area, San Francisco, CA</p> -->
                              <!-- <button class="btn btn-primary">Follow</button>
                                <button class="btn btn-outline-primary ms-2">Message</button> -->
                              <!-- Button trigger modal -->
                              <!-- <button type="button" class="btn btn-outline-primary ms-2" data-bs-toggle="modal" data-bs-target="#takeImage">Take photo</button> -->
                          </div>
                          <button class="btn btn-primary" (click)="navigateToUpdatePassword()">
                            Reset Password
                          </button>
                      </div>
                  </div>
              </div>
              <!-- <div class="card mt-3 ">
                  <div class="row mx-3 p-3">
                      <div class="col text-center"> <a href={{professionInformation.other}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{professionInformation.other}}"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline">
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="2" y1="12" x2="22" y2="12"></line>
                                  <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                              </svg> </a> </div>
                      <div class="col text-center"> <a href={{professionInformation.github}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{professionInformation.github}}"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline">
                                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                              </svg> </a> </div>
                      <div class="col text-center"> <a href={{professionInformation.linkedin}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{professionInformation.linkedin}}"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 448 512">
                                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                              </svg> </a> </div>
                      <div class="col text-center"> <a href={{professionInformation.medium}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{professionInformation.medium}}"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-medium" viewBox="0 0 16 16">
                                  <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z" />
                              </svg> </a> </div>
                  </div>
              </div> -->
              <!-- <div class="card mt-3 rounded-5">
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>  Website</h6>
                  <span class="text-secondary">{{professionInformation.other || "NA"}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>  Github</h6>
                  <span class="text-secondary">{{professionInformation.github || "NA"}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>  Linkedin</h6>
                  <span class="text-secondary">{{professionInformation.linkedin || "NA"}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-medium" viewBox="0 0 16 16"> <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z"/> </svg>  medium</h6>
                  <span class="text-secondary">{{professionInformation.medium || "NA"}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16"> <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/> </svg>  Other</h6>
                  <span class="text-secondary">{{professionInformation.other || "NA"}}</span>
                </li>
              </ul>
            </div> -->
          </div>
          <div class="col-md-9">
              <!-- <div class="card mb-3"> -->
              <div class="card-body">
                  <!-- <div class="row"> -->
                  <div class="col-12">
                      <h6 class="mb-0 text-uppercase">Customer Info</h6>
                      <hr /> <!-- <div class="card"> -->
                      <div class="card-body">
                          <ul class="nav nav-tabs nav-primary" role="tablist">
                              <li class="nav-item" role="presentation"> <a class="nav-link active" data-bs-toggle="tab" href="#personal" role="tab" aria-selected="true">
                                      <div class="d-flex align-items-center">
                                          <div class="tab-icon"><i class='bx bx-home font-18 me-1'></i> </div>
                                          <div class="tab-title">Personal</div>
                                      </div>
                                  </a> </li>
                                  <li class="nav-item" role="presentation"> <a data-test-id="subscription" class="nav-link" data-bs-toggle="tab" href="#subscription" role="tab" aria-selected="false">
                                    <div class="d-flex align-items-center">
                                        <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i> </div>
                                        <div class="tab-title">Subscription</div>
                                    </div>
                                </a> </li>
                                <!-- <li class="nav-item" role="presentation"> <a data-test-id="domain" class="nav-link" data-bs-toggle="tab" href="#domain" role="tab" aria-selected="false">
                                    <div class="d-flex align-items-center">
                                        <div class="tab-icon"><i class='bx bx-trophy font-18 me-1'></i> </div>
                                        <div class="tab-title">Solutions</div>
                                    </div>
                                </a> </li> -->
                              <!-- <li class="nav-item" role="presentation"> <a data-test-id="professional" class="nav-link" data-bs-toggle="tab" href="#professional" role="tab" aria-selected="false">
                                      <div class="d-flex align-items-center">
                                          <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i> </div>
                                          <div class="tab-title">Professional</div>
                                      </div>
                                  </a> </li>
                              <li class="nav-item" role="presentation"> <a data-test-id="geographical" class="nav-link" data-bs-toggle="tab" href="#geographic" role="tab" aria-selected="false">
                                      <div class="d-flex align-items-center">
                                          <div class="tab-icon"><i class='bx bx-map-pin font-18 me-1'></i> </div>
                                          <div class="tab-title">Geographical</div>
                                      </div>
                                  </a> </li>
                              <li class="nav-item" role="presentation"> <a data-test-id="education" class="nav-link" data-bs-toggle="tab" href="#education" role="tab" aria-selected="false">
                                      <div class="d-flex align-items-center">
                                          <div class="tab-icon"><i class='bx bx-trophy font-18 me-1'></i> </div>
                                          <div class="tab-title">Education</div>
                                      </div>
                                  </a> </li>
                              <li class="nav-item" role="presentation"> <a data-test-id="experience" class="nav-link" data-bs-toggle="tab" href="#experience" role="tab" aria-selected="false">
                                      <div class="d-flex align-items-center">
                                          <div class="tab-icon"><i class='bx bx-run font-18 me-1'></i> </div>
                                          <div class="tab-title">Experience</div>
                                      </div>
                                  </a> </li>
                              <li class="nav-item" role="presentation"> <a data-test-id="skills" class="nav-link" data-bs-toggle="tab" href="#skills" role="tab" aria-selected="false">
                                      <div class="d-flex align-items-center">
                                          <div class="tab-icon"><i class='bx bx-trophy font-18 me-1'></i> </div>
                                          <div class="tab-title">Skills</div>
                                      </div>
                                  </a> </li> -->
                          </ul>

                          <div class="tab-content">
                              <div class="tab-pane fade show active" id="personal" role="tabpanel">
                                  <div class="card rounded-3">
                                      <div class="row ms-3">&nbsp;
                                          <!-- <div class="col  text-start">
                                      <i class="bx bx-notepad fs-3"></i>
                                  </div> -->
                                          <!-- <div class="col  text-end"> <a type="button" class="btn-info col-sm" data-bs-toggle="modal" data-bs-target="#personalInformation"><i class="bx bx-pencil font-24 me-1"></i></a> </div> -->
                                      </div>
                                      <div class="row ms-3">
                                          <div class="col-sm-3">
                                              <h6 class="mb-0">Username</h6>
                                          </div>
                                          <div class="col-sm-9 text-secondary"> {{userDetails.name || ""}} </div>
                                      </div>
                                      <hr>
                                      <div class="row ms-3">
                                          <div class="col-sm-3">
                                              <h6 class="mb-0">Email</h6>
                                          </div>
                                          <div class="col-sm-9 text-secondary"> {{userDetails.email || ""}} </div>
                                      </div>
                                      <hr>
                                      <div class="row ms-3">
                                          <div class="col-sm-3">
                                              <h6 class="mb-0">Mobile</h6>
                                          </div>
                                          <div class="col-sm-9 text-secondary"> {{userDetails.mobile || ""}} </div>
                                      </div>
                                      <hr>
                                      <div class="row ms-3">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Organization</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary"> {{userDetails.organization || ""}} </div>
                                    </div>
                                    <hr>
                                    <!-- <div class="row ms-3">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Date Registered</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary"> {{profileData.register_date || ""}} </div>
                                    </div> -->
                                    <!-- <div class="mt-4"> </div> -->
                                  </div>
                              </div> <!-- <i class='bx bx-pencil font-18 me-1'></i></a> -->
                              <!-- Modal -->
                              <div class="modal fade" id="personalInformation" tabindex="-1" aria-hidden="true">
                                  <div class="modal-dialog modal-dialog-centered ">
                                      <div class="modal-content">
                                          <div class="modal-header">
                                              <h5 class="modal-title">Edit Personal Information</h5> <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div class="modal-body">
                                              <form [formGroup]="personalInformationForm" class="needs-validation row g-3" novalidate>
                                                  <div class=""> <label for="name" class="form-label">Full Name</label>
                                                      <div class="input-group input-group"> <input type="text"autocomplete="off"  required require pattern="[A-Za-z ]{0,30}" formControlName="name" class="form-control border-start-1" id="name" value="{{profileData.username}}" />
                                                          <div *ngIf="personalInformationForm.get('name')?.invalid && (personalInformationForm.get('name')?.dirty || personalInformationForm.get('name')?.touched)" class="invalid-feedback">
                                                              <div *ngIf="personalInformationForm.get('name')?.errors?.['required']"> Name is required. </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class=""> <label for="email" class="form-label">Email</label>
                                                      <div class="input-group input-group"> <input [disabled]="isDisabled" class="form-control border-start-4" placeholder="{{this.profileData.email}}" /> </div>
                                                  </div>
                                                  <div class=""> <label for="phone" class="form-label">Phone</label>
                                                      <div class="input-group input-group"> <input type="number" required require autocomplete="off" pattern="^((\\+91-?)|0)?[0-9]{10}$" formControlName="phone" class="form-control border-start-4" id="phone" value="{{profileData.phone}}" />
                                                          <div *ngIf="personalInformationForm.get('phone')?.invalid && (personalInformationForm.get('phone')?.dirty || personalInformationForm.get('phone')?.touched)" class="invalid-feedback">
                                                              <div *ngIf="personalInformationForm.get('phone')?.errors?.['required']"> Phone is required. </div>
                                                              <div *ngIf="personalInformationForm.get('phone')?.errors?.['pattern']"> Phone is required. </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </form>
                                          </div>
                                          <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> <button (click)="personalInformation()" [disabled]="personalInformationForm.invalid" type="button" data-bs-dismiss="modal" class="btn btn-primary">Save</button> </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="tab-pane fade" id="subscription" role="tabpanel">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="subscription" role="tabpanel">
                                        <div class="card rounded-3">
                                            <!-- <div class="col  text-end"> <a type="button" class="btn-info col-sm" data-bs-toggle="modal" data-bs-target="#subscriptionInformation"><i class='bx bx-pencil font-24 me-1'></i></a> </div> -->
                                            <ng-container *ngIf="profileData && profileData.tools">
                                                  <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th>Name</th>
                                                        <th>Volume</th>
                                                        <th>Deployment Type</th>
                                                        <th>Region</th>
                                                        <th>Type</th>
                                                        <th>Status</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody *ngFor="let tool of profileData.tools">
                                                      <tr>
                                                        <td>{{tool.name}}</td>
                                                        <td>{{tool.volume}}</td>
                                                        <td>{{tool.deployment_type}}</td>
                                                        <td>{{tool.region}}</td>
                                                        <td>{{tool.type}}</td>
                                                        <td>{{tool.status}}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                            </ng-container>


                                            <!-- <div class="modal fade" id="subscriptionInformation" tabindex="-1" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered ">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Edit Professional Information</h5> <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <form [formGroup]="professionalInformationForm" class="needs-validation row g-3" novalidate>
                                                                <div class=""> <label for="github" class="form-label">Github</label>
                                                                    <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="github" class="form-control border-start-4" id="github" value="{{this.professionInformation.github || 'NA'}}" /> </div>
                                                                </div>
                                                                <div class=""> <label for="linkedin" class="form-label">Linkedin</label>
                                                                    <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="linkedin" class="form-control border-start-4" id="linkedin" value="{{this.professionInformation.linkedin || 'NA'}}" /> </div>
                                                                </div>
                                                                <div class=""> <label for="medium" class="form-label">medium.com</label>
                                                                    <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="medium" class="form-control border-start-4" id="medium" value="{{this.professionInformation.medium || 'NA'}}" /> </div>
                                                                </div>
                                                                <div class=""> <label for="other" class="form-label">Other</label>
                                                                    <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="other" class="form-control border-start-4" id="other" value="{{this.professionInformation.other || 'NA'}}" /> </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> <button (click)="professionalInformation()" [disabled]="professionalInformationForm.invalid" type="button" data-bs-dismiss="modal" class="btn btn-primary">Save </button> </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="tab-pane fade" id="domain" role="tabpanel">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="domain" role="tabpanel">
                                        <div class="card rounded-3">&nbsp;
                                            <ng-container *ngIf="profileData && profileData.domain">
                                            <ng-container *ngFor="let solution of profileData.domain">
                                                <div class="row ms-3">
                                                    <div class="col">
                                                        <h6 class="mb-0">{{solution}}</h6>
                                                    </div>
                                                </div>
                                                <hr>
                                            </ng-container>
                                        </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                              <!-- <div class="tab-pane fade" id="professional" role="tabpanel">
                                  <div class="tab-content">
                                      <div class="tab-pane fade show active" id="professional" role="tabpanel">
                                          <div class="card rounded-3">
                                              <div class="col  text-end"> <a type="button" class="btn-info col-sm" data-bs-toggle="modal" data-bs-target="#professionalInformation"><i class='bx bx-pencil font-24 me-1'></i></a> </div>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">Github</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.professionInformation.github || 'NA'}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">Linkedin</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.professionInformation.linkedin || "NA"}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">medium</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.professionInformation.medium || "NA"}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">Other</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.professionInformation.other || "NA"}} </div>
                                                  <div class="mt-4"> </div>
                                              </div>
                                              <div class="modal fade" id="professionalInformation" tabindex="-1" aria-hidden="true">
                                                  <div class="modal-dialog modal-dialog-centered ">
                                                      <div class="modal-content">
                                                          <div class="modal-header">
                                                              <h5 class="modal-title">Edit Professional Information</h5> <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                          </div>
                                                          <div class="modal-body">
                                                              <form [formGroup]="professionalInformationForm" class="needs-validation row g-3" novalidate>
                                                                  <div class=""> <label for="github" class="form-label">Github</label>
                                                                      <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="github" class="form-control border-start-4" id="github" value="{{this.professionInformation.github || 'NA'}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="linkedin" class="form-label">Linkedin</label>
                                                                      <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="linkedin" class="form-control border-start-4" id="linkedin" value="{{this.professionInformation.linkedin || 'NA'}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="medium" class="form-label">medium.com</label>
                                                                      <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="medium" class="form-control border-start-4" id="medium" value="{{this.professionInformation.medium || 'NA'}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="other" class="form-label">Other</label>
                                                                      <div class="input-group input-group"> <input type="url" pattern="https?://.+" autocomplete="off" required require formControlName="other" class="form-control border-start-4" id="other" value="{{this.professionInformation.other || 'NA'}}" /> </div>
                                                                  </div>
                                                              </form>
                                                          </div>
                                                          <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> <button (click)="professionalInformation()" [disabled]="professionalInformationForm.invalid" type="button" data-bs-dismiss="modal" class="btn btn-primary">Save </button> </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div> -->

                              <!-- <div class="tab-pane fade" id="geographic" role="tabpanel">
                                  <div class="tab-content">
                                      <div class="tab-pane fade show active" id="geographic" role="tabpanel">
                                          <div class="card rounded-3">
                                              <div class="col  text-end"> <a type="button" class="btn-info col-sm" data-bs-toggle="modal" data-bs-target="#geographicalInformation"><i class='bx bx-pencil font-24 me-1'></i></a> </div>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">Address</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.geographicInformation.address || "N.A"}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">City</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.geographicInformation.city || "N.A"}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">State</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.geographicInformation.state || "N.A"}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">Country</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.geographicInformation.country || "N.A"}} </div>
                                              </div>
                                              <hr>
                                              <div class="row ms-3">
                                                  <div class="col-sm-3">
                                                      <h6 class="mb-0">Pincode</h6>
                                                  </div>
                                                  <div class="col-sm-9 text-secondary"> {{this.geographicInformation.pincode || "N.A"}} </div>
                                                  <div class="mt-4"></div>
                                              </div>
                                              <div class="modal fade" id="geographicalInformation" tabindex="-1" aria-hidden="true">
                                                  <div class="modal-dialog modal-dialog-centered ">
                                                      <div class="modal-content">
                                                          <div class="modal-header">
                                                              <h5 class="modal-title">Edit Geographical Information</h5> <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                          </div>
                                                          <div class="modal-body">
                                                              <form [formGroup]="geographicalInformationForm" class="needs-validation row g-3" novalidate>
                                                                  <div class=""> <label for="address" class="form-label">Address</label>
                                                                      <div class="input-group input-group"> <input type="text" required require formControlName="address" class="form-control border-start-4" id="address" value="{{this.geographicInformation.address}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="city" class="form-label">City</label>
                                                                      <div class="input-group input-group"> <input type="text" required require formControlName="city" class="form-control border-start-4" id="city" value="{{this.geographicInformation.city}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="state" class="form-label">State</label>
                                                                      <div class="input-group input-group"> <input type="text" required require formControlName="state" class="form-control border-start-4" id="state" value="{{this.geographicInformation.state}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="country" class="form-label">Country</label>
                                                                      <div class="input-group input-group"> <input type="text" required require formControlName="country" class="form-control border-start-4" id="country" value="{{this.geographicInformation.country}}" /> </div>
                                                                  </div>
                                                                  <div class=""> <label for="pincode" class="form-label">Pincode</label>
                                                                      <div class="input-group input-group"> <input type="number" required require formControlName="pincode" class="form-control border-start-4" id="pincode" value="{{this.geographicInformation.pincode}}" /> </div>
                                                                  </div>
                                                              </form>
                                                          </div>
                                                          <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> <button (click)="geographicalInformation()" [disabled]="geographicalInformationForm.invalid" type="button" data-bs-dismiss="modal" class="btn btn-primary">Save </button> </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div> -->

                              <!-- <div class="tab-pane fade" id="skills" role="tabpanel">
                                  <div class="tab-content">
                                      <div class="card rounded-3">
                                          <div class="tab-pane fade show active ms-3 mt-4 mx-3" id="skills" role="tabpanel">
                                              <h6 for="presentSkills">Present Skills:</h6>
                                              <ng-container *ngIf="skills.length > 0">
                                                <span class="badge text-bg-success fs-6 p-2 mt-2 ms-3 mb-3" *ngFor="let val of skills"> {{val}}
                                                </span>
                                                  <hr>
                                              </ng-container>
                                                  <h6 class="form-label">Add Skills</h6>
                                                  <input type="text" (keyup)="search()" [(ngModel)]="searchText" class="form-control" placeholder="Type your skills here">
                                                  <ul class="list-group">
                                                      <li *ngFor="let val of filteredSkills" (click)="selectSkill(val)" class="list-group-item list-group-item-action" value="{{val.name}}">{{val.name}}</li>
                                                  </ul> <span class="badge text-bg-primary fs-6 p-2 mt-2 me-2" *ngFor="let chip of selectedSkills"> {{chip.name}} <span (click)="removeSkill(chip)"><i class="bx bx-x"></i></span> </span>
                                          </div>
                                          <div class="row mt-3 mb-3 mx-1">
                                              <div class="col-12 text-end"> <button class="btn btn-primary" [disabled]="this.isSaveSkill_Allowed" (click)="saveSkills()">Save Skills</button> </div>
                                          </div>
                                      </div>
                                  </div>
                              </div> -->



                          </div>
                      </div>
                      <!-- Modal to capture a photo
                  <div class="modal fade" id="takeImage" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Profile Photo</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="col-md-12">
                            <webcam
                              [width]=300
                              [height]="300"
                              [trigger]="invokeObservable"
                              [imageQuality]="1"
                              (imageCapture)="captureImg($event)"
                              [switchCamera]="nextWebcamObservable"

                            ></webcam>
                          </div>
                          <div class="col-md-12">
                            <button class="btn btn-danger" (click)="getSnapshot()">
                              Capture Image
                            </button>
                          </div>
                          <div class="col-12">
                            <div id="results">Your taken image manifests here...</div>
                            <img [src]="webcamImage?.imageAsDataUrl" height="200px" width="200px" class="rounded-circle" />
                          </div>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                      <!-- Modal to add a photo -->
                      <div class="modal fade" id="uploadImage" tabindex="-1" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title">Import profile photo</h5>
                                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                      <h6>Upload your photo here</h6>
                                      <input id="fancy-file-upload" type="file" (change)="onFileSelected($event)" name="files" accept=".jpg, .png, image/jpeg, image/png" multiple>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="!isAnyDocumentSelected()" (click)="profilPhotoUpload()">Save </button>
                                  </div>
                              </div>
                          </div>
                      </div> <!-- </div> -->
                      <!-- <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Mobile</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    9408820985
                  </div>
                </div> -->
                      <!-- <hr> -->
                      <!-- <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Address</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">changePassword
                    Bay Area, San Francisco, CA
                  </div>
                </div>
                <hr> -->
                      <!-- <div class="row ms-5">
                  <div class="text-start col-3"> -->
                      <!--------------- Button for Password change------------->
                      <!-- <a type="button" class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#changePassword">Change Password</a> -->
                      <!-- Modal -->
                      <!-- <div class="modal fade" id="changePassword" tabindex="-1" aria-hidden="true">

                      </div>
                    </div>
                  </div> -->
                      <div class="text-start col-3">
                          <!--------------- Button for Experience change------------->
                          <!-- <a type="button" class="btn btn-info" data-bs-toggle="modal" data-bs-target="#ExPerience">Add Experience</a> -->
                          <!-- Modal -->
                          <div class="modal fade" id="ExPerience" tabindex="-1" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered ">
                                  <div class="modal-content">
                                      <div class="modal-header">
                                          <h5 class="modal-title">Add Experience Information</h5>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                      </div>
                                      <div class="modal-body">
                                          <form [formGroup]="experiencesInformationForm" class="needs-validation row g-3" novalidate>
                                              <div class="">
                                                <label for="position" class="form-label">Postion</label>
                                                  <div class="input-group input-group">
                                                    <!-- <input type="text" #inputPosition autocomplete="off" autocomplete="off" required require formControlName="position" class="form-control border-start-4" id="position" />  -->
                                                    <select #inputPosition autocomplete="off" class="form-control border-start-4" required require formControlName="position" id="position" size="1">
                                                        <!-- <option value="default">default</option> -->
                                                        <ng-container *ngFor="let pos of positions">
                                                            <option class="pt-1" value="{{pos.name}}">{{pos.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                  </div>
                                                  <!-- <div class="input-group">
                                                    <select #jimmy class="form-control border-start-4" required require formControlName="selectDropJimmy" id="selectDropJimmy" size="1">
                                                        <option value="default">default</option>
                                                        <ng-container *ngFor="let pos of positions">
                                                            <option value="{{pos.name}}">{{pos.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                  </div> -->
                                              </div>
                                              <div class="">
                                                <label for="company" class="form-label">Company</label>
                                                  <div class="input-group input-group">
                                                    <input type="text" #inputCompany autocomplete="off" autocomplete="off" pattern="^[A-Za-z0-9\s.'&-]*$" required require formControlName="company" class="form-control border-start-4" id="company" />
                                                  </div>
                                              </div>
                                              <div class="">
                                                <label for="duration" class="form-label">Duration</label>
                                                    <div class="input-group input-group">
                                                        <div class="row">
                                                            <div class="col ps-1">
                                                                <label class="me-2 ms-0">From </label>
                                                                <input type="date" #inputDurationFrom required require autocomplete="off" formControlName="durationFrom" class="form-control border-start-2 me-2 ms-0" id="durationfrom" />
                                                            </div>
                                                            <div class="col">
                                                                <label class="ms-2 me-2">To </label>
                                                                <input type="date" #inputDurationTo required require autocomplete="off" formControlName="durationTo" class="form-control border-start-2 ms-2 me-2" id="durationTo" />
                                                            </div>
                                                        </div>
                                                    </div>
                                              </div>
                                              <div class="">
                                                <label for="workDescription" class="form-label">Work Description</label>
                                                  <div class="input-group input-group">
                                                    <input type="textarea" #inputWorkDescription autocomplete="off" required require formControlName="workdescription" class="form-control border-start-4" id="workDescription" />
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button class="btn btn-danger" [disabled]="experiencesInformationForm.invalid" (click)="addExperience(inputPosition.value, inputCompany.value, inputDurationFrom.value,inputDurationTo.value, inputWorkDescription.value)" data-bs-dismiss="modal"> add this to list</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- <div class="row mt-3">
                  <div class="text-start col-12"> -->
                  <!--------------- Button for Password change------------->
                  <!-- <input type="text" placeholder="add skills here" >
                    <a type="button" class="btn btn-info" >Skills</a> -->
                  <!-- <h6 for="presentSkills">Present Skills:</h6>
                    <span class="badge text-bg-success fs-6 p-2 mt-2 ms-3 mb-3" *ngFor="let val of skills">
                      {{val}}
                    </span><br>
                    <label class="form-label">Add Skills</label> -->
                  <!-- <select class="multiple-select" data-placeholder="Choose anything" multiple="multiple">
                      <option *ngFor="let val of Skills" [value]="val.name" >{{val.name}}</option>
                    </select> -->
                  <!-- <ng-multiselect-dropdown [placeholder]="'Choose anything'" [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
                      </ng-multiselect-dropdown> -->
                  <!-- <input type="text" (keyup)="search()" [(ngModel)]="searchText" class="form-control" placeholder="Type your skills here">
                      <ul class="list-group">
                        <li *ngFor="let val of filteredSkills" (click)="selectSkill(val)" class="list-group-item list-group-item-action" value="{{val.name}}">{{val.name}}</li>
                      </ul>
                      <span class="badge text-bg-primary fs-6 p-2 mt-2 me-2" *ngFor="let chip of selectedSkills">
                        {{chip.name}}
                        <span (click)="removeSkill(chip)"><i class="bx bx-x"></i></span>
                      </span>





                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 text-end">
                    <button class="btn btn-primary" (click)="saveSkills()">Save Skills</button>
                  </div>
                </div> -->
              </div>
          </div>
          <!-- <div class="row gutters-sm">
              <div class="col-sm-6 mb-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="d-flex align-items-center mb-3">

                      Project Status</h6>
                    <small>Web Design</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Website Markup</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>One Page</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Mobile Template</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Backend API</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="d-flex align-items-center mb-3">

                      Project Status</h6>
                    <small>Web Design</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Website Markup</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>One Page</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Mobile Template</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Backend API</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
  </div> -->
          <!-- Modal -->
          <div class="modal fade" id="EditExperience" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered ">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Edit Experience Information</h5> <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <form [formGroup]="experiencesInformationForm" class="needs-validation row g-3" novalidate>
                              <div class="">
                                <label for="position" class="form-label">Postion</label>
                                  <div class="input-group input-group">
                                    <!-- <input type="text" #editedPosition autocomplete="off" required require autocomplete="off" formControlName="position" class="form-control border-start-4" id="position" />  -->
                                    <select #editedPosition autocomplete="off" class="form-control border-start-4" required require formControlName="position" id="position" size="1">
                                        <!-- <option value="default">default</option> -->
                                        <ng-container *ngFor="let pos of positions">
                                            <option class="pt-1" value="{{pos.name}}">{{pos.name}}</option>
                                        </ng-container>
                                    </select>
                                  </div>
                              </div>
                              <div class="">
                                <label for="company" class="form-label">Company</label>
                                  <div class="input-group input-group">
                                    <input type="text" #editedCompany autocomplete="off" required require autocomplete="off" pattern="^[A-Za-z0-9\s.'&-]*$" formControlName="company" class="form-control border-start-4" id="company" />
                                  </div>
                              </div>
                              <div class="">
                                <label for="duration" class="form-label">Duration</label>
                                  <div class="input-group input-group">
                                    <div class="row">
                                        <div class="col">
                                            <label class="ms-2 me-2">From </label>
                                            <input type="month" #editedDurationFrom required require autocomplete="off" formControlName="durationFrom" class="form-control border-start-2 me-2 ms-2" id="durationfrom" />

                                        </div>
                                        <div class="col">
                                            <label class="ms-2 me-2">To </label>
                                            <input type="month" #editedDurationTo required require autocomplete="off" formControlName="durationTo" class="form-control border-start-2 ms-2 me-2" id="durationTo" />
                                        </div>
                                    </div>

                                </div>
                              </div>
                              <div class="">
                                <label for="workDescription" class="form-label">Work Description</label>
                                  <div class="input-group input-group">
                                    <input type="text" #editedWorkDescritption required autocomplete="off" require formControlName="workdescription" class="form-control border-start-4" id="workDescription" />
                                  </div>
                              </div>
                          </form>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-danger" [disabled]="experiencesInformationForm.invalid" data-bs-dismiss="modal" (click)="Save_EditedExperience(editedPosition.value, editedCompany.value, editedDurationFrom.value,editedDurationTo.value, editedWorkDescritption.value)"> Save </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
