import { Routes } from '@angular/router';
//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard', 
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {

        path: 'application',
        loadChildren: () => import('../../application/application.module').then(m => m.ApplicationModule)

    },
    {
        path: 'users',
        loadChildren: () => import('../../users/users.module').then(m => m.UserModule)

    },
    {
        path: 'user-profile', 
        loadChildren: () => import('../../user-profile/user-profile.module').then(m => m.UserProfileModule)

    },
    {
        path: 'add-new-instance',
        loadChildren: () => import('../../add-new-instance/add-new-instance.module').then(m => m.AddNewInstanceModule) // Add this line
    },
    {
        path: 'add-tool',
        loadChildren: () => import('../../add-tool/add-tool.module').then(m => m.AddToolModule) // Add this line
    }
];